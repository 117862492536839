import styled from "styled-components"
import { useContext, useEffect, useState } from "react"
import StandardMsg from "../Components/Subcomponents/StandardMsg"
import LeftBox from "../Components/LeftBox"
import RightBox from "../Components/RightBox"
import { DataContext } from "../Contexts/DataContext"
import Input from "../Components/Subcomponents/Input"
import Button from "../Components/Subcomponents/Button"
import Dropdown from "../Components/Subcomponents/Dropdown"
import { CheckBuyerForm, CheckCatalogForm, CheckModelQCForm, CheckPhotoEditForm, CheckPhotoForm, CheckPhotoQCForm, CheckPricingForm, CheckProductOpsForm, CheckVideoEditForm, FetchData } from "../Components/Subcomponents/FetchData"
import Loader from "../Components/Subcomponents/Loader"
import Stepper from "../Components/Subcomponents/Stepper"
import { motion } from "framer-motion";


const Container = styled(motion.div)`
    display: flex;
    width: 100vw;
    height: calc(100vh - 80px);
    gap: 10px;
`
const LeftContainer = styled(motion.div)`
    display: flex;
    width: 25%;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    gap: 20px;
    padding: 10px 10px;
`
const CenterBox = styled(motion.div)`
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
    height: 98%;
    background-color: #2b2b2b;
    color: white;
    border-radius: 10px;
    box-shadow: var(--box-shadow); 
`
const RightContainer = styled(motion.div)`
    display: flex;
    width: 25%;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 10px 0px;
`
const Forms = styled.div`
    // height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
`
const Header = styled.div`
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fcbf49;
    letter-spacing: var(--ls);
    text-transform: uppercase;
    font-size: var(--font-s);
`
const Body = styled.div`
    height: calc(100% - 80px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    width: 100%;
`

const Form = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: ${props => props.fd};
    gap: 10px;
    width: ${props => props.width};
    height: 100%;
`
const Part1 = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    width: 50%;
    height: 100%;
`
const ButtonContainer = styled.div`
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;
    margin-top: 20px;
`

const Link = styled.div`
    display: flex;
    align-itms: center;
    justify-content: center;
    width: 70%;
    flex-direction: column;
    margin: 0px auto;
    gap: 10px;
    letter-spacing: var(--ls);
    color: lightgrey;
    text-align: center;
    word-wrap: break-word;
`

const Message = styled.div`
    color: red;
    font-size: var(--font-s);
    letter-spacing: var(--ls);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    width: 60%;
    margin: auto;
`

const Empty = styled.div`
    height: 20px;
`
const POQTY = styled.div`
    letter-spacing: var(--ls);
    color: lightgrey;
    font-size: var(--font-s);
    padding-bottom: 20px;
`

const Reason = styled.div`
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 10px;
    padding: 10px;
`
const Head = styled.div`
    width: 100%;
    color: lightgrey;
    letter-spacing: 0.5px;
    text-transform: upperacase;
`
const Content = styled.div`
    color: lightgrey;
    letter-spacing: 0.5px;
    width: 100%;
    color: red;
`


const Homepage = () => {

    const { user, url, rejection } = useContext(DataContext)


    useEffect(() => {
        const reloadCount = sessionStorage.getItem('reloadCount');
        if (reloadCount < 1) {
            sessionStorage.setItem('reloadCount', String(reloadCount + 1));
            window.location.reload();
        } else {
            sessionStorage.removeItem('reloadCount');
        }
    }, []);

    ////////////////////// Selected SKU ////////////////////

    const [selected, setSelected] = useState("")
    const [selectedValue, setSelectedValue] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        async function fetchFn() {
            setLoading(true)
            const response = await FetchData({
                "endpoint": url + "boxview/fetchhome",
                data: {
                    "selected": selected
                }
            })

            if (response.error === "no error") {
                setSelectedValue(response.data)
                setLoading(false)
            } else {
                setLoading(false)
            }
        }
        fetchFn()
    }, [selected])


    /////////////////////////// Product Ops Form //////////////////////////////


    const ProductOpsForm = {
        productWeight: "", productLength: "", productWidth: ""
    };

    const [productOpsForms, setProductOpsForms] = useState(ProductOpsForm);

    const handleProductOpsForm = (e) => {
        const { name, value } = e.target;

        setProductOpsForms({
            ...productOpsForms,
            [name]: value,
        });
    }

    const postProductOpsForm = async () => {

        const dataCheck = await CheckProductOpsForm(productOpsForms)

        if (dataCheck['error'] === "no error") {
            setLoading(true)
            const response = await FetchData({
                "endpoint": url + "forms/productops",

                data: {
                    "firstCode": selected,
                    "form": productOpsForms,
                }
            })

            if (response.error === "no error") {
                setLoading(false)
                setMessage("")
                clearForm()
                window.location.reload()
            } else {
                setMessage(response.message)
                setLoading(false)
            }
        } else {
            setMessage(dataCheck["message"])
            setLoading(false)
        }
    }


    /////////////////////////// Pricing Form //////////////////////////////

    const PricingForm = {
        mrp: "", sellingPrice: "", poQty: ""
    };

    const [pricingForms, setPricingForms] = useState(PricingForm);

    const handlePricingForm = (e) => {
        const { name, value } = e.target;

        setPricingForms({
            ...pricingForms,
            [name]: value,
        });
    }

    const postPricingForm = async () => {

        const dataCheck = await CheckPricingForm(pricingForms)

        if (dataCheck['error'] === "no error") {
            setLoading(true)
            const response = await FetchData({
                "endpoint": url + "forms/pricing",

                data: {
                    "firstCode": selected,
                    "form": pricingForms,
                }
            })

            if (response.error === "no error") {
                setLoading(false)
                setMessage("")
                clearForm()
                window.location.reload()
            } else {
                setMessage(response.message)
                setLoading(false)
            }
        } else {
            setMessage(dataCheck["message"])
            setLoading(false)
        }
    }


    /////////////////////////// Buyer Form //////////////////////////////

    const BuyerForm = {
        poNumber: "", poQuantity: "", remarks: ""
    };

    const [buyerForms, setBuyerForms] = useState(BuyerForm);

    const handleBuyerForm = (e) => {
        const { name, value } = e.target;

        setBuyerForms({
            ...buyerForms,
            [name]: value,
        });
    }

    const postBuyerForm = async () => {

        const dataCheck = await CheckBuyerForm(buyerForms)

        if (dataCheck['error'] === "no error") {
            setLoading(true)
            const response = await FetchData({
                "endpoint": url + "forms/buyer",

                data: {
                    "firstCode": selected,
                    "form": buyerForms,
                }
            })

            if (response.error === "no error") {
                setLoading(false)
                setMessage("")
                clearForm()
                window.location.reload()
            } else {
                setMessage(response.message)
                setLoading(false)
            }
        } else {
            setMessage(dataCheck["message"])
            setLoading(false)
        }
    }

    /////////////////////////// Photo / Video Form //////////////////////////////

    const PhotoForm = {
        photoLink: ""
    };

    const [photoForms, setPhotoForms] = useState(PhotoForm);

    const handlePhotoForm = (e) => {
        const { name, value } = e.target;

        setPhotoForms({
            ...photoForms,
            [name]: value,
        });
    }

    const postPhotoForm = async () => {

        const dataCheck = await CheckPhotoForm(photoForms)

        if (dataCheck['error'] === "no error") {
            setLoading(true)
            const response = await FetchData({
                "endpoint": url + "forms/shoot",

                data: {
                    "firstCode": selected,
                    "form": photoForms,
                    "userName": user[0]['userName'],
                    "designation": user[0]["designation"]
                }
            })

            if (response.error === "no error") {
                setLoading(false)
                setMessage("")
                clearForm()
                window.location.reload()
            } else {
                setMessage(response.message)
                setLoading(false)
            }
        } else {
            setMessage(dataCheck["message"])
            setLoading(false)
        }
    }

    /////////////////////////// Editor //////////////////////////////

    const [photoEditForm, setPhotoEditForm] = useState("");
    const [approve, setApprove] = useState("")
    const [rejectionDropdown, setRejectionDropdown] = useState("")


    const handleProductApprove = () => {
        setApprove("productapproved")
    }

    const handleProductReject = () => {
        setApprove("productrejected")
    }

    const handlePhotoEditForm = (e) => {
        setPhotoEditForm(e.target.value);
    }

    const postPhotoEditForm = async () => {

        const dataCheck = await CheckPhotoEditForm(photoEditForm, approve, rejectionDropdown)

        if (dataCheck['error'] === "no error") {
            setLoading(true)
            const response = await FetchData({
                "endpoint": url + "forms/editor",

                data: {
                    "firstCode": selected,
                    "form": photoEditForm,
                    "rejectionDropdown": rejectionDropdown,
                    "userName": user[0]['userName'],
                    "designation": user[0].designation,
                    "approve": approve
                }
            })

            if (response.error === "no error") {
                setLoading(false)
                setMessage("")
                clearForm()
                window.location.reload()
            } else {
                setMessage(response.message)
                setLoading(false)
            }
        } else {
            setMessage(dataCheck["message"])
            setLoading(false)
        }
    }


    /////////////////////////// QC //////////////////////////////

    const PhotoQCForm = {
        photoLink: "", rejectionReason: ""
    };

    const [photoQCForms, setPhotoQCForms] = useState(PhotoQCForm);
    // const [approve, setApprove] = useState("")

    // const handleProductApprove = () => {
    //     setApprove("productapproved")
    // }

    // const handleProductReject = () => {
    //     setApprove("productrejected")
    // }


    const handlePhotoQCForm = (e) => {
        const { name, value } = e.target;

        setPhotoQCForms({
            ...photoQCForms,
            [name]: value,
        });
    }

    const postPhotoQCForm = async () => {

        const dataCheck = await CheckPhotoQCForm(photoQCForms, approve)

        if (dataCheck['error'] === "no error") {
            setLoading(true)
            const response = await FetchData({
                "endpoint": url + "forms/qc",

                data: {
                    "firstCode": selected,
                    "form": photoQCForms,
                    "approve": approve,
                    "userName": user[0]['userName'],
                    "designation": user[0].designation
                }
            })

            if (response.error === "no error") {
                setLoading(false)
                setMessage("")
                clearForm()
                window.location.reload()
            } else {
                setMessage(response.message)
                setLoading(false)
            }
        } else {
            setMessage(dataCheck["message"])
            setLoading(false)
        }
    }



    // /////////////////////////// Model QC //////////////////////////////

    // const ModelQCForm = {
    //     photoProductLink: "", photoModelLink: "", productRejectionReason: "", modelRejectionReason: ""
    // };

    // const [modelQCForms, setModelQCForms] = useState(ModelQCForm);
    // // const [approve, setApprove] = useState("")

    // // const handleProductApprove = () => {
    // //     setApprove("productapproved")
    // // }

    // // const handleProductReject = () => {
    // //     setApprove("productrejected")
    // // }

    // // const handleModelApprove = () => {
    // //     setApprove("modelapproved")
    // // }

    // // const handleModelReject = () => {
    // //     setApprove("modelrejected")
    // // }

    // const switchForm2 = () => {
    //     if ((modelQCForms.photoProductLink).toLowerCase() === "closed") {
    //         setMessage("")
    //         setApprove("switched")
    //     } else if (modelQCForms.productRejectionReason !== "") {
    //         if ((modelQCForms.productRejectionReason).length > 30) {
    //             setMessage("Max length is only 50 characters")
    //         } else {
    //             setMessage("")
    //             setApprove("switched")
    //         }
    //     } else {
    //         setMessage("Enter Valid Values")
    //     }
    // }

    // const handleModelQCForm = (e) => {
    //     const { name, value } = e.target;

    //     setModelQCForms({
    //         ...modelQCForms,
    //         [name]: value,
    //     });
    // }

    // const postModelQCForm = async () => {

    //     const dataCheck = await CheckModelQCForm(modelQCForms, approve)

    //     if (dataCheck['error'] === "no error") {
    //         setLoading(true)
    //         const response = await FetchData({
    //             "endpoint": url + "forms/modelqc",

    //             data: {
    //                 "firstCode": selected,
    //                 "form": modelQCForms,
    //             }
    //         })

    //         if (response.error === "no error") {
    //             setLoading(false)
    //             setMessage("")
    //             clearForm()
    //             window.location.reload()
    //         } else {
    //             setMessage(response.message)
    //             setLoading(false)
    //         }
    //     } else {
    //         setMessage(dataCheck["message"])
    //         setLoading(false)
    //     }
    // }


    /////////////////////////// Buyer Form //////////////////////////////

    const CatalogForm = {
        poNumber: "", poQuantity: ""
    };

    const [catalogForms, setCatalogForms] = useState(CatalogForm);

    const handleCatalogForm = (e) => {
        const { name, value } = e.target;

        setCatalogForms({
            ...catalogForms,
            [name]: value,
        });
    }

    const postCatalogForm = async () => {

        const dataCheck = await CheckCatalogForm(catalogForms)

        if (dataCheck['error'] === "no error") {
            setLoading(true)
            const response = await FetchData({
                "endpoint": url + "forms/catalog",

                data: {
                    "firstCode": selected,
                    "form": catalogForms,
                }
            })

            if (response.error === "no error") {
                setLoading(false)
                setMessage("")
                clearForm()
                window.location.reload()
            } else {
                setMessage(response.message)
                setLoading(false)
            }
        } else {
            setMessage(dataCheck["message"])
            setLoading(false)
        }
    }


    ///////////////////////// Clear Forms ////////////////////

    const clearForm = () => {
        // setProductOpsForms(ProductOpsForm)
        setPricingForms(PricingForm)
        setPhotoForms(PhotoForm)
        setPhotoQCForms(PhotoQCForm)
        // setModelQCForms(ModelQCForm)
        setBuyerForms(BuyerForm)
        setCatalogForms(CatalogForm)
        setApprove("")
        setSelectedValue("")
        setSelected("")
        setMessage("")
    }

    ////////////////////// Stepper /////////////////////////

    const [showStepper, setShowStepper] = useState(false)

    return (
        <Container
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
                type: "spring",
                stiffness: 100,
                duration: 0.5, delay: 0.2
            }}
        >


            <LeftContainer
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                    type: "spring",
                    stiffness: 100,
                    duration: 0.5, delay: 0.6
                }}
            >
                <LeftBox setSelected={setSelected} setShowStepper={setShowStepper} />
            </LeftContainer>


            {!loading ?
                <>
                    {!showStepper && selectedValue !== "" &&
                        <CenterBox
                        >
                            {selectedValue !== "" &&
                                <Forms>
                                    <Header>
                                        SKU ID : {selected}
                                    </Header>
                                    <Message>
                                        {message}
                                    </Message>

                                    {user[0]["designation"] === "ProductOps" &&
                                        <Body>
                                            <Form fd={"row"} width={"100%"}>
                                                <Part1>
                                                    <Input label={"Weight (in grams)"} onChange={handleProductOpsForm}
                                                        name={"productWeight"} />
                                                    <Input label={"Length (in cm)"} onChange={handleProductOpsForm}
                                                        name={"productLength"} />
                                                    <Input label={"Width (in cm)"} onChange={handleProductOpsForm}
                                                        name={"productWidth"} />
                                                </Part1>
                                            </Form>
                                            <ButtonContainer jc={"center"}>
                                                <Button name={loading ? "loading..." : "cancel"} onClick={clearForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                <Button name={loading ? "loading..." : "submit"} onClick={postProductOpsForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                            </ButtonContainer>
                                        </Body>
                                    }



                                    {user[0]["designation"] === "Pricing" &&
                                        <Body>
                                            <Form fd={"column"} width={"45%"}>
                                                <Input label={"MRP"} onChange={handlePricingForm}
                                                    name={"mrp"} />
                                                <Input label={"Selling Price"} onChange={handlePricingForm}
                                                    name={"sellingPrice"} />
                                                <Input label={"PO Quantity"} onChange={handlePricingForm}
                                                    name={"poQty"} />
                                            </Form>
                                            <ButtonContainer jc={"center"}>
                                                <Button name={loading ? "loading..." : "cancel"} onClick={clearForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                <Button name={loading ? "loading..." : "submit"} onClick={postPricingForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                            </ButtonContainer>
                                        </Body>
                                    }



                                    {user[0]["designation"] === "Buyer" &&
                                        <Body>
                                            <POQTY>Pricing Quantity: {selectedValue[0].poQty}</POQTY>
                                            <Form fd={"column"} width={"45%"}>
                                                <Input label={"PO Number"} onChange={handleBuyerForm}
                                                    name={"poNumber"} />
                                                <Input label={"PO Quantity"} onChange={handleBuyerForm}
                                                    name={"poQuantity"} />
                                                <Input label={"Remarks"} onChange={handleBuyerForm}
                                                    name={"remarks"} />
                                            </Form>
                                            <ButtonContainer jc={"center"}>
                                                <Button name={loading ? "loading..." : "cancel"} onClick={clearForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                <Button name={loading ? "loading..." : "submit"} onClick={postBuyerForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                            </ButtonContainer>
                                        </Body>
                                    }



                                    {user[0]["designation"] === "Catalog" &&
                                        <Body>
                                            <Form fd={"column"} width={"45%"}>
                                                <Input label={"Catalog ID"} onChange={handleCatalogForm}
                                                    name={"catalogID"} />
                                                <Input label={"FSN ID"} onChange={handleCatalogForm}
                                                    name={"fsnID"} />
                                            </Form>
                                            <ButtonContainer jc={"center"}>
                                                <Button name={loading ? "loading..." : "cancel"} onClick={clearForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                <Button name={loading ? "loading..." : "submit"} onClick={postCatalogForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                            </ButtonContainer>
                                        </Body>
                                    }



                                    {(user[0]["designation"] === "Photographer" || user[0]["designation"] === "ModelShoot" || user[0]["designation"] === "MoodShoot" || user[0]["designation"] === "VideoShoot") &&
                                        <Body>
                                            <Reason>

                                                {user[0].designation === "Photographer" && selectedValue[0].productShootReEdit !== "" &&
                                                    <>
                                                        <Head>
                                                            Rejection Reason:
                                                        </Head>
                                                        <Content>
                                                            {selectedValue[0].productShootReEdit}
                                                        </Content>
                                                    </>}

                                                {user[0].designation === "ModelShoot" && selectedValue[0].modelShootReEdit !== "" &&
                                                    <>
                                                        <Head>
                                                            Rejection Reason:
                                                        </Head>
                                                        <Content>
                                                            {selectedValue[0].modelShootReEdit}
                                                        </Content>
                                                    </>
                                                }

                                                {user[0].designation === "MoodShoot" && selectedValue[0].moodShootReEdit !== "" &&
                                                    <>
                                                        <Head>
                                                            Rejection Reason:
                                                        </Head>
                                                        <Content>
                                                            {selectedValue[0].moodShootReEdit}
                                                        </Content>
                                                    </>}


                                                {user[0].designation === "VideoShoot" && selectedValue[0].videoReEdit !== "" &&
                                                    <>
                                                        <Head>
                                                            Rejection Reason:
                                                        </Head>
                                                        <Content>
                                                            {selectedValue[0].videoReEdit}
                                                        </Content>
                                                    </>
                                                }
                                            </Reason>
                                            <Form fd={"column"} width={"45%"}>
                                                <Input label={"Photo / Video File Link"} onChange={handlePhotoForm}
                                                    name={"photoLink"} />
                                            </Form>
                                            <ButtonContainer jc={"center"}>
                                                <Button name={loading ? "loading..." : "cancel"} onClick={clearForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                <Button name={loading ? "loading..." : "submit"} onClick={postPhotoForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                            </ButtonContainer>
                                        </Body>
                                    }



                                    {(user[0]["designation"] === "Editor" || user[0]["designation"] === "ModelEditor" || user[0]["designation"] == "MoodEditor" || user[0]["designation"] === "VideoEditor") &&
                                        <>
                                            <Body>
                                                <Reason>
                                                    {user[0].designation === "Editor" && selectedValue[0].productShootToEditorReEdit !== "" &&
                                                        <>
                                                            <Head>
                                                                Rejection Reason:
                                                            </Head>
                                                            <Content>
                                                                {selectedValue[0].productShootToEditorReEdit}
                                                            </Content>
                                                        </>}

                                                    {user[0].designation === "ModelEditor" && selectedValue[0].modelShootToEditorReEdit !== "" &&
                                                        <>
                                                            <Head>
                                                                Rejection Reason:
                                                            </Head>
                                                            <Content>
                                                                {selectedValue[0].modelShootToEditorReEdit}
                                                            </Content>
                                                        </>}

                                                    {user[0].designation === "MoodEditor" && selectedValue[0].moodShootToEditorReEdit !== "" &&
                                                        <>
                                                            <Head>
                                                                Rejection Reason:
                                                            </Head>
                                                            <Content>
                                                                {selectedValue[0].moodShootToEditorReEdit}
                                                            </Content>
                                                        </>}


                                                    {user[0].designation === "VideoEditor" && selectedValue[0].videoToEditorReEdit !== "" &&
                                                        <>
                                                            <Head>
                                                                Rejection Reason:
                                                            </Head>
                                                            <Content>
                                                                {selectedValue[0].videoToEditorReEdit}
                                                            </Content>
                                                        </>}
                                                </Reason>

                                                {approve === "" && <>
                                                    <Link>
                                                        <div>Product Shoot File Link</div>
                                                        {user[0].designation === "Editor" && <a href={selectedValue[0].productShootPhoto} target="_blank" rel="noopener noreferrer">{selectedValue[0].productShootPhoto}</a>}
                                                        {user[0].designation === "ModelEditor" && <a href={selectedValue[0].modelShootPhoto} target="_blank" rel="noopener noreferrer">{selectedValue[0].modelShootPhoto}</a>}
                                                        {user[0].designation === "MoodEditor" && <a href={selectedValue[0].moodShootPhoto} target="_blank" rel="noopener noreferrer">{selectedValue[0].moodShootPhoto}</a>}
                                                        {user[0].designation === "VideoEditor" && <a href={selectedValue[0].videoPhoto} target="_blank" rel="noopener noreferrer">{selectedValue[0].videoPhoto}</a>}
                                                    </Link>

                                                    <ButtonContainer jc={"center"}>
                                                        <Button name={loading ? "loading..." : "reject"} onClick={handleProductReject} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                        <Button name={loading ? "loading..." : "approve"} onClick={handleProductApprove} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                    </ButtonContainer>
                                                    <Empty />
                                                </>}

                                                {approve === "productapproved" &&
                                                    <>
                                                        <Form fd={"column"} width={"45%"}>
                                                            <Input label={"Photo / Video File Link"} onChange={handlePhotoEditForm}
                                                                name={"photoProductLink"} />
                                                        </Form>
                                                        <ButtonContainer jc={"center"}>
                                                            <Button name={loading ? "loading..." : "cancel"} onClick={clearForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                            <Button name={loading ? "loading..." : "submit"} onClick={postPhotoEditForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                        </ButtonContainer>
                                                    </>}

                                                {approve === "productrejected" &&
                                                    <>
                                                        <Form fd={"column"} width={"45%"}>
                                                            <Dropdown index={1} ph={"Rejection Reason"} options={rejection} onChange={"handleSignUpChange"}
                                                                name={"rejection"} fn={setRejectionDropdown} />
                                                        </Form>
                                                        <ButtonContainer jc={"center"}>
                                                            <Button name={loading ? "loading..." : "cancel"} onClick={clearForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                            <Button name={loading ? "loading..." : "submit"} onClick={postPhotoEditForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                        </ButtonContainer>
                                                    </>}
                                            </Body>
                                        </>
                                    }



                                    {(user[0]["designation"] === "QC" || user[0]["designation"] === "ModelQC" || user[0]["designation"] === "MoodQC" || user[0]["designation"] === "VideoQC") &&
                                        <Body>
                                            {approve === "" && <>
                                                <Link>
                                                    <div>Photo Edit File Link</div>
                                                    {user[0].designation === "QC" && <a href={selectedValue[0].productShootToEditorPhoto} target="_blank" rel="noopener noreferrer">{selectedValue[0].productShootToEditorPhoto}</a>}
                                                    {user[0].designation === "ModelQC" && <a href={selectedValue[0].modelShootToEditorPhoto} target="_blank" rel="noopener noreferrer">{selectedValue[0].modelShootToEditorPhoto}</a>}
                                                    {user[0].designation === "MoodQC" && <a href={selectedValue[0].moodShootToEditorPhoto} target="_blank" rel="noopener noreferrer">{selectedValue[0].moodShootToEditorPhoto}</a>}
                                                    {user[0].designation === "VideoQC" && <a href={selectedValue[0].videoToEditorPhoto} target="_blank" rel="noopener noreferrer">{selectedValue[0].videoToEditorPhoto}</a>}
                                                </Link>

                                                <ButtonContainer jc={"center"}>
                                                    <Button name={loading ? "loading..." : "reject"} onClick={handleProductReject} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                    <Button name={loading ? "loading..." : "approve"} onClick={handleProductApprove} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                </ButtonContainer>
                                                <Empty />
                                            </>}

                                            {approve === "productapproved" &&
                                                <>
                                                    <Form fd={"column"} width={"45%"}>
                                                        <Input label={"Enter 'Closed' to proceed"} onChange={handlePhotoQCForm}
                                                            name={"photoLink"} />
                                                    </Form>
                                                    <ButtonContainer jc={"center"}>
                                                        <Button name={loading ? "loading..." : "cancel"} onClick={clearForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                        <Button name={loading ? "loading..." : "submit"} onClick={postPhotoQCForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                    </ButtonContainer>
                                                </>}

                                            {approve === "productrejected" &&
                                                <>
                                                    <Form fd={"column"} width={"45%"}>
                                                        <Input label={"Rejection Reason"} onChange={handlePhotoQCForm}
                                                            name={"rejectionReason"} />
                                                    </Form>
                                                    <ButtonContainer jc={"center"}>
                                                        <Button name={loading ? "loading..." : "cancel"} onClick={clearForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                        <Button name={loading ? "loading..." : "submit"} onClick={postPhotoQCForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                    </ButtonContainer>
                                                </>}
                                        </Body>
                                    }




                                    {/* {(user[0]["designation"] === "ModelQC") &&
                                        <>
                                            {selectedValue[0].modelShootEditToQCStatus === "pending" && (selectedValue[0].videoEditToQCStatus === "completed" || selectedValue[0].videoEditToQCStatus === "") &&
                                                <Body>

                                                    {approve === "" && <>
                                                        <Link>
                                                            <div>Model Shoot File Link</div>
                                                            <a href={selectedValue[0].modelShootToEditorPhoto} target="_blank" rel="noopener noreferrer">{selectedValue[0].modelShootToEditorPhoto}</a>
                                                        </Link>

                                                        <ButtonContainer jc={"center"}>
                                                            <Button name={loading ? "loading..." : "reject"} onClick={handleProductReject} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                            <Button name={loading ? "loading..." : "approve"} onClick={handleProductApprove} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                        </ButtonContainer>
                                                        <Empty />
                                                    </>}

                                                    {approve === "productapproved" &&
                                                        <>
                                                            <Form fd={"column"} width={"45%"}>
                                                                <Input label={"Enter 'Closed' to proceed"} onChange={handleModelQCForm}
                                                                    name={"photoProductLink"} />
                                                            </Form>
                                                            <ButtonContainer jc={"center"}>
                                                                <Button name={loading ? "loading..." : "cancel"} onClick={clearForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                                <Button name={loading ? "loading..." : "submit"} onClick={postModelQCForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                            </ButtonContainer>
                                                        </>}

                                                    {approve === "productrejected" &&
                                                        <>
                                                            <Form fd={"column"} width={"45%"}>
                                                                <Input label={"Rejection Reason"} onChange={handleModelQCForm}
                                                                    name={"productRejectionReason"} />
                                                            </Form>
                                                            <ButtonContainer jc={"center"}>
                                                                <Button name={loading ? "loading..." : "cancel"} onClick={clearForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                                <Button name={loading ? "loading..." : "submit"} onClick={postModelQCForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                            </ButtonContainer>
                                                        </>}
                                                </Body>
                                            }


                                            {(selectedValue[0].videoEditToQCStatus === "pending") && (selectedValue[0].modelShootEditToQCStatus === "" || selectedValue[0].modelShootEditToQCStatus === "completed") &&
                                                <Body>

                                                    {approve === "" && <>
                                                        <Link>
                                                            <div>Video Shoot File Link</div>
                                                            <a href={selectedValue[0].videoToEditorPhoto} target="_blank" rel="noopener noreferrer">{selectedValue[0].videoToEditorPhoto}</a>
                                                        </Link>

                                                        <ButtonContainer jc={"center"}>
                                                            <Button name={loading ? "loading..." : "reject"} onClick={"handleModelReject"} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                            <Button name={loading ? "loading..." : "approve"} onClick={"handleModelApprove"} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                        </ButtonContainer>
                                                        <Empty />
                                                    </>}

                                                    {approve === "modelapproved" &&
                                                        <>
                                                            <Form fd={"column"} width={"45%"}>
                                                                <Input label={"Enter 'Closed' to proceed"} onChange={handleModelQCForm}
                                                                    name={"photoModelLink"} />
                                                            </Form>
                                                            <ButtonContainer jc={"center"}>
                                                                <Button name={loading ? "loading..." : "cancel"} onClick={clearForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                                <Button name={loading ? "loading..." : "submit"} onClick={postModelQCForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                            </ButtonContainer>
                                                        </>}

                                                    {approve === "modelrejected" &&
                                                        <>
                                                            <Form fd={"column"} width={"45%"}>
                                                                <Input label={"Rejection Reason"} onChange={handleModelQCForm}
                                                                    name={"modelRejectionReason"} />
                                                            </Form>
                                                            <ButtonContainer jc={"center"}>
                                                                <Button name={loading ? "loading..." : "cancel"} onClick={clearForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                                <Button name={loading ? "loading..." : "submit"} onClick={postModelQCForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                            </ButtonContainer>
                                                        </>}
                                                </Body>
                                            }

                                            {selectedValue[0].modelShootEditToQCStatus === "pending" && selectedValue[0].videoEditToQCStatus === "pending" &&
                                                <Body>
                                                    {approve === "" && <>
                                                        <Link>
                                                            <div>Model Shoot File Link</div>
                                                            <a href={selectedValue[0].modelShootToEditorPhoto} target="_blank" rel="noopener noreferrer">{selectedValue[0].modelShootToEditorPhoto}</a>
                                                        </Link>

                                                        <ButtonContainer jc={"center"}>
                                                            <Button name={loading ? "loading..." : "reject"} onClick={handleProductReject} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                            <Button name={loading ? "loading..." : "approve"} onClick={handleProductApprove} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                        </ButtonContainer>
                                                        <Empty />
                                                    </>}

                                                    {approve === "productapproved" &&
                                                        <>
                                                            <Form fd={"column"} width={"45%"}>
                                                                <Input label={"Enter 'Closed' to proceed"} onChange={handleModelQCForm}
                                                                    name={"photoProductLink"} />
                                                            </Form>
                                                            <ButtonContainer jc={"center"}>
                                                                <Button name={loading ? "loading..." : "cancel"} onClick={clearForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                                <Button name={loading ? "loading..." : "submit"} onClick={switchForm2} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                            </ButtonContainer>
                                                        </>}

                                                    {approve === "productrejected" &&
                                                        <>
                                                            <Form fd={"column"} width={"45%"}>
                                                                <Input label={"Rejection Reason"} onChange={handleModelQCForm}
                                                                    name={"productRejectionReason"} />
                                                            </Form>
                                                            <ButtonContainer jc={"center"}>
                                                                <Button name={loading ? "loading..." : "cancel"} onClick={clearForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                                <Button name={loading ? "loading..." : "submit"} onClick={switchForm2} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                            </ButtonContainer>
                                                        </>}

                                                    {approve === "switched" && <>
                                                        <Link>
                                                            <div>Video Shoot File Link</div>
                                                            <a href={selectedValue[0].videoToEditorPhoto} target="_blank" rel="noopener noreferrer">{selectedValue[0].videoToEditorPhoto}</a>
                                                        </Link>

                                                        <ButtonContainer jc={"center"}>
                                                            <Button name={loading ? "loading..." : "reject"} onClick={"handleModelReject"} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                            <Button name={loading ? "loading..." : "approve"} onClick={"handleModelApprove"} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                        </ButtonContainer>
                                                        <Empty />
                                                    </>}

                                                    {approve === "modelapproved" &&
                                                        <>
                                                            <Form fd={"column"} width={"45%"}>
                                                                <Input label={"Enter 'Closed' to proceed"} onChange={handleModelQCForm}
                                                                    name={"photoModelLink"} />
                                                            </Form>
                                                            <ButtonContainer jc={"center"}>
                                                                <Button name={loading ? "loading..." : "cancel"} onClick={clearForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                                <Button name={loading ? "loading..." : "submit"} onClick={postModelQCForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                            </ButtonContainer>
                                                        </>}

                                                    {approve === "modelrejected" &&
                                                        <>
                                                            <Form fd={"column"} width={"45%"}>
                                                                <Input label={"Rejection Reason"} onChange={handleModelQCForm}
                                                                    name={"modelRejectionReason"} />
                                                            </Form>
                                                            <ButtonContainer jc={"center"}>
                                                                <Button name={loading ? "loading..." : "cancel"} onClick={clearForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                                <Button name={loading ? "loading..." : "submit"} onClick={postModelQCForm} bgcolor={"#fcbf49"} color={"var(--font-color)"} />
                                                            </ButtonContainer>
                                                        </>}

                                                </Body>
                                            }
                                        </>
                                    } */}

                                </Forms>}


                        </CenterBox>}

                    {selectedValue === "" &&
                        <CenterBox>
                            <StandardMsg color={"#fcbf49"} value={"click cards to view"} />
                        </CenterBox>
                    }

                    {showStepper && selectedValue !== "" &&
                        <CenterBox>
                            {selectedValue !== "" &&
                                <Stepper selectedSKUValue={selectedValue} home={true} setShowStepper={setShowStepper} />
                            }
                        </CenterBox>
                    }

                </>
                :
                <CenterBox>
                    <Loader />
                </CenterBox>
            }

            <RightContainer
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                    type: "spring",
                    stiffness: 100,
                    duration: 0.5, delay: 0.8
                }}
            >
                <RightBox setSelected={setSelected} setShowStepper={setShowStepper} setLoading={setLoading} />
            </RightContainer>
        </Container >
    )
}

export default Homepage