import { useContext, useEffect } from "react"
import styled from "styled-components"
import { DataContext } from "../../Contexts/DataContext"
import { motion } from "framer-motion"

const Container = styled(motion.div)`
    position: absolute;
    top: 100px;
    left: 350px;
    background-color: white;
    height: 300px;
    width: 300px;
    // z-index: 1000;
`
const ImageRounded = styled.div`
`

const HoverableDiv1 = (imageLink) => {

    const { imageUrl } = useContext(DataContext)

    return (
        <Container
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
                type: "spring",
                stiffness: 100,
                duration: 0.5
            }}
        >
            <ImageRounded>
                <img style={{
                    height: "300px", width: "300px", borderRadius: "5px", objectFit: "cover", objectPosition: "center"

                }} src={imageUrl + imageLink["url"]} alt="" />
            </ImageRounded>
        </Container>
    )
}

export default HoverableDiv1