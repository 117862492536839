import BarLoader from "react-spinners/BarLoader"
import styled from "styled-components"

const Container = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    letter-spacing: 1.2px;
    color: #03045e;
`

const Loader = () => {
    return (
        <Container>
            <BarLoader color={"#fcbf49"} height={2} width={150} />
        </Container>
    )
}

export default Loader