import styled from "styled-components"
import { motion } from "framer-motion"
import { useContext, useEffect, useRef } from "react"
import { DataContext } from "../Contexts/DataContext"
import { useState } from "react"
import { FetchData } from "../Components/Subcomponents/FetchData"
import Loader from "../Components/Subcomponents/Loader"
import Barcode from 'react-barcode';
import Input from "../Components/Subcomponents/Input"
import html2canvas from 'html2canvas';


const Container = styled(motion.div)`
    width: 100%;
    min-height: 100%;
    max-height: 85vh;
    display: flex;
`
const Body = styled(motion.div)`
    width: 100%;
    min-height: 100%;
    display: flex;
`
const Left = styled(motion.div)`
    width: 50%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #caf0f8;
    box-shadow: var(--box-shadow);
    border-radius: 10px;
`
const Right = styled(motion.div)`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

const Message = styled.div`
    height: 100%;
    width: 100%;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: var(--ls);
    font-size: var(--font-l);
    color: grey;
`

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    margin: auto;
    margin-top: 20px;
    width: max-content;
    background-color: #ffc8dd;
    padding: 6px 20px;
    font-size: var(--font-s);
    letter-spacing: 1px;
    text-transform: caplitalize;
    border-radius: 3px;
`

const InnerBody = styled.div`
    height: calc(100% - 35px);
    width: 100%;
    display: flex;
    padding: 10px;
    font-size: var(--font-s);
    letter-spacing: 1px;
    text-transform: caplitalize;
    font-style: italic;
    flex-direction: column;
    gap: 20px;
`
const InnerBody1 = styled.div`
    height: calc(100% - 35px);
    width: 100%;
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
    font-size: var(--font-s);
    letter-spacing: 1px;
    text-transform: caplitalize;
    flex-direction: column;
`
const Tooltip = styled.div`
    height: 20px;
    padding-top: 10px;
`
const FirstCodeContainer = styled.div`
    max-height: 100%;
    width: 100%;
    overflow-y: scroll;
    font-size: var(--fsm);

    &::-webkit-scrollbar {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-track {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-thumb {
        width: 5px;
        background-color: #ffbe0b;
    }

`
const FirstCodeBox = styled.div`
    width: 100%;
    display: flex;
    text-transform: uppercase;
    font-style: normal;
    padding: 5px;
    align-items: center;
    letter-spacing: 0px;
    &:nth-child(even) {
        background-color: rgba(255,205,178, 0.3);
      }
      &:nth-child(odd) {
        background-color: rgba(248,249,250,1);
      }

`
const FC = styled.div`
    width: 33%;
`
const Cat = styled.div`
    width: 33%;    
`
const Created = styled.div`
    width: 33%;
    text-wrap: no-wrap;
    text-transform: capitalize;
`
const BarcodeContainer = styled.div`
      height: 160px;
      width: 280px;
      background-color: white;
`

const BarcodeDetails = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px;
    gap: 5px;
`
const Bar = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
`

const Name = styled.div`
    display: flex;
    align-items: flex-start;
    padding-left: 8px;
    width: 100%;
    height: 20px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.5px;
`
const MRP = styled.div`
    display: flex;
    align-items: flex-start;
    padding-left: 8px;
    text-transform: capitalise;
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0.5px;
`
const Color = styled.div`
    display: flex;
    align-items: flex-start;
    padding-left: 8px;
    text-transform: capitalise;
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0.5px;
`

const ButtonContainer = styled.div`
      width: 100%;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: var(--font-m);
`
const Button = styled.div`
      background-color: #3d348b;
      letter-spacing: 0.2px;
      padding: 6px 15px;
      box-shadow: var(--box-shadow);
      color: white;
      cursor: pointer;
      border-radius: 2px;
`

const BarcodePrint = () => {

    const { url } = useContext(DataContext)

    const [allFirstCode, setAllFirstCode] = useState('')
    const [load, setLoad] = useState(true)
    const [message, setMessage] = useState("")
    const [selected, setSelected] = useState("")
    const [selectedSKU, setSelectedSKU] = useState("")


    useEffect(() => {
        if (selected !== "") {
            if (allFirstCode !== "") {
                var select = allFirstCode.filter(el => el.firstCode == selected)
                setSelectedSKU(select)
            }
        }
    }, [selected, allFirstCode])

    const wrapper_ref = useRef();

    useEffect(() => {
        async function fetchAll() {
            setLoad(true)
            const response = await FetchData({
                "endpoint": url + "create/firstCodeFetchAll",
            })

            if (response.error === "no error") {
                setAllFirstCode(response.data)
                setLoad(false)
            } else {
                setMessage(response.message)
            }
        }
        fetchAll()
        // eslint-disable-next-line
    }, [])

    const onClick = (e) => {
        const opt = {
            scale: 1
        }
        const elem = wrapper_ref.current;
        html2canvas(elem, opt).then(canvas => {
            const iframe = document.createElement('iframe')
            iframe.name = 'printf'
            iframe.id = 'printf'
            iframe.height = 0;
            iframe.width = 0;
            document.body.appendChild(iframe)

            const imgUrl = canvas.toDataURL({
                format: 'jpeg',
                quality: '1.0'
            })

            const style = `
                height:160px;
                width:280px;
                position:absolute;
                left:0:
                top:0;
            `;

            const url = `<img style="${style}" src="${imgUrl}"/>`;
            var newWin = window.frames["printf"];
            newWin.document.write(`<body onload="window.print()">${url}</body>`);
            newWin.document.close();

        });
    }


    return (

        <Container
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
                duration: 0.5
            }}
        >
            {!load ?
                <Body>
                    {message === "" ?
                        <>
                            <Left>
                                <Header>
                                    Print Barcodes
                                </Header>
                                <InnerBody>
                                    <Tooltip>
                                        Click any First Code to generate barcode
                                    </Tooltip>
                                    {allFirstCode.length > 0 &&
                                        <FirstCodeContainer>
                                            {allFirstCode.map((code, i) => {
                                                return <FirstCodeBox key={i} onClick={() => setSelected(code.firstCode)}>
                                                    <FC>
                                                        {code.firstCode}
                                                    </FC>
                                                    <Cat>
                                                        {code.productCategory}
                                                    </Cat>
                                                    <Created>
                                                        {code['firstCodeCreatedTime'].replace("GMT+0000 (Coordinated Universal Time)", "")}
                                                    </Created>
                                                </FirstCodeBox>
                                            })
                                            }
                                        </FirstCodeContainer>
                                    }
                                </InnerBody>

                            </Left>
                            <Right>
                                <Header>
                                    Barcode Preview
                                </Header>
                                {selectedSKU !== "" &&
                                    <InnerBody1>
                                        <BarcodeContainer>
                                            <BarcodeDetails ref={wrapper_ref}>
                                                <Bar>
                                                    <Barcode value={selectedSKU[0].firstCode} height={40} width={1.6} displayValue={false} />
                                                </Bar>
                                                <Name>
                                                    {selected}
                                                </Name>
                                                <MRP>
                                                    M.R.P (Inclusive of all taxes) : {selectedSKU[0].mrp}
                                                </MRP>
                                                <Color>
                                                    Color: {selectedSKU[0].baseColor}
                                                </Color>
                                            </BarcodeDetails>
                                        </BarcodeContainer>
                                        <ButtonContainer>
                                            <Button onClick={onClick}>Print Barcode</Button>
                                        </ButtonContainer>
                                    </InnerBody1>
                                }
                            </Right>
                        </>
                        :
                        <Message>
                            {message}
                        </Message>
                    }
                </Body>
                :
                <Loader />
            }


        </Container >
    )
}

export default BarcodePrint