import styled from "styled-components"
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Button from "../Components/Subcomponents/Button";


const Container = styled(motion.div)`
    min-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-transform: capitalize;  
    letter-spacing: var(--ls);
    font-size: 16px;
    gap: 20px;
    color: #03045e;
`

const A = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
`
const B = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
`
const Span = styled(motion.div)`
`
const ButtonContainer = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
`

const LoginSuccessful = ({ isAutoApproved }) => {

    const navigate = useNavigate()

    const cancelClick = () => {
        navigate("/")
    }

    return (
        <>
            {!isAutoApproved
                ?
                <Container>
                    <A>
                        <Span
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.2 }}>
                            Successfully signed up.
                        </Span>
                    </A>
                    <B>
                        <Span
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.7 }}>
                            Pending approval from <span style={{ color: "#e63946", letterSpacing: "1.5px", fontWeight: "600" }}>ADMIN</span>
                        </Span>
                    </B>
                    <ButtonContainer
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 1 }}
                    >
                        <Button name={"close"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} onClick={cancelClick} />
                    </ButtonContainer>
                </Container >
                :
                <Container>
                    <A>
                        <Span
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.2 }}>
                            Successfully signed up.
                        </Span>
                    </A>
                    <B>
                        <Span
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.7 }}>
                            You can now login to your <span style={{ color: "#e63946", letterSpacing: "1.5px", fontWeight: "600" }}>ADMIN</span> profile
                        </Span>
                    </B>
                    <ButtonContainer
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 1 }}
                    >
                        <Button name={"close"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} onClick={cancelClick} />
                    </ButtonContainer>
                </Container >

            }
        </>
    )
}

export default LoginSuccessful