import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { FetchData } from "../Components/Subcomponents/FetchData"
import HoverableDiv from "../Components/Subcomponents/HoverableDiv"
import Loader from "../Components/Subcomponents/Loader"
import StandardMsg from "../Components/Subcomponents/StandardMsg"
import Stepper from "../Components/Subcomponents/Stepper"
import { DataContext } from "../Contexts/DataContext"
import { AiOutlineDownload } from "react-icons/ai"
import { CSVLink } from "react-csv";
import { motion } from "framer-motion"
import * as XLSX from "xlsx/xlsx";


const Container = styled(motion.div)`
    display: flex;
    width: 100vw;
    height: calc(100vh - 80px);
    gap: 10px;
`

const Left = styled(motion.div)`
    height: 100%;
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Center = styled(motion.div)`
    height: 100%;
    width: 38%;
    // background-color: #f6fff8;
    box-shadow: var(--box-shadow);
    border-radius: 10px;
`

const Right = styled(motion.div)`
    height: 100%;
    width: 25%;
    // background-color: #d8f3dc;
    border-radius: 10px;
`

const KPIContainer = styled(motion.div)`
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    width: 100%;
    height: 100%;
    padding: 10px;
`

const KPI = styled(motion.div)`
    width: 100px;
    height: 80px;
    display:  flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    border-radius: 5px;
    box-shadow: var(--box-shadow);
    letter-spacing: var(--ls);
    font-size: 11px;
    cursor: pointer;
    background-color: ${props => props.color}
`

const Heading = styled.div`
`

const Value = styled.div`
    font-size: var(--font-l);
    font-weight: 600;
`
const Message = styled.div`
    height: 100%;
    width: 100%;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: var(--ls);
    font-size: var(--font-l);
    color: grey;
`
const Table = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: var(--font-s);
    text-transform: uppercase;
    letter-spacing: var(--ls);
`
const Header = styled(motion.div)`
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    border-bottom: 0.5px solid lightgrey;
    background-color: #f8ffe5;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
`
const Body = styled(motion.div)`
    height: calc(100% - 50px);
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    position: relative;
    &::-webkit-scrollbar {
        width: 0px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-track {
        width: 0px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-thumb {
        width: 0px;
        background-color: #D6EAF8;
    }


`
const Content = styled.div`
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid lightgrey;
    font-size: var(--font-xs);
    padding: 0px 5px;
`
const RH = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.width};
`
const RHB = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffd166;
    width: ${props => props.width};
    padding: 5px;
    border-radius: 3px;
    box-shadow: var(--box-shadow);
`

const ImageRounded = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
`

const Dashboard = () => {

    const { imageUrl } = useContext(DataContext)

    const [isHovering, setIsHovering] = useState(false)
    const [imageLink, setImageLink] = useState("")


    const handleHover = (value, image) => {
        if (value === true) {
            setIsHovering(true)
            setImageLink(image)
        } else {
            setIsHovering(false)
            setImageLink(image)
        }
    }

    const [toFetch, setToFetch] = useState("")
    const [timeField, setTimeField] = useState("")

    const { useableRoles, url } = useContext(DataContext)
    const [loading, setLoading] = useState(false)
    const [loading1, setLoading1] = useState(false)

    const [message, setMessage] = useState("")
    const [message1, setMessage1] = useState("")

    const [toShow, setToShow] = useState(1)
    const [toShow1, setToShow1] = useState(2)

    const [data, setData] = useState("")
    const [allData, setAllData] = useState("")

    const date = new Date()

    const colors = ["#e7c6ff", "#c7f9cc", "#e7c6ff", "#ffe1a8", "#fdfcdc", "#e0fbfc", "#f7ede2", "#ffe5ec", "#fdfcdc", "#e0fbfc", "#f7ede2", "#ffe5ec", "#fdfcdc", "#e0fbfc", "#f7ede2", "#ffe5ec", "#c7f9cc", "#fde2e4", "#ffe1a8"]


    const handleToFetch = (e) => {
        setToFetch(e)
    }

    useEffect(() => {

        if (toFetch === "Created") {
            setTimeField("firstCodeCreatedTime")
        } else if (toFetch === "Pending") {
            setTimeField("firstCodeCreatedTime")
        } else if (toFetch === "Merchandiser") {
            setTimeField("merchCreatedTime")
            // } else if (toFetch === "ProductOps") {
            //     setTimeField("productOpsStartTime")
        } else if (toFetch === "Pricing") {
            setTimeField("pricingStartTime")
        } else if (toFetch === "Buyer") {
            setTimeField("buyerStartTime")
        } else if (toFetch === "ProductShoot") {
            setTimeField("productShootStartTime")
        } else if (toFetch === "ModelShoot") {
            setTimeField("modelShootStartTime")
        } else if (toFetch === "MoodShoot") {
            setTimeField("moodShootStartTime")
        } else if (toFetch === "VideoShoot") {
            setTimeField("videoStartTime")
        } else if (toFetch === "ProductEditor") {
            setTimeField("productShootToEditorStartTime")
        } else if (toFetch === "ModelEditor") {
            setTimeField("modelShootToEditorStartTime")
        } else if (toFetch === "MoodEditor") {
            setTimeField("moodShootToEditorStartTime")
        } else if (toFetch === "VideoEditor") {
            setTimeField("videoToEditorStartTime")
        } else if (toFetch === "ProductQC") {
            setTimeField("productShootEditToQCStartTime")
        } else if (toFetch === "ModelQC") {
            setTimeField("modelShootEditToQCStartTime")
        } else if (toFetch === "MoodQC") {
            setTimeField("moodShootEditToQCStartTime")
        } else if (toFetch === "VideoQC") {
            setTimeField("videoEditToQCStartTime")
        } else if (toFetch === "Catalog") {
            setTimeField("catalogStartTime")
        }
    }, [toFetch])


    useEffect(() => {
        async function fetchFn() {
            setLoading(true)
            const response = await FetchData({
                "endpoint": url + "dash/kpi",
            })

            if (response.error === "no error") {
                setLoading(false)
                setMessage("")
                setToShow(1)
                setData(response.data)
            } else {
                setMessage("No data to show")
                setToShow(2)
                setLoading(false)
            }
        }
        fetchFn()
    }, [])


    useEffect(() => {
        async function fetchFn() {
            setLoading1(true)
            const response = await FetchData({
                "endpoint": url + "dash/data",

                data: {
                    "toFetch": toFetch
                }
            })

            if (response.error === "no error" && response.data !== "no data") {
                setLoading1(false)
                setMessage1("")
                setToShow1(1)
                setAllData(response.data)
            } else {
                setMessage1("No data to show")
                setToShow1(2)
                setLoading1(false)
            }
        }
        fetchFn()
    }, [toFetch])

    //////////////////////////// Selected SKU /////////////////////////////

    const [selectedSKU, setSelectedSKU] = useState("")
    const [selectedSKUValue, setSelectedSKUValue] = useState("")
    const [loading2, setLoading2] = useState(false)
    const [message2, setMessage2] = useState(false)
    const [toShow2, setToShow2] = useState(2)


    const handleSelectedSKU = (e) => {
        setLoading2(true)
        setSelectedSKU(e)
        setLoading2(false)
    }


    useEffect(() => {
        async function fetchFn() {
            setLoading2(true)
            const response = await FetchData({
                "endpoint": url + "boxview/fetchhome",

                data: {
                    "selected": selectedSKU
                }
            })

            if (response.error === "no error") {
                setLoading2(false)
                setMessage2("")
                setToShow2(1)
                await setSelectedSKUValue(response.data)
            } else {
                setMessage2("No data to show")
                setToShow2(2)
                setLoading2(false)
            }
        }
        fetchFn()
    }, [selectedSKU])


    //////////////////////////// Set Priority /////////////////////////////

    const [selectedSKUPriority, setSelectedSKUPriority] = useState("")


    const handleSelectedSKUPriority = (e) => {
        setSelectedSKUPriority(e)
    }


    useEffect(() => {
        async function fetchFn() {
            setLoading1(true)
            const response = await FetchData({
                "endpoint": url + "dash/setpriority",

                data: {
                    "selected": selectedSKUPriority
                }
            })

            if (response.error === "no error") {
                setLoading1(false)
                window.location.reload()
            } else {
                setLoading1(false)
                setMessage2("No data to show")
            }
        }
        fetchFn()
    }, [selectedSKUPriority])


    //////////////////////////// Handle Download /////////////////////////////

    const [listProduct, setListProduct] = useState("")
    const [fetched, setFetched] = useState(false)
    const [newLists, setNewLists] = useState('')
    const [newLists1, setNewLists1] = useState('')

    const handleFetched = () => {
        setFetched(false)
    }

    const downloadExcel = (data) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "DataSheet.xlsx");
    };

    const handleDownload = async () => {
        const response = await FetchData({
            "endpoint": url + "dash/download",
            data: {
                "toFetch": toFetch
            }
        })

        if (response.error === "no error") {
            await setListProduct(response.data)

            var newList = []
            for (var i = 0; i < response['data'].length; i++) {
                var myObject = {}
                myObject["Category Code"] = response['data'][i].productCategory
                myObject["Product Code"] = response['data'][i].firstCode
                myObject["Name"] = response['data'][i].title
                myObject["Description"] = response['data'][i].description
                myObject["Scan Identifier"] = ""
                myObject["Length (mm)"] = response['data'][i].productLength
                myObject["Width (mm)"] = response['data'][i].productWidth
                myObject["height (mm)"] = ""
                myObject["Weight (gms)"] = response['data'][i].productWeight
                myObject["ean"] = ""
                myObject["upc"] = ""
                myObject["isbn"] = ""
                myObject["color"] = response['data'][i].baseColor
                myObject["brand"] = "Rubans"
                myObject["size"] = ""
                myObject["Requires Customization"] = ""
                myObject["Min Order Size"] = ""
                myObject["Tax Type Code"] = ""
                myObject["GST Tax Type Code"] = ""
                myObject["HSN Code"] = ""
                myObject["Tags"] = ""
                myObject["TAT"] = ""
                myObject["Image Url"] = ""
                myObject["Product Page URL"] = ""
                myObject["Item Detail Fields"] = ""
                myObject["Cost Price"] = response['data'][i].costPrice
                myObject["MRP"] = response['data'][i].mrp
                myObject["Base Price"] = ""
                myObject["Enabled"] = ""
                myObject["Resync Inventory"] = ""
                myObject["Type"] = ""
                myObject["Scan Type"] = ""
                myObject["Component Product Code"] = ""
                myObject["Component Quantity"] = ""
                myObject["Component Price"] = ""
                myObject["Batch Group Code"] = ""
                myObject["Dispatch Expiry Tolerance"] = ""
                myObject["Shelf Life"] = ""
                myObject["Tax Calculation Type"] = ""
                myObject["Expirable"] = ""
                myObject["Determine Expiry From"] = ""
                myObject["grn Expiry Tolerance"] = ""
                myObject["Return Expiry Tolerance"] = ""
                myObject["Expiry Date as dd/MM/yyyy"] = ""
                myObject["Sku Type"] = ""
                newList.push(myObject)
            }
            setNewLists(newList)

            var newList1 = []
            for (var i = 0; i < response['data'].length; i++) {
                var myObject1 = {}
                myObject1["_id"] = response['data'][i]._id
                myObject1["Product Code"] = response['data'][i].firstCode
                myObject1["Product Name"] = response['data'][i].productName
                myObject1["Category Code"] = response['data'][i].categoryCode
                myObject1["productCategory"] = response['data'][i].productCategory
                myObject1["firstCodeStatus"] = response['data'][i].firstCodeStatus
                myObject1["firstCodeCreatedTime"] = response['data'][i].firstCodeCreatedTime
                myObject1["photo"] = "https://storage.googleapis.com/rubansimage/" + response['data'][i].photo
                myObject1["costPrice"] = response['data'][i].costPrice
                myObject1["sampleQuantity"] = response['data'][i].sampleQuantity
                myObject1["stock"] = response['data'][i].stock
                myObject1["vendorName"] = response['data'][i].vendorName
                myObject1["title"] = response['data'][i].title
                myObject1["description"] = response['data'][i].description
                myObject1["baseMetal"] = response['data'][i].baseMetal
                myObject1["baseColor"] = response['data'][i].baseColor
                myObject1["look"] = response['data'][i].look
                myObject1["collections"] = response['data'][i].collections
                myObject1["productType"] = response['data'][i].productType
                myObject1["subType"] = response['data'][i].subType
                myObject1["additionalColor"] = response['data'][i].additionalColor
                myObject1["productWeight"] = response['data'][i].productWeight
                myObject1["productLength"] = response['data'][i].productLength
                myObject1["productWidth"] = response['data'][i].productWidth
                myObject1["productHeight"] = response['data'][i].productHeight
                myObject1["tag"] = response['data'][i].tag
                myObject1["merchStatus"] = response['data'][i].merchStatus
                myObject1["mrp"] = response['data'][i].mrp
                myObject1["sellingPrice"] = response['data'][i].sellingPrice
                myObject1["poQty"] = response['data'][i].poQty
                myObject1["pricingStatus"] = response['data'][i].pricingStatus
                myObject1["poNumber"] = response['data'][i].poNumber
                myObject1["buyerPOQuantity"] = response['data'][i].buyerPOQuantity
                myObject1["buyerRemarks"] = response['data'][i].buyerRemarks
                myObject1["buyerStatus"] = response['data'][i].buyerStatus
                myObject1["productShootPhoto"] = response['data'][i].productShootPhoto
                myObject1["productShootStatus"] = response['data'][i].productShootStatus
                myObject1["productShootReEdit"] = response['data'][i].productShootReEdit
                myObject1["productShootUser"] = response['data'][i].productShootUser
                myObject1["modelShootPhoto"] = response['data'][i].modelShootPhoto
                myObject1["modelShootStatus"] = response['data'][i].modelShootStatus
                myObject1["modelShootReEdit"] = response['data'][i].modelShootReEdit
                myObject1["modelShootUser"] = response['data'][i].modelShootUser
                myObject1["moodShootPhoto"] = response['data'][i].moodShootPhoto
                myObject1["moodShootStatus"] = response['data'][i].moodShootStatus
                myObject1["moodShootReEdit"] = response['data'][i].moodShootReEdit
                myObject1["moodShootUser"] = response['data'][i].moodShootUser
                myObject1["video"] = response['data'][i].videoPhoto
                myObject1["videoStatus"] = response['data'][i].videoStatus
                myObject1["videoReEdit"] = response['data'][i].videoReEdit
                myObject1["videoUser"] = response['data'][i].videoUser
                myObject1["productShootToEditorPhoto"] = response['data'][i].productShootToEditorPhoto
                myObject1["productShootToEditorStatus"] = response['data'][i].productShootToEditorStatus
                myObject1["productShootToEditorReEdit"] = response['data'][i].productShootToEditorReEdit
                myObject1["productShootToEditorUser"] = response['data'][i].productShootToEditorUser
                myObject1["modelShootToEditorPhoto"] = response['data'][i].modelShootToEditorPhoto
                myObject1["modelShootToEditorStatus"] = response['data'][i].modelShootToEditorStatus
                myObject1["modelShootToEditorReEdit"] = response['data'][i].modelShootToEditorReEdit
                myObject1["modelShootToEditorUser"] = response['data'][i].modelShootToEditorUser
                myObject1["moodShootToEditorPhoto"] = response['data'][i].moodShootToEditorPhoto
                myObject1["moodShootToEditorStatus"] = response['data'][i].moodShootToEditorStatus
                myObject1["moodShootToEditorReEdit"] = response['data'][i].moodShootToEditorReEdit
                myObject1["moodShootToEditorUser"] = response['data'][i].moodShootToEditorUser
                myObject1["videoToEditorPhoto"] = response['data'][i].videoToEditorPhoto
                myObject1["videoToEditorStatus"] = response['data'][i].videoToEditorStatus
                myObject1["videoToEditorReEdit"] = response['data'][i].videoToEditorReEdit
                myObject1["videoToEditorUser"] = response['data'][i].videoToEditorUser
                myObject1["productShootEditToQCPhoto"] = response['data'][i].productShootEditToQCPhoto
                myObject1["productShootEditToQCStatus"] = response['data'][i].productShootEditToQCStatus
                myObject1["productShootEditToQCReEdit"] = response['data'][i].productShootEditToQCReEdit
                myObject1["productShootEditToQCUser"] = response['data'][i].productShootEditToQCUser
                myObject1["modelShootEditToQCPhoto"] = response['data'][i].modelShootEditToQCPhoto
                myObject1["modelShootEditToQCStatus"] = response['data'][i].modelShootEditToQCStatus
                myObject1["modelShootEditToQCReEdit"] = response['data'][i].modelShootEditToQCReEdit
                myObject1["modelShootEditToQCUser"] = response['data'][i].modelShootEditToQCUser
                myObject1["moodShootEditToQCPhoto"] = response['data'][i].moodShootEditToQCPhoto
                myObject1["moodShootEditToQCStatus"] = response['data'][i].moodShootEditToQCStatus
                myObject1["moodShootEditToQCReEdit"] = response['data'][i].moodShootEditToQCReEdit
                myObject1["moodShootEditToQCUser"] = response['data'][i].moodShootEditToQCUser
                myObject1["videoEditToQCPhoto"] = response['data'][i].videoEditToQCPhoto
                myObject1["videoEditToQCStatus"] = response['data'][i].videoEditToQCStatus
                myObject1["videoEditToQCReEdit"] = response['data'][i].videoEditToQCReEdit
                myObject1["videoEditToQCUser"] = response['data'][i].videoEditToQCUser
                myObject1["catalogID"] = response['data'][i].catalogID
                myObject1["FSNID"] = response['data'][i].FSNID
                myObject1["catalogStatus"] = response['data'][i].catalogStatus
                myObject1["comments"] = response['data'][i].comments
                myObject1["priority"] = response['data'][i].priority
                myObject1["createdAt"] = response['data'][i].createdAt
                myObject1["updatedAt"] = response['data'][i].updatedAt
                newList1.push(myObject1)
            }
            setNewLists1(newList1)
            setFetched(true)
        }
    }


    return (
        <Container
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
                type: "spring",
                stiffness: 100,
                duration: 0.5, delay: 0.3
            }}
        >
            {!loading ?
                <>
                    {toShow === 1 && <>
                        <Left
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{
                                type: "spring",
                                stiffness: 100,
                                duration: 0.5, delay: 0.3
                            }}
                        >
                            <KPIContainer
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5
                                }}
                            >
                                {useableRoles.map((value, index) => {
                                    return <KPI
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{
                                            type: "spring",
                                            stiffness: 100,
                                            duration: 0.5, delay: index * 0.1
                                        }}
                                        key={index} color={colors[index]} onClick={() => handleToFetch(value)}>
                                        <Heading>{value}</Heading>
                                        <Value>{data[value]}</Value>
                                    </KPI>
                                })}
                            </KPIContainer>
                        </Left>
                        <Center
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{
                                type: "spring",
                                stiffness: 100,
                                duration: 0.5, delay: 1.4
                            }}
                        >
                            {!loading1 ?
                                <>
                                    {toShow1 === 1 && <Table>
                                        <Header
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{
                                                type: "spring",
                                                stiffness: 100,
                                                duration: 0.5
                                            }}
                                        >
                                            <RH width={"8%"} style={{ cursor: "pointer" }}>
                                                {toFetch !== "" &&
                                                    <>
                                                        {fetched ?
                                                            <div onClick={() => downloadExcel(newLists)} >
                                                                {/* <CSVLink data={newLists} filename={"data.csv"} enclosingCharacter={`"`}> */}
                                                                <AiOutlineDownload size={20} />
                                                                {/* </CSVLink> */}
                                                            </div>
                                                            :
                                                            <div style={{ padding: "5px", marginLeft: "5px", boxShadow: "var(--box-shadow)", backgroundColor: "#8338ec", fontSize: "10px", color: "white", borderRadius: "2px" }} onClick={() => handleDownload()} >
                                                                F1
                                                            </div>
                                                        }
                                                        {fetched ?
                                                            <div onClick={() => downloadExcel(newLists1)} >
                                                                {/* <CSVLink data={listProduct} filename={"data.csv"} enclosingCharacter={`"`}> */}
                                                                <AiOutlineDownload size={20} />
                                                                {/* </CSVLink> */}
                                                            </div>
                                                            :
                                                            <div style={{ padding: "5px", marginLeft: "5px", boxShadow: "var(--box-shadow)", backgroundColor: "#8338ec", fontSize: "10px", color: "white", borderRadius: "2px" }} onClick={() => handleDownload()} >
                                                                F2
                                                            </div>
                                                        }

                                                    </>
                                                }
                                            </RH>
                                            <RH width={"30%"}>SKU</RH>
                                            <RH width={"16%"}>Ageing</RH>
                                            <RH width={"30%"}>Stock</RH>
                                            <RH width={"16%"}>Priority</RH>
                                        </Header>

                                        {isHovering === true && <HoverableDiv url={imageLink} />}
                                        <Body
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{
                                                type: "spring",
                                                stiffness: 100,
                                                duration: 0.5, delay: 0.2
                                            }}
                                        >
                                            {allData.map((value, index) => {
                                                return <Content key={index}>
                                                    <RH width={"8%"}>
                                                        <ImageRounded onMouseOver={() => handleHover(true, value.photo)} onMouseOut={() => handleHover(false, value.photo)}>
                                                            <img style={{
                                                                height: "40px", width: "40px", borderRadius: "5px", objectFit: "cover", objectPosition: "center"

                                                            }} src={imageUrl + value.photo} alt="" />
                                                        </ImageRounded>
                                                    </RH>
                                                    <RH width={"30%"} style={{ cursor: "pointer", "textDecoration": "underline" }} onClick={() => handleSelectedSKU(value.firstCode)}>{value.firstCode}</RH>
                                                    <RH width={"16%"}>{Math.ceil((Math.abs(date - new Date(value[timeField]))) / (24 * 3600 * 1000))}</RH>
                                                    <RH width={"30%"}>{value.stock}</RH>
                                                    {value.priority === "true" ?
                                                        <RH width={"16%"}>{value.priority} </RH>
                                                        :
                                                        <RHB width={"16%"} style={{ cursor: "pointer" }} onClick={() => handleSelectedSKUPriority(value.firstCode)}>
                                                            Set
                                                        </RHB>
                                                    }
                                                </Content>
                                            })}
                                        </Body>
                                    </Table>
                                    }
                                    {toShow1 === 2 && <StandardMsg color={"#2b2b2b"} value={"click cards to view"} />}
                                </>
                                :
                                <Loader />
                            }
                        </Center>
                        <Right
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{
                                type: "spring",
                                stiffness: 100,
                                duration: 0.5, delay: 1.6
                            }}
                        >
                            {!loading2 ?
                                <>
                                    {toShow2 === 1 &&
                                        <Stepper selectedSKUValue={selectedSKUValue} />}
                                    {toShow2 === 2 &&
                                        <StandardMsg color={"#2b2b2b"} value={"click sku to view"} />
                                    }
                                </>
                                :
                                <Loader />
                            }
                        </Right>
                    </>
                    }

                    {toShow === 2 &&
                        <Message>
                            {message}
                        </Message>}
                </>
                :
                <Loader />
            }
        </Container>
    )
}

export default Dashboard