import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import Button from "../Components/Subcomponents/Button"
import Dropdown from "../Components/Subcomponents/Dropdown"
import Input from "../Components/Subcomponents/Input"
import SVGBox from "../Components/Subcomponents/SVGBox"
import { FetchData, CheckSignUp, CheckLogin } from "../Components/Subcomponents/FetchData"
import { DataContext } from "../Contexts/DataContext"
import { motion } from "framer-motion";
import { useEffect } from "react"


const Container = styled(motion.div)`
    width: 100%;
    min-height: 100%;
    display: flex;
`
const Left = styled.div`
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`
const Right = styled.div`
    width: 60%;
    display: flex;
    align-items: center;
    height: 100%;
    background-color: #ebf2fa;
    border-radius: 10px;
    padding: 20px 50px;
    box-shadow: var(--box-shadow);
`
const Forms = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    gap: 20px;
`
const FormHeader = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 19px;
    color: #03045e;
`
const FormError = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    font-size: var(--font-s);
    color: #f72585;
`
const FormButton = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`
const FormInput = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 20px;
`
const FormMsg = styled.div`
    display: flex;
    gap: 10px;
    // align-items: center;
    width: 100%;
    flex-direction: column;
    text-transform: capitalize;
    font-size: var(--font-s);
    color: var(--font-color);
    text-decoration: underline;
    cursor: pointer;
`

const Login = ({ isLogin }) => {

    const { url, roles, isAdmin } = useContext(DataContext)

    const initialLogin = {
        userName: "",
        passWord: "",
    };

    const initialSignUp = {
        userName: "",
        emailID: "",
        setPassword: "",
        confirmPassword: "",
    };

    const login = isLogin

    const navigate = useNavigate()

    const [loginValue, setLoginValue] = useState(initialLogin);
    const [signUpValue, setSignUpValue] = useState(initialSignUp);

    const [designation, setDesignation] = useState("");
    const [accessLevel, setAccessLevel] = useState("");

    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);


    const handleLoginChange = (e) => {
        const { name, value } = e.target;

        setLoginValue({
            ...loginValue,
            [name]: value,
        });
    };

    const handleSignUpChange = (e) => {
        const { name, value } = e.target;

        setSignUpValue({
            ...signUpValue,
            [name]: value,
        });
    };


    const postLogin = async () => {

        setLoading(true)
        const dataCheck = await CheckLogin(loginValue)

        if (dataCheck['error'] === "no error") {

            const response = await FetchData({
                "endpoint": url + "user/login",
                data: {
                    "loginValue": loginValue,
                }
            })

            if (response.error === "no error" && response.message === "success") {
                localStorage.setItem("user", JSON.stringify(response.data))
                setLoading(false)
                navigate("/home")
            } else if (response.error === "error" && response.message === "pending approval") {
                setLoading(false)
                navigate("/logged")
            } else {
                setLoading(false)
                setMessage(response.message)
            }

        } else {
            setMessage(dataCheck["message"])
            setLoading(false)
        }
    }


    const postSignup = async () => {

        setLoading(true)
        const dataCheck = await CheckSignUp(signUpValue, designation, accessLevel)

        if (dataCheck['error'] === "no error") {

            const response = await FetchData({
                "endpoint": url + "user/add",
                data: {
                    "signUpValue": signUpValue,
                    "designation": designation,
                    "accessLevel": accessLevel
                }
            })

            if (response.error === "no error" && response.message === "Auto Approved") {
                setLoading(false)
                navigate("/success")
            } else if (response.error === "no error" && response.message === "Pending Approval") {
                setLoading(false)
                navigate("/logged")
            } else {
                setMessage(response.message)
                setLoading(false)
            }

        } else {
            setMessage(dataCheck["message"])
            setLoading(false)
        }
    }


    useEffect(() => {

        const reloadCount = sessionStorage.getItem('reloadCount');
        if (reloadCount < 1) {
            sessionStorage.setItem('reloadCount', String(reloadCount + 1));
            localStorage.removeItem("user")
            window.location.reload();
        } else {
            sessionStorage.removeItem('reloadCount');
        }
    }, []);

    return (
        <Container
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
                type: "spring",
                stiffness: 100,
                duration: 0.5, delay: 0.5
            }}
        >
            <Left>
                <SVGBox />
            </Left>
            <Right>
                <Forms>
                    <FormHeader>
                        {login ?
                            <>
                                Log In to your account
                            </>
                            :
                            <>
                                Account Sign Up
                            </>
                        }
                    </FormHeader>
                    <FormError>
                        {message}
                        {/* *Incorrect details entered. Please check */}
                    </FormError>
                    <FormInput>
                        {login ?
                            <>
                                <Input index={5} label={"User Name"} onChange={handleLoginChange}
                                    name={"userName"}
                                />
                                <Input index={6} label={"PassWord"} onChange={handleLoginChange}
                                    name={"passWord"} type={"password"} />
                            </>
                            :
                            <>
                                <Input index={5} label={"User Name"} onChange={handleSignUpChange}
                                    name={"userName"} />
                                <Input index={6} label={"Email ID"} onChange={handleSignUpChange}
                                    name={"emailID"} />
                                <Dropdown index={7} ph={"Designation"} options={roles} onChange={handleSignUpChange}
                                    name={"designation"} fn={setDesignation} />
                                <Dropdown index={8} ph={"Access Level"} options={isAdmin} onChange={handleSignUpChange}
                                    name={"admin"} fn={setAccessLevel} />
                                <Input index={9} label={"Set Password"} onChange={handleSignUpChange}
                                    name={"setPassword"} />
                                <Input index={10} label={"Confirm Password"} onChange={handleSignUpChange}
                                    name={"confirmPassword"} />
                            </>
                        }
                    </FormInput>
                    <FormButton>
                        {login ?
                            <Button index={7} name={loading ? "Loading..." : "submit"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} onClick={postLogin} />
                            :
                            <Button index={11} name={loading ? "Loading..." : "create account"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} onClick={postSignup} />
                        }
                    </FormButton>
                    <FormMsg >
                        {login ?
                            <div onClick={() => { navigate("/signup") }}>
                                New User? Click to Sign up
                            </div>
                            :
                            <div onClick={() => { navigate("/") }}>
                                Already have an account? Click to Login
                            </div>
                        }
                        <div onClick={() => { navigate("/forgetpassword") }}>
                            Forget Password? Click here
                        </div>
                    </FormMsg>
                </Forms>
            </Right>
        </Container>
    )
}

export default Login