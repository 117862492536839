import './App.css';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'
import Homepage from './Pages/Homepage';
import Layout from './Components/Layout';
import Dashboard from './Pages/Dashboard';
import AuthContextProvider from './Contexts/DataContext';
import Login from './Pages/Login';
import Users from './Pages/Users';
import Switch from './Pages/Modal';
import Modal from './Components/Modal';
import CreateSKU from './Pages/CreateSKU';
import LoginSuccessful from './Pages/LoginSuccessful';
import { ProtectedRoute } from "./Components/Subcomponents/ProtectedRoute"
import ForgetPassword from './Pages/ForgetPassword';
import Edit from './Pages/Edit';
import BarcodePrint from './Pages/BarcodePrint';
import Samples from './Pages/Samples';
import MergeQC from './Pages/MergeQC';


function App() {
  return (
    <AuthContextProvider>
      <Router>
        <Layout>
          <Routes>
            <Route exact path="/" element={<Login isLogin={true} />} />
          </Routes>
          <Routes>
            <Route exact path="/users" element={<ProtectedRoute><Users /></ProtectedRoute>} />
          </Routes>
          <Routes>
            <Route exact path="/edit" element={<ProtectedRoute><Edit /></ProtectedRoute>} />
          </Routes>
          <Routes>
            <Route exact path="/signup" element={<Login isLogin={false} />} />
          </Routes>
          <Routes>
            <Route exact path="/forgetpassword" element={<ForgetPassword />} />
          </Routes>
          <Routes>
            <Route exact path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          </Routes>
          <Routes>
            <Route exact path="/home" element={<ProtectedRoute><Homepage /></ProtectedRoute>} />
          </Routes>
          <Routes>
            <Route exact path="/modal" element={<ProtectedRoute><Modal /></ProtectedRoute>} />
          </Routes>
          <Routes>
            <Route exact path="/create" element={<ProtectedRoute><CreateSKU /></ProtectedRoute>} />
          </Routes>
          <Routes>
            <Route exact path="/logged" element={<LoginSuccessful />} />
          </Routes>
          <Routes>
            <Route exact path="/success" element={<LoginSuccessful isAutoApproved={true} />} />
          </Routes>
          <Routes>
            <Route exact path="/barcode" element={<ProtectedRoute><BarcodePrint /></ProtectedRoute>} />
          </Routes>
          <Routes>
            <Route exact path="/samples" element={<ProtectedRoute><Samples /></ProtectedRoute>} />
          </Routes>
          <Routes>
            <Route exact path="/switch" element={<ProtectedRoute><Switch /></ProtectedRoute>} />
          </Routes>
          <Routes>
            <Route exact path="/mergeqc" element={<ProtectedRoute><MergeQC /></ProtectedRoute>} />
          </Routes>

        </Layout>
      </Router>
    </AuthContextProvider>
  );
}

export default App;
