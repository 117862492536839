import styled from "styled-components"
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Button from "../Components/Subcomponents/Button";
import Input from "../Components/Subcomponents/Input";
import { useContext, useState } from "react";
import { DataContext } from "../Contexts/DataContext";
import { FetchData } from "../Components/Subcomponents/FetchData";
import Loader from "../Components/Subcomponents/Loader";


const Container = styled(motion.div)`
    min-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-transform: capitalize;  
    letter-spacing: var(--ls);
    gap: 20px;
    color: #03045e;
    background-color: #e9edc9;
    box-shadow: var(--box-shadow);
`

const A = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
`
const B = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
`
const Span = styled(motion.div)`
`
const Msg = styled(motion.div)`
    font-size: 12px;
    color: red;
`


const ButtonContainer = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    gap: 20px;
`

const MergeQC = () => {

    const { url } = useContext(DataContext)
    const navigate = useNavigate()

    const [query, setQuery] = useState("")
    const [loading, setLoading] = useState("")
    const [message, setMessage] = useState("")
    const [show, setShow] = useState("A")

    const handleInput = (e) => {
        setQuery(e.target.value)
    }

    const cancelClick = () => {
        navigate("/home")
    }

    const cancelClick1 = () => {
        window.location.reload()
    }

    const handleClick = async () => {

        setMessage("")
        setLoading(true)
        if (query != "") {
            const response = await FetchData({
                "endpoint": url + "dropbox/copy",

                data: {
                    "query": query
                }
            })

            if (response.error === "no error") {
                setLoading(false)
                setMessage("")
                setShow("B")
                setQuery("")
                // window.location.reload()
            } else {
                setQuery("")
                setMessage(response.message)
                setLoading(false)
            }
        } else {
            setQuery("")
            setMessage("Please enter SKU")
            setLoading(false)

        }
    }

    return (
        <>
            {show === "A" ?
                <Container>
                    {!loading ?
                        <>
                            <Msg>
                                {message}
                            </Msg>
                            <A>
                                <Span
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.2 }}>
                                    Enter SKU to Fetch Images
                                </Span>
                            </A>
                            <B>
                                <Input label={"SKU"} onChange={handleInput}
                                    name={"sku"} />

                            </B>
                            <ButtonContainer
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.5 }}
                            >
                                <Button name={"close"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} onClick={cancelClick} />
                                <Button name={"Submit"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} onClick={handleClick} />
                            </ButtonContainer>
                        </>
                        :
                        <Loader />
                    }
                </Container >
                :
                <Container>
                    <A>
                        <Span
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.2 }}>
                            Files successfully copied. It may take some time to reflect in dropbox
                        </Span>
                    </A>
                    <ButtonContainer
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.5 }}
                    >
                        <Button name={"close"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} onClick={cancelClick1} />
                    </ButtonContainer>

                </Container>
            }
        </>
    )
}

export default MergeQC