import { useContext, useState } from "react"
import styled from "styled-components"
import Button from "./Subcomponents/Button"
import Dropdown from "./Subcomponents/Dropdown"
import { useNavigate, useLocation } from 'react-router-dom';
import { FetchData } from "./Subcomponents/FetchData";
import { DataContext } from "../Contexts/DataContext";


const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    min-width: 100%;
`

const Body = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 300px;
    min-width: 300px;
    background-color: #ecf8f8;
    border-radius: 5px;
    box-shadow: var(--box-shadow);
    gap: 20px;
    padding: 20px 0px;
    font-size: var(--font-s);
    letter-spacing: var(--ls);
    text-transform: uppercase;
`
const Name = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    width: 200px;
`

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
`

const Value = styled.div`
    display: flex;
    align-items: center;
    width: 200px;
    justify-content: space-between;
    font-size: var(--font-s);
    text-transform: uppercase;
    letter-spacing: var(--ls);
    gap: 15px;
`

const Message = styled.div`
    display: flex;
    align-items: center;
    width: 200px;
    justify-content: center;
    font-size: var(--font-s);
    text-transform: uppercase;
    letter-spacing: var(--ls);
    padding-bottom: 20px;
    text-align: center;
`

const Modal = () => {

    const { url, roles, isAdmin } = useContext(DataContext)

    const navigate = useNavigate()
    const { state } = useLocation();


    const [designation, setDesignation] = useState(state.designation);
    const [accessLevel, setAccessLevel] = useState(state.accessLevel);
    const [msg, setMsg] = useState("")

    const handleClick = async () => {
        const response = await FetchData({
            "endpoint": url + "user/edit",
            data: {
                "userName": state.userName,
                "designation": designation,
                "accessLevel": accessLevel
            }
        })

        if (response.error === "no error") {
            setMsg("User updated successfully")
        } else {
            setMsg(response.message)
        }

    }

    const handleDelete = async () => {
        const response = await FetchData({
            "endpoint": url + "user/delete",
            data: {
                "userName": state.userName
            }
        })

        setMsg(response.message)

    }

    const cancelClick = async () => {
        setMsg("")
        navigate("/users")
    }


    return (
        <Container>
            {msg === "" ?
                <Body>
                    <Name>
                        User: {(state.userName).length > 12 ? (state.userName).slice(0, 11) + "..." : state.userName}

                        <Button name={"delete"} bgcolor={"#ffb4a2"} color={"var(--font-color)"} onClick={handleDelete} />
                    </Name>
                    <Content>
                        <Dropdown ph={state.designation} options={roles}
                            name={"designation"} fn={setDesignation} />
                        <Dropdown ph={state.accessLevel} options={isAdmin}
                            name={"admin"} fn={setAccessLevel} />
                    </Content>
                    <Value>
                        <Button name={"cancel"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} onClick={cancelClick} />
                        <Button name={"Update"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} onClick={handleClick} />
                    </Value>
                </Body>
                :
                <Body>
                    <Message>
                        {msg}
                    </Message>
                    <Button name={"close"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} onClick={cancelClick} />
                </Body>
            }
        </Container >
    )
}

export default Modal