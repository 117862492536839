import styled from "styled-components"
import { motion } from "framer-motion"
import { useContext, useEffect, useRef } from "react"
import { DataContext } from "../Contexts/DataContext"
import Dropdown from "../Components/Subcomponents/Dropdown"
import { useState } from "react"
import Button from "../Components/Subcomponents/Button"
import { CheckForm2, FetchData, SendImage } from "../Components/Subcomponents/FetchData"
import Input from "../Components/Subcomponents/Input"
import Loader from "../Components/Subcomponents/Loader"
import HoverableDiv from "../Components/Subcomponents/HoverableDiv"



const Container = styled(motion.div)`
    width: 100%;
    min-height: 100%;
    display: flex;
    text-transform: uppercase;
`
const Left = styled(motion.div)`
    width: 70%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #caffbf;
    box-shadow: var(--box-shadow);
    border-radius: 10px;
`
const Right = styled(motion.div)`
    width: 30%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
`
const Top = styled(motion.div)`
    width: 100%;
    min-height: 80%;
    display: flex;
    flex-direction: column;
`
const Bottom = styled(motion.div)`
    width: 50%;
    margin: auto;
    min-height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    letter-spacing: var(--ls);
    font-size: var(--font-s);
`

const ButtonBox = styled.div`
    cursor: pointer;
    padding: 5px 10px;
    background-color: #cdb4db;
    border-radius: 3px;
    box-shadow: var(--box-shadow);
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 150px;
`
const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    margin: auto;
    margin-top: 20px;
    width: max-content;
    background-color: #ffdab9;
    padding: 6px 20px;
    font-size: var(--font-s);
    letter-spacing: 1px;
    text-transform: caplitalize;
    border-radius: 3px;
`
const Header1 = styled.div`
    display: flex;
`
const Body = styled.div`
    display: flex;
    padding: 20px;
    min-height: calc(100% - 70px);
    font-size: var(--font-s);
    gap: 20px;
`
const Body1 = styled.div`
    padding-top: 40px;
    min-height: calc(100% - 70px);
    font-size: var(--font-s);
    width: 60%;
    margin: auto;
`
const First = styled.div`
`
const Second = styled.div`
`
const Third = styled.div`
`
const Fourth = styled.div`
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: flex-end;
`
const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${props => props.jc};
    width: 100%;
    margin: auto;
    padding-top: 10px;
`
const FinalCode = styled.div`
    font-size: var(--font-m);
    color: #e63946;
    letter-spacing: 1.2px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 20px;
`
const Heading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    // font-size: var(--font-s);
    letter-spacing: var(--ls);
    color: #861657;
`

const Message = styled.div`
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 30px;
    color: red;
    text-transform: capitalize;
    letter-spacing: var(--ls);
    font-size: var(--font-s);
`
const Message1 = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 10px;
    color: red;
    text-transform: capitalize;
    letter-spacing: var(--ls);
    font-size: var(--font-s);
`
const Success = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    letter-spacing: var(--ls);
    text-transform: capitalize;
    font-size: var(--font-m);
`

const Part = styled.div`
    display: flex;
    width: 100%;
    max-width: 450px;
    margin: auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
`

const Part2 = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
`
const PhotoName = styled.div`
`
const Uploader = styled.div`
`
const Button1 = styled.div`
    text-transform: uppercase;
    background-color: #f6bd60;
    color: var(--font-color);
    padding: 6px 20px;
    border-radius: 3px;
    cursor: pointer;
    font-size: var(--font-s);
    box-shadow: var(--box-shadow);
`
const Random = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    padding: 5px;
    font-size: var(--font-s);
    color: #861657;
    letter-spacing: 0.5px;
`
const Warning = styled.div`
    text-transform: capitalize;
    padding: 10px 5px;
    font-size: var(--font-s);
    color: #861657;
    letter-spacing: 0.5px;
`

const DropDownBox = styled.div`
    max-height: 120px;
    width: 100%;
    background-color: white;
    overflow-y: scroll;
    position: relative;
    &::-webkit-scrollbar {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-track {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-thumb {
        width: 5px;
        background-color: #D6EAF8;
    }
`
const DropdownOption = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #ddfdfe;
    border-bottom: 1px solid #edede9;
    padding: 12px;
    font-size: var(--font-xs);
    letter-spacing: var(--ls);
    text-transform: uppercase;
    cursor: pointer;
    color: var(--font-color);
`

const Code = styled.div`
    width: 100%;
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    letter-spacing: var(--ls);
`
const CodeName = styled.div`
    font-size: var(--font-xs);
`
const Edit = styled.div`
    background-color: #fcbf49;
    padding: 3px 5px;
    border-radius: 2px;
    box-shadow: var(--box-shadow);
    cursor: pointer;
`

const Parts = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    min-width: 100%;
    height: 100%;
`
const Part1 = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    width: 50%;
    height: 100%;
`

const Part3 = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    width: 50%;
    height: 100%;
`

const Image = styled.div`
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
`
const ImageBox = styled.div`
    height: 200px;
    width: 200px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dde5b6;
    box-shadow: var(--box-shadow);
`



const CreateSKU = () => {

    const { url, firstCode, vendor, looks, setVendorList, tags, imageUrl, categoryCodes } = useContext(DataContext)

    const [lastValue, setLastValue] = useState("")
    const [load, setLoad] = useState(false)


    useEffect(() => {
        async function fetchRecent() {
            setLoad(true)
            const response = await FetchData({
                "endpoint": url + "create/lastSKU",
                data: {
                    "firstCode": "firstCode",
                }
            })

            if (response.error === "no error") {
                setLastValue(response.data)
                setLoad(false)
            } else if (response.error === "error" && response.data === "no value") {
                setLoad(false)
            } else {
                setMessage(response.message)
            }
        }
        fetchRecent()
        // eslint-disable-next-line
    }, [])

    const [current, setCurrent] = useState("1")
    const [message, setMessage] = useState("")
    const [message1, setMessage1] = useState("")
    const [brandCode, setBrandCode] = useState("")
    const [collectionType, setCollectionType] = useState("")
    const [collection, setCollection] = useState("")
    const [productCategory, setProductCategory] = useState("")
    const [pc2, setPC2] = useState("")
    const [finalCode, setFinalCode] = useState("")
    const [randomNumber, setRandomNumber] = useState("")

    const [loading, setLoading] = useState(false)
    const [loading1, setLoading1] = useState(false)

    const [firstCodes, setFirstCodes] = useState("")

    const handleChange = async () => {
        setLoading(true)

        // console.log(finalCode)
        const response = await FetchData({
            "endpoint": url + "create/check",
            data: {
                "firstCode": finalCode,
            }
        })

        if (response.error === "no error") {
            setCurrent("2")
            setLoading(false)
            setMessage("")
        } else {
            setMessage(response.message)
            setLoading(false)
        }
    }


    const handleFirstCodes = (e) => {
        setFirstCodes(e.target.value)
    }

    const handleRandomNumber = (e) => {
        setRandomNumber(e.target.value)
    }

    const handleClose = () => {
        setBrandCode("")
        setCollectionType("")
        setCollection("")
        setProductCategory("")
        setPC2("")
        setRandomNumber("")
        setCurrent("1")
        setMessage("")
        window.location.reload()
    }

    ///////////////////// Delete /////////////////////////

    const [toDelete, setToDelete] = useState("")
    const [deleteCurrent, setDeleteCurrent] = useState("1")
    const [loading2, setLoading2] = useState(false)
    const [message2, setMessage2] = useState("")
    const [loading4, setLoading4] = useState(false)

    const handleDeleteOption = async () => {

        if (toDelete !== "") {
            setLoading2(true)
            const response = await FetchData({
                "endpoint": url + "create/check",
                data: {
                    "firstCode": toDelete,
                }
            })

            if (response.error === "error") {
                setDeleteCurrent("2")
                setLoading2(false)
                setMessage1("")
            } else {
                setMessage1("Invalid SKU ID")
                setLoading2(false)
                setToDelete("")
            }
        }
    }

    const handleDelete = async () => {
        setLoading4(true)

        const response = await FetchData({
            "endpoint": url + "create/delete",
            data: {
                "firstCode": toDelete,
            }
        })

        if (response.error === "no error") {
            setLoading4(false)
            setMessage2("SKU ID deleted successfully")
            setDeleteCurrent("3")
        } else {
            setMessage1(response.message)
            setLoading4(false)
        }
    }

    const handleInactive = async () => {
        setLoading1(true)

        const response = await FetchData({
            "endpoint": url + "create/inactive",
            data: {
                "firstCode": toDelete,
            }
        })

        if (response.error === "no error") {
            setLoading1(false)
            setMessage2("SKU ID made inactive successfully")
            setDeleteCurrent("3")
        } else {
            setMessage1(response.message)
            setLoading1(false)
        }
    }


    const handleToDelete = (e) => {
        setToDelete(e.target.value)
    }

    const handleDeleteClose = () => {
        setDeleteCurrent("1")
        setMessage1("")
        setLoading2(false)
        setToDelete("")
    }

    ///////////////////// /////////////////////////

    useEffect(() => {
        var a = firstCode.filter(function (val) {
            return val.title === "brand name" && val.option === brandCode
        }).map(function (item) { return item.code })

        var b = firstCode.filter(function (val) {
            return val.title === collectionType && val.option === collection
        }).map(function (item) { return item.code })

        var c = firstCode.filter(function (val) {
            return val.title === "product category" && val.option === productCategory
        }).map(function (item) { return item.code })

        if (c == "00") {
            c = ""
        }

        var d = firstCode.filter(function (val) {
            return val.title === productCategory && val.option === pc2
        }).map(function (item) { return item.code })

        if (lastValue === "") {
            var number = randomNumber
        } else {
            var number = Number(lastValue.slice(-6)) + 1
        }

        setFinalCode(a + b + c + d + number)
    }, [brandCode, collection, collectionType, productCategory, pc2, finalCode, lastValue])


    ///////////////////// Form 2 /////////////////////////


    const secondForm = {
        costPrice: "", productName: "", productHeight: "", sampleQuantity: "", stock: "", title: "", description: "", baseMetal: "", baseColor: "", collections: "", productType: "", subType: "", additionalColor: "", productWeight: "", productLength: "", productWidth: ""
    };

    const [form2, setForm2] = useState(secondForm);
    const [tag, setTag] = useState("")
    const [look, setLook] = useState("")
    const [categoryCode, setCategoryCode] = useState("")


    const handleManagerForm2 = (e) => {
        const { name, value } = e.target;

        setForm2({
            ...form2,
            [name]: value,
        });
    }

    const postForm2 = async () => {
        setLoading(true)
        const dataCheck = await CheckForm2(form2)

        if (categoryCode != "") {
            if (tag !== "") {
                if (look !== "") {
                    if (finalVendorCode !== "") {
                        if (dataCheck['error'] === "no error") {
                            const response = await FetchData({
                                "endpoint": url + "create/add",
                                data: {
                                    "firstCode": finalCode,
                                    "form2": form2,
                                    "photo": photoPath,
                                    "productCategory": productCategory,
                                    "finalVendorCode": finalVendorCode,
                                    "look": look,
                                    "tag": tag,
                                    "firstCodes": firstCodes,
                                    "categoryCode": categoryCode
                                }
                            })

                            if (response.error === "no error") {
                                setLoading(false)
                                setMessage("")
                                clearForm()
                                setCurrent("3")
                            } else {
                                setMessage(response.message)
                                setLoading(false)
                            }
                        } else {
                            setMessage(dataCheck["message"])
                            setLoading(false)
                        }
                    } else {
                        setMessage("Select Valid Vendor Name")
                        setLoading(false)
                    }
                } else {
                    setMessage("Select Valid Looks")
                    setLoading(false)
                }
            }
            else {
                setMessage("Select Valid Tag")
                setLoading(false)
            }
        } else {
            setMessage("Select Valid Category Code")
            setLoading(false)
        }

    }

    const clearForm = () => {
        setForm2(secondForm)
        setMessage("")
    }

    ///////////////////// Reset Form for Changes /////////////////////////

    useEffect(() => {
        setRandomNumber("")
        setCollectionType("")
        setCollection("")
        setProductCategory("")
        setPC2("")
    }, [brandCode])


    useEffect(() => {
        setCollection("")
        setProductCategory("")
        setPC2("")
    }, [collectionType])

    useEffect(() => {
        setProductCategory("")
        setPC2("")
    }, [collection])



    ///////////////////// Image Upload /////////////////////////

    const [photoName, setPhotoName] = useState({ 1: "", 2: "", 3: "", 4: "", 5: "" });
    const [photo, setPhoto] = useState(null)
    const [photoPath, setPhotoPath] = useState([])
    const hiddenFileInput = useRef(null);
    const [uploading, setUploading] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [extension, setExtension] = useState("")

    const handlePhoto = () => {
        hiddenFileInput.current.click();
    };

    const handleUpload = (e) => {
        setPhoto(e.target.files[0])
    }

    useEffect(() => {
        async function fetchFn() {
            if (photo) {
                setUploading(true)
                const response = await SendImage({ "endpoint": url + "create/upload", "photo": photo, "firstCode": finalCode })

                if (response.error === "no error") {
                    var replaced = response.path.replace(/\//g, "/")
                    await setPhotoPath(replaced)
                    await setPhotoName(response.firstCode)
                    await setExtension(response.ext)
                    setShowForm(true)
                    setMessage("")
                    setUploading(false)
                }
            }
        }
        fetchFn()
        // eslint-disable-next-line
    }, [photo])


    ///////////////////////////// Image Hover //////////////////////////////////

    const [isHovering, setIsHovering] = useState(false)
    const [imageLink, setImageLink] = useState("")


    const handleHover = (value, image) => {
        if (value === true) {
            setIsHovering(true)
            setImageLink(image)
        } else {
            setIsHovering(false)
            setImageLink(image)
        }
    }


    ///////////////////////////// Vendor Code //////////////////////////////////

    const [suggestions, setSuggestions] = useState([])
    const [finalVendorCode, setFinalVendorCode] = useState("")
    const [vendorTemp, setVendorTemp] = useState(finalVendorCode)

    // useEffect(() => {
    //     console.log(vendorTemp)
    //     // console.log(vendor)
    // })

    const handleVendor = (e) => {
        setVendorTemp(e.target.value)
    }

    const handleFinalVendorCode = (e) => {
        setFinalVendorCode(e)
        setVendorTemp(e)
    }

    // useEffect(() => {
    //     console.log("finalVendorCode", finalVendorCode)
    // }, [finalVendorCode])


    // useEffect(() => {
    //     if (vendor.length > 0) {

    //         const array = vendor.filter(vendors => {
    //             var list = []
    //             // console.log(vendors.vendorName)
    //             list.push(vendors.vendorName)
    //             var filtered = list.filter(function (el) {
    //                 return el != null;
    //             });
    //             var arr = filtered.filter(name => name.includes(vendorTemp))
    //             // console.log(filtered.includes(vendorTemp))
    //             // console.log(arr)
    //             return arr
    //         })

    //         console.log(array)

    //         //Clears Data If Search Input Field Is Empty
    //         if (vendorTemp.length === 0) {
    //             setSuggestions([])
    //         }
    //     }

    //     console.log(suggestions)
    // }, [vendorTemp])

    useEffect(() => {
        if (vendor) {
            if (vendor.length > 0) {

                setSuggestions(vendor.filter(vendors => {
                    return (vendors.vendorName).toLowerCase().match(vendorTemp.toLowerCase());
                }))
            }
            //Clears Data If Search Input Field Is Empty
            if (vendorTemp.length === 0) {
                setSuggestions([])
            }
        }
    }, [vendorTemp])


    // useEffect(() => {
    //     console.log(vendor)
    // })


    /////////////////////////////// Sync Inventory ///////////////////////////////

    const [sync, setSync] = useState("Sync Inventory")

    const handleSync = async () => {
        setSync("Syncing...")
        const response = await FetchData({
            "endpoint": url + "uniware/inventory",
        })
        if (response.error === "no error") {
            setSync("Sync Inventory")
        } else {
            setMessage("Error")
        }
    }

    /////////////////////////////// Sync Vendor ///////////////////////////////

    const [sync1, setSync1] = useState("Update Vendor")

    const handleFetchVendor = async () => {
        setSync1("Updating...")
        const response = await FetchData({
            "endpoint": url + "uniware/vendors",
        })
        if (response.error === "no error") {
            setSync1("Update Vendor")
            setVendorList(response.data)
            sessionStorage.setItem("vendors", JSON.stringify(response.data))
        } else {
            setMessage("Error")
        }
    }

    useEffect(() => {
        // console.log(vendor)
    })

    return (
        <Container
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
                duration: 0.5
            }}
        >
            {!load ?
                <Left>
                    {isHovering === true && <HoverableDiv url={imageLink} home={false} />}

                    <Header1>
                        <Random>
                            Last Series: {lastValue.slice(-6)}
                        </Random>

                        <Header>
                            {current === "1" ? "Create First Code" : "First Code: " + finalCode}
                        </Header>
                        {current === "1" &&
                            <FinalCode>
                                {finalCode}
                            </FinalCode>
                        }
                    </Header1>
                    <Message>
                        {message}
                    </Message>

                    <Body>
                        {current === "1" &&
                            <>
                                <First>
                                    <Heading>
                                        Select Brand Code
                                    </Heading>
                                    <Dropdown index={1} ph={"Brand Code"}
                                        options={firstCode.filter(function (val) {
                                            return val.title === "brand name"
                                        }).map(function (item) { return item.option })}
                                        onChange={"handleBCChange"}
                                        fn={setBrandCode} />

                                    {brandCode !== "" && lastValue === "" &&
                                        <>
                                            <Heading>
                                                6 Digit Number
                                            </Heading>
                                            <Input type={"number"} index={1} label={"Random Number"} onChange={handleRandomNumber}
                                                name={"setRandomNumber"} />
                                        </>
                                    }

                                    <Heading>
                                        First Code
                                    </Heading>

                                    <Input index={1} label={"First Codes"} onChange={handleFirstCodes}
                                        name={"setFirstCodes"} />

                                </First>
                                <Second>
                                    {(randomNumber.length > 5 || lastValue !== "") && brandCode !== "" &&
                                        <>
                                            <Heading>
                                                Collection type
                                            </Heading>
                                            <Dropdown index={1} ph={"Collection Type"} options={firstCode.filter(function (val) {
                                                return val.title === "collection type"
                                            }).map(function (item) { return item.option })} onChange={"handleChange"}
                                                fn={setCollectionType} />
                                        </>
                                    }

                                    {collectionType !== "" &&
                                        <>
                                            <Heading>
                                                Generic Material
                                            </Heading>
                                            <Dropdown index={1} ph={collectionType} options={firstCode.filter(function (val) {
                                                return val.title === collectionType
                                            }).map(function (item) { return item.option })} onChange={"handleChange"}
                                                name={"collection"} fn={setCollection} />
                                        </>
                                    }
                                </Second>
                                <Third>
                                    {collection !== "" &&
                                        <>
                                            <Heading>
                                                Product Category
                                            </Heading>
                                            <Dropdown index={1} ph={"Product Category"} options={firstCode.filter(function (val) {
                                                return val.title === "product category"
                                            }).map(function (item) { return item.option })} onChange={"handleChange"}
                                                name={"productCategory"} fn={setProductCategory} />
                                        </>
                                    }

                                    {firstCode.filter(function (val) {
                                        return val.title === "product category" && val.option === productCategory
                                    }).map(function (item) { return item.code }) == "00" &&
                                        <>
                                            <Heading>
                                                Product Category 2
                                            </Heading>
                                            <Dropdown index={1} ph={"Product Category"} options={firstCode.filter(function (val) {
                                                return val.title === productCategory
                                            }).map(function (item) { return item.option })} onChange={"handleChange"}
                                                name={"productCategory"} fn={setPC2} />
                                        </>
                                    }
                                </Third>
                                <Fourth>
                                    {productCategory !== "" &&
                                        <Button index={1} name={loading ? "Loading..." : "submit"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} onClick={handleChange} />
                                    }
                                </Fourth>
                            </>
                        }
                        {current === "2" &&
                            <Part>

                                {!showForm && !uploading &&
                                    <>
                                        <Part2>
                                            <ButtonContainer jc={"space-between"}>
                                                <PhotoName>
                                                    {photoName ? "Photo" : photoName}
                                                </PhotoName>
                                                <Uploader>
                                                    <Button1 onClick={handlePhoto}>
                                                        add photo
                                                    </Button1>
                                                    <input type="file"
                                                        name="imagename"
                                                        ref={hiddenFileInput}
                                                        onChange={(e) => handleUpload(e)}
                                                        style={{ display: 'none' }}
                                                        accept="image/*"
                                                        encType="multipart/form-data"
                                                    />
                                                </Uploader>
                                            </ButtonContainer>
                                        </Part2>
                                    </>
                                }

                                {showForm && !uploading &&
                                    <Part>
                                        <Parts>
                                            <Image>
                                                <ImageBox>
                                                    {extension != "" &&
                                                        <img onMouseOver={() => handleHover(true, "uploads/" + finalCode + "/" + finalCode + "." + extension)} onMouseOut={() => handleHover(false, "uploads/" + finalCode + "/" + finalCode + "." + extension)} style={{
                                                            height: "200px", width: "200px", borderRadius: "5px", objectFit: "cover", objectPosition: "center"

                                                        }} src={imageUrl + "uploads/" + finalCode + "/" + finalCode + "." + extension} alt="" />
                                                    }
                                                </ImageBox>
                                            </Image>
                                            <Part1>
                                                <Dropdown index={1} ph={"Category Code"} options={categoryCodes} onChange={"handleSignUpChange"}
                                                    name={"categoryCode"} fn={setCategoryCode} />
                                                <Input label={"Product Name"} onChange={handleManagerForm2}
                                                    name={"productName"} />
                                                <Input type={"number"} label={"Cost Price"} onChange={handleManagerForm2}
                                                    name={"costPrice"} />
                                                <Input type={"number"} label={"Sample Quantity"} onChange={handleManagerForm2}
                                                    name={"sampleQuantity"} />
                                                <Input type={"number"} label={"Stock"} onChange={handleManagerForm2}
                                                    name={"stock"} />

                                                {finalVendorCode === "" ? <>

                                                    <Input label={"Vendor Name"} onChange={handleVendor}
                                                        name={"vendorName"} />

                                                    {suggestions.length > 0 &&
                                                        <DropDownBox>
                                                            {suggestions.map((value, index) => {
                                                                return <DropdownOption key={index} onClick={() => handleFinalVendorCode(value.vendorName)}>
                                                                    {value.vendorName}
                                                                </DropdownOption>
                                                            })
                                                            }
                                                        </DropDownBox>
                                                    }
                                                </>
                                                    :
                                                    <Code>
                                                        <CodeName>
                                                            {finalVendorCode}
                                                        </CodeName>
                                                        <Edit onClick={() => setFinalVendorCode("")}>
                                                            Edit
                                                        </Edit>
                                                    </Code>
                                                }
                                                <Input label={"Title"} onChange={handleManagerForm2}
                                                    name={"title"} />
                                                <Input label={"Description"} onChange={handleManagerForm2}
                                                    name={"description"} />
                                                <Dropdown index={1} ph={"Tags"} options={tags} onChange={"handleSignUpChange"}
                                                    name={"looks"} fn={setTag} />
                                                <Input label={"Weight (in grams)"} onChange={handleManagerForm2}
                                                    name={"productWeight"} />

                                            </Part1>

                                            <Part3>
                                                <Input label={"Length (in cm)"} onChange={handleManagerForm2}
                                                    name={"productLength"} />
                                                <Input label={"Width (in cm)"} onChange={handleManagerForm2}
                                                    name={"productWidth"} />
                                                <Input label={"Height"} onChange={handleManagerForm2}
                                                    name={"productHeight"} />

                                                <Input label={"Additional Color"} onChange={handleManagerForm2}
                                                    name={"additionalColor"} />
                                                <Input label={"Base Metal"} onChange={handleManagerForm2}
                                                    name={"baseMetal"} />
                                                <Input label={"Base Color"} onChange={handleManagerForm2}
                                                    name={"baseColor"} />
                                                <Dropdown index={1} ph={"Looks"} options={looks} onChange={"handleSignUpChange"}
                                                    name={"looks"} fn={setLook} />
                                                <Input label={"Collection"} onChange={handleManagerForm2}
                                                    name={"collections"} />
                                                <Input label={"Product Type"} onChange={handleManagerForm2}
                                                    name={"productType"} />
                                                <Input label={"Sub Type"} onChange={handleManagerForm2}
                                                    name={"subType"} />
                                            </Part3>
                                        </Parts>

                                        <ButtonContainer jc={"center"}>
                                            <Button name={loading ? "loading..." : "submit"} onClick={postForm2} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} />
                                        </ButtonContainer>
                                    </Part>}
                                {uploading && <Loader />}
                            </Part>
                        }

                        {current === "3" &&
                            <Success>
                                First Code : {finalCode} created successfully
                                <Button index={1} name={"close"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} onClick={handleClose} />
                            </Success>
                        }
                    </Body>
                </Left>
                :
                <Left>
                    <Loader />
                </Left>
            }


            <Right>
                <Top>
                    <Header>
                        Delete First Code
                    </Header>
                    <Message1>
                        {message1}
                    </Message1>
                    <Body1>
                        {!loading2 ?
                            <>
                                {deleteCurrent === "1" &&
                                    <>
                                        <Input index={1} label={"First Code"} onChange={handleToDelete}
                                            name={"setfirstCode"} />
                                        <ButtonContainer jc={"center"}>
                                            <Button index={1} name={loading1 ? "Loading..." : "Delete"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} onClick={handleDeleteOption} />
                                        </ButtonContainer>
                                    </>
                                }
                                {deleteCurrent === "2" &&
                                    <>
                                        <Warning>
                                            Delete <span style={{ color: "red" }}>{toDelete}</span>
                                        </Warning>
                                        <Warning>
                                            This process cannot be reversed. Are you sure?
                                        </Warning>
                                        <Warning>
                                            Instead, you can make this inactive. The data will be saved in database
                                        </Warning>
                                        <ButtonContainer jc={"space-between"}>
                                            <Button index={1} name={loading1 ? "Loading..." : "Inactive"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} onClick={handleInactive} />
                                            <Button index={2} name={loading4 ? "Loading..." : "Delete"} bgcolor={"#ffafcc"} color={"var(--font-color)"} onClick={handleDelete} />
                                        </ButtonContainer>
                                        <ButtonContainer jc={"center"}>
                                            <Button index={2} name={"Cancel"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} onClick={handleDeleteClose} />
                                        </ButtonContainer>
                                    </>
                                }
                                {deleteCurrent === "3" &&
                                    <>
                                        <Warning>
                                            {message2}
                                        </Warning>
                                        <ButtonContainer jc={"center"}>
                                            <Button index={2} name={"Close"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} onClick={handleDeleteClose} />
                                        </ButtonContainer>
                                    </>
                                }

                            </>
                            :
                            <div style={{ marginTop: "-20px" }}>
                                <Loader />
                            </div>
                        }
                    </Body1>
                </Top>
                {deleteCurrent === "1" &&
                    <>
                        <Bottom>
                            <ButtonBox onClick={() => handleSync()}>
                                {sync}
                            </ButtonBox>
                        </Bottom>
                        <Bottom>
                            <ButtonBox onClick={() => handleFetchVendor()}>
                                {sync1}
                            </ButtonBox>
                        </Bottom>
                    </>
                }
            </Right>
        </Container >
    )
}

export default CreateSKU