import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { FetchData } from "../Components/Subcomponents/FetchData"
import Loader from "../Components/Subcomponents/Loader";
import { DataContext } from "../Contexts/DataContext";
import { motion } from "framer-motion"
import Input1 from "../Components/Subcomponents/Input1";
import Button from "../Components/Subcomponents/Button";
import Dropdown from "../Components/Subcomponents/Dropdown";
import Input from "../Components/Subcomponents/Input";

const Container = styled(motion.div)`
    width: 100%;
    display: flex;
    font-size: var(--font-s);
    flex-direction: column;
    gap: 10px;
`
const Header = styled.div`
    width: max-content;
    display: flex;
    height: max-content;
    align-items: center;
    justify-content: center;
    background-color: #ffdab9;
    padding: 6px 20px;
    letter-spacing: 1.2px;
    text-transform: caplitalize;
    border-radius: 3px;
`
const Body = styled.div`
    height: calc(100% - 50px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Pane = styled.div`
    height: 100%;
    width: 98%;
    width: ${props => props.width};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.bg};
    box-shadow: ${props => props.bs};
    border-radius: 5px;
`
const Pane1 = styled.div`
    height: 30%;
    width: 98%;
    flex-direction: column;
    border-radius: 5px;
`
const InnerPane = styled.div`
    height: max-content;
    width: 98%;
    width: ${props => props.width};
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: ${props => props.direction};
`

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${props => props.jc};
    width: 100%;
    margin: auto;
    padding-top: 10px;
`

const Message = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    color: red;
    text-transform: capitalize;
    letter-spacing: 1px;
    margin: auto;
    padding: 20px 10px;
`

const Content = styled.div`
    height: 100%;
    width: 98%;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
`

const Inner = styled.div`
    height: 100%;
    width: 200px;
    gap: 20px;
    flex-direction: column;
`

const DropDownBox = styled.div`
    max-height: 120px;
    width: 100%;
    background-color: white;
    overflow-y: scroll;
    position: relative;
    &::-webkit-scrollbar {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-track {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-thumb {
        width: 5px;
        background-color: #D6EAF8;
    }
`
const DropdownOption = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #ddfdfe;
    border-bottom: 1px solid #edede9;
    padding: 12px;
    font-size: var(--font-xs);
    letter-spacing: var(--ls);
    text-transform: uppercase;
    cursor: pointer;
    color: var(--font-color);
`

const Code = styled.div`
    width: 100%;
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    letter-spacing: var(--ls);
`
const CodeName = styled.div`
    font-size: var(--font-xs);
`
const Edits = styled.div`
    background-color: #fcbf49;
    padding: 3px 5px;
    border-radius: 2px;
    box-shadow: var(--box-shadow);
    cursor: pointer;
`
const Drp = styled.div`
    margin: 20px 0px;
`
const Msg = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
`

const Left = styled.div`
    // background-color: white;
    height: 100%;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
    margin: 10px;
`
const Right = styled.div`
    height: 100%;
    width: calc(100% - 200px);
    display: flex;
    align-items: center;
    // justify-content: center;
    gap: 20px;
    margin: 20px;    
`
const LeftInner = styled.div`
    font-size: 12px;
    width: 100%;
    padding: 6px 12px;
    border-radius: 2px;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    font-weight: 500;
    display: flex;
    align-items: flex-start;    
    justify-content: flex-start;
    border: 1.5px solid black;
    cursor: pointer;
    background-color: ${props => props.bg};
    color: ${props => props.color};
`

const Edit = () => {

    const { url, looks, vendor, tags, statuss, categoryCodes } = useContext(DataContext)
    const [firstCode, setFirstCode] = useState("")
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState("")
    const [msg, setMsg] = useState("No data to show")
    const [message, setMessage] = useState("")
    const [show, setShow] = useState(false)
    const [profile, setProfile] = useState("Merchandiser")



    const handleFetch = async () => {

        if (firstCode.length > 0) {
            setLoading(true)
            const response = await FetchData({
                "endpoint": url + "boxview/fetchedit",
                data: {
                    "firstCode": firstCode,
                }
            })

            if (response.error === "no error") {
                setLoading(false)
                setMessage("")
                setData(response.data)
            } else {
                setLoading(false)
                setMessage("Invalid First Code")
            }
        } else {
            setLoading(false)
            setMessage("Enter First Code")
        }

    }


    useEffect(() => {
        if (data.length > 0) {
            setFinalVendorCode(data[0].vendorName)
            setProductName(data[0].productName)
            setCategoryCode(data[0].categoryCode)
            setProductCategory(data[0].productCategory)
            setCostPrice(data[0].costPrice)
            setLook(data[0].look)
            setTag(data[0].tags)
            setSampleQty(data[0].sampleQuantity)
            setStock(data[0].stock)
            setTitle(data[0].title)
            setDesc(data[0].description)
            setBaseMetal(data[0].baseMetal)
            setBaseColor(data[0].baseColor)
            setCollections(data[0].collections)
            setProductType(data[0].productType)
            setSubType(data[0].subType)
            setAddlColor(data[0].additionalColor)
            setProductWeight(data[0].productWeight)
            setProductLength(data[0].productLength)
            setProductWidth(data[0].productWidth)
            setProductHeight(data[0].productHeight)
            setMRP(data[0].mrp)
            setSellingPrice(data[0].sellingPrice)
            setPOQty(data[0].poQty)
            setPricingStatus(data[0].pricingStatus)
            setPONumber(data[0].poNumber)
            setBuyerQty(data[0].buyerPOQuantity)
            setBuyerRemarks(data[0].buyerRemarks)
            setBuyerStatus(data[0].buyerStatus)
            setProductShootPhoto(data[0].productShootPhoto)
            setProductShootUser(data[0].productShootUser)
            setProductShootReEdit(data[0].productShootReEdit)
            setProductShootStatus(data[0].productShootStatus)
            setModelShootPhoto(data[0].modelShootPhoto)
            setModelShootUser(data[0].modelShootUser)
            setModelShootReEdit(data[0].modelShootReEdit)
            setModelShootStatus(data[0].modelShootStatus)
            setMoodShootPhoto(data[0].moodShootPhoto)
            setMoodShootUser(data[0].moodShootUser)
            setMoodShootReEdit(data[0].moodShootReEdit)
            setMoodShootStatus(data[0].moodShootStatus)
            setVideoPhoto(data[0].videoPhoto)
            setVideoUser(data[0].videoUser)
            setVideoReEdit(data[0].videoReEdit)
            setVideoStatus(data[0].videoStatus)
            setProductShootToEditorPhoto(data[0].productShootToEditorPhoto)
            setProductShootToEditorUser(data[0].productShootToEditorUser)
            setProductShootToEditorReEdit(data[0].productShootToEditorReEdit)
            setProductShootToEditorStatus(data[0].productShootToEditorStatus)
            setModelShootToEditorPhoto(data[0].modelShootToEditorPhoto)
            setModelShootToEditorUser(data[0].modelShootToEditorUser)
            setModelShootToEditorReEdit(data[0].modelShootToEditorReEdit)
            setModelShootToEditorStatus(data[0].modelShootToEditorStatus)
            setMoodShootToEditorPhoto(data[0].moodShootToEditorPhoto)
            setMoodShootToEditorUser(data[0].moodShootToEditorUser)
            setMoodShootToEditorReEdit(data[0].moodShootToEditorReEdit)
            setMoodShootToEditorStatus(data[0].moodShootToEditorStatus)
            setVideoToEditorPhoto(data[0].videoToEditorPhoto)
            setVideoToEditorUser(data[0].videoToEditorUser)
            setVideoToEditorReEdit(data[0].videoToEditorReEdit)
            setVideoToEditorStatus(data[0].videoToEditorStatus)
            // setProductShootEditToQCPhoto(data[0].productShootEditToQCPhoto)
            setProductShootEditToQCUser(data[0].productShootEditToQCUser)
            // setProductShootEditToQCReEdit(data[0].productShootEditToQCReEdit)
            setProductShootEditToQCStatus(data[0].productShootEditToQCStatus)
            // setModelShootEditToQCPhoto(data[0].modelShootEditToQCPhoto)
            setModelShootEditToQCUser(data[0].modelShootEditToQCUser)
            // setModelShootEditToQCReEdit(data[0].modelShootEditToQCReEdit)
            setModelShootEditToQCStatus(data[0].modelShootEditToQCStatus)
            // setMoodShootEditToQCPhoto(data[0].moodShootEditToQCPhoto)
            setMoodShootEditToQCUser(data[0].moodShootEditToQCUser)
            // setMoodShootEditToQCReEdit(data[0].moodShootEditToQCReEdit)
            setMoodShootEditToQCStatus(data[0].moodShootEditToQCStatus)
            // setVideoEditToQCPhoto(data[0].videoEditToQCPhoto)
            setVideoEditToQCUser(data[0].videoEditToQCUser)
            // setVideoEditToQCReEdit(data[0].videoEditToQCReEdit)
            setVideoEditToQCStatus(data[0].videoEditToQCStatus)
            setCatalogID(data[0].catalogID)
            setFSNID(data[0].FSNID)
            setComments(data[0].comments)
            setCatalogStatus(data[0].catalogStatus)

        }
    }, [data])




    ////////////////////////////////// Vendor Form /////////////////////////////

    const [suggestions, setSuggestions] = useState([])
    const [finalVendorCode, setFinalVendorCode] = useState("")
    const [vendorTemp, setVendorTemp] = useState(finalVendorCode)

    const handleVendor = (e) => {
        setVendorTemp(e.target.value)
    }

    const handleFinalVendorCode = (e) => {
        setFinalVendorCode(e)
        setVendorTemp(e)
    }

    // useEffect(() => {
    //     console.log("finalVendorCode", finalVendorCode)
    // }, [finalVendorCode])


    // useEffect(() => {
    //     setSuggestions(vendor.filter(vendors => {
    //         return (vendors.vendorName).match(vendorTemp.toLowerCase());
    //     }))

    //     //Clears Data If Search Input Field Is Empty
    //     if (vendorTemp.length === 0) {
    //         setSuggestions([])
    //     }
    // }, [vendorTemp])

    // useEffect(() => {
    //     if (vendor.length > 0) {

    //         setSuggestions(vendor.filter(vendors => {
    //             return vendors.vendorName
    //         }))

    //         //Clears Data If Search Input Field Is Empty
    //         if (vendorTemp.length === 0) {
    //             setSuggestions([])
    //         }
    //     }
    // }, [vendorTemp])

    useEffect(() => {
        if (vendor) {
            if (vendor.length > 0) {
                setSuggestions(vendor.filter(vendors => {
                    return (vendors.vendorName).toLowerCase().match(vendorTemp.toLowerCase());
                }))
            }
            //Clears Data If Search Input Field Is Empty
            if (vendorTemp.length === 0) {
                setSuggestions([])
            }
        }
    }, [vendorTemp])




    //////////////////////////////  Merchandiser Submit ///////////////////////////

    const [productName, setProductName] = useState("")
    const [categoryCode, setCategoryCode] = useState("")
    const [productCategory, setProductCategory] = useState("")
    const [costPrice, setCostPrice] = useState("")
    const [look, setLook] = useState("")
    const [tag, setTag] = useState("")
    const [sampleQuantity, setSampleQty] = useState("")
    const [stock, setStock] = useState("")
    const [title, setTitle] = useState("")
    const [description, setDesc] = useState("")
    const [baseMetal, setBaseMetal] = useState("")
    const [baseColor, setBaseColor] = useState("")
    const [collections, setCollections] = useState("")
    const [productType, setProductType] = useState("")
    const [subType, setSubType] = useState("")
    const [additionalColor, setAddlColor] = useState("")
    const [productWeight, setProductWeight] = useState("")
    const [productLength, setProductLength] = useState("")
    const [productWidth, setProductWidth] = useState("")
    const [productHeight, setProductHeight] = useState("")

    const [buttonLoad, setButtonLoad] = useState(false)

    const handleReload = () => {
        window.location.reload("/edit")
    }


    const mercSubmit = async () => {

        // console.log(finalVendorCode)
        setButtonLoad(true)
        const response = await FetchData({
            "endpoint": url + "edit/merc",
            data: {
                "firstCode": firstCode,
                "productName": productName,
                "categoryCode": categoryCode,
                "productCategory": productCategory,
                "costPrice": costPrice,
                "look": look,
                "tag": tag,
                "sampleQuantity": sampleQuantity,
                "stock": stock,
                "title": title,
                "description": description,
                "baseMetal": baseMetal,
                "baseColor": baseColor,
                "collections": collections,
                "productType": productType,
                "subType": subType,
                "additionalColor": additionalColor,
                "productWeight": productWeight,
                "productLength": productLength,
                "productWidth": productWidth,
                "productHeight": productHeight,
                "vendorName": finalVendorCode,
            }
        })

        if (response.error === "no error") {
            setButtonLoad(false)
            setMessage("Data Changed Successfully")
            setFirstCode("")
            setData("")
            setShow(true)
        } else {
            setMessage(response.message)
            setLoading(false)
        }
    }


    //////////////////////////////  Pricing Submit ///////////////////////////

    const [mrp, setMRP] = useState("")
    const [sellingPrice, setSellingPrice] = useState("")
    const [poQty, setPOQty] = useState("")
    const [pricingStatus, setPricingStatus] = useState("")


    const pricingSubmit = async () => {

        // console.log(finalVendorCode)
        setButtonLoad(true)
        const response = await FetchData({
            "endpoint": url + "edit/pricing",
            data: {
                "firstCode": firstCode,
                "mrp": mrp,
                "sellingPrice": sellingPrice,
                "poQty": poQty,
                "pricingStatus": pricingStatus,
            }
        })

        if (response.error === "no error") {
            setButtonLoad(false)
            setMessage("Data Changed Successfully")
            setFirstCode("")
            setData("")
            setShow(true)
        } else {
            setMessage(response.message)
            setLoading(false)
        }
    }




    //////////////////////////////  Buyer Submit ///////////////////////////

    const [poNumber, setPONumber] = useState("")
    const [buyerPOQuantity, setBuyerQty] = useState("")
    const [buyerRemarks, setBuyerRemarks] = useState("")
    const [buyerStatus, setBuyerStatus] = useState("")


    const buyerSubmit = async () => {

        // console.log(finalVendorCode)
        setButtonLoad(true)
        const response = await FetchData({
            "endpoint": url + "edit/buyer",
            data: {
                "firstCode": firstCode,
                "poNumber": poNumber,
                "buyerPOQuantity": buyerPOQuantity,
                "buyerRemarks": buyerRemarks,
                "buyerStatus": buyerStatus,
            }
        })

        if (response.error === "no error") {
            setButtonLoad(false)
            setMessage("Data Changed Successfully")
            setFirstCode("")
            setData("")
            setShow(true)
        } else {
            setMessage(response.message)
            setLoading(false)
        }
    }



    //////////////////////////////  Shoot Submit ///////////////////////////

    const [productShootPhoto, setProductShootPhoto] = useState("")
    const [productShootUser, setProductShootUser] = useState("")
    const [productShootReEdit, setProductShootReEdit] = useState("")
    const [productShootStatus, setProductShootStatus] = useState("")

    const [modelShootPhoto, setModelShootPhoto] = useState("")
    const [modelShootUser, setModelShootUser] = useState("")
    const [modelShootReEdit, setModelShootReEdit] = useState("")
    const [modelShootStatus, setModelShootStatus] = useState("")

    const [moodShootPhoto, setMoodShootPhoto] = useState("")
    const [moodShootUser, setMoodShootUser] = useState("")
    const [moodShootReEdit, setMoodShootReEdit] = useState("")
    const [moodShootStatus, setMoodShootStatus] = useState("")

    const [videoPhoto, setVideoPhoto] = useState("")
    const [videoUser, setVideoUser] = useState("")
    const [videoReEdit, setVideoReEdit] = useState("")
    const [videoStatus, setVideoStatus] = useState("")


    const shootSubmit = async () => {

        // console.log(finalVendorCode)
        setButtonLoad(true)
        const response = await FetchData({
            "endpoint": url + "edit/shoot",
            data: {
                "firstCode": firstCode,
                "productShootPhoto": productShootPhoto,
                "productShootUser": productShootUser,
                "productShootReEdit": productShootReEdit,
                "productShootStatus": productShootStatus,

                "modelShootPhoto": modelShootPhoto,
                "modelShootUser": modelShootUser,
                "modelShootReEdit": modelShootReEdit,
                "modelShootStatus": modelShootStatus,

                "moodShootPhoto": moodShootPhoto,
                "moodShootUser": moodShootUser,
                "moodShootReEdit": moodShootReEdit,
                "moodShootStatus": moodShootStatus,

                "videoPhoto": videoPhoto,
                "videoUser": videoUser,
                "videoReEdit": videoReEdit,
                "videoStatus": videoStatus,
            }
        })

        if (response.error === "no error") {
            setButtonLoad(false)
            setMessage("Data Changed Successfully")
            setFirstCode("")
            setData("")
            setShow(true)
        } else {
            setMessage(response.message)
            setLoading(false)
        }
    }



    //////////////////////////////  Editor Submit ///////////////////////////

    const [productShootToEditorPhoto, setProductShootToEditorPhoto] = useState("")
    const [productShootToEditorUser, setProductShootToEditorUser] = useState("")
    const [productShootToEditorReEdit, setProductShootToEditorReEdit] = useState("")
    const [productShootToEditorStatus, setProductShootToEditorStatus] = useState("")

    const [modelShootToEditorPhoto, setModelShootToEditorPhoto] = useState("")
    const [modelShootToEditorUser, setModelShootToEditorUser] = useState("")
    const [modelShootToEditorReEdit, setModelShootToEditorReEdit] = useState("")
    const [modelShootToEditorStatus, setModelShootToEditorStatus] = useState("")

    const [moodShootToEditorPhoto, setMoodShootToEditorPhoto] = useState("")
    const [moodShootToEditorUser, setMoodShootToEditorUser] = useState("")
    const [moodShootToEditorReEdit, setMoodShootToEditorReEdit] = useState("")
    const [moodShootToEditorStatus, setMoodShootToEditorStatus] = useState("")

    const [videoToEditorPhoto, setVideoToEditorPhoto] = useState("")
    const [videoToEditorUser, setVideoToEditorUser] = useState("")
    const [videoToEditorReEdit, setVideoToEditorReEdit] = useState("")
    const [videoToEditorStatus, setVideoToEditorStatus] = useState("")


    const editorSubmit = async () => {

        // console.log(finalVendorCode)
        setButtonLoad(true)
        const response = await FetchData({
            "endpoint": url + "edit/editor",
            data: {
                "firstCode": firstCode,
                "productShootToEditorPhoto": productShootToEditorPhoto,
                "productShootToEditorUser": productShootToEditorUser,
                "productShootToEditorReEdit": productShootToEditorReEdit,
                "productShootToEditorStatus": productShootToEditorStatus,

                "modelShootToEditorPhoto": modelShootToEditorPhoto,
                "modelShootToEditorUser": modelShootToEditorUser,
                "modelShootToEditorReEdit": modelShootToEditorReEdit,
                "modelShootToEditorStatus": modelShootToEditorStatus,

                "moodShootToEditorPhoto": moodShootToEditorPhoto,
                "moodShootToEditorUser": moodShootToEditorUser,
                "moodShootToEditorReEdit": moodShootToEditorReEdit,
                "moodShootToEditorStatus": moodShootToEditorStatus,

                "videoToEditorPhoto": videoToEditorPhoto,
                "videoToEditorUser": videoToEditorUser,
                "videoToEditorReEdit": videoToEditorReEdit,
                "videoToEditorStatus": videoToEditorStatus,
            }
        })

        if (response.error === "no error") {
            setButtonLoad(false)
            setMessage("Data Changed Successfully")
            setFirstCode("")
            setData("")
            setShow(true)
        } else {
            setMessage(response.message)
            setLoading(false)
        }
    }




    //////////////////////////////  QC Submit ///////////////////////////

    // const [productShootEditToQCPhoto, setProductShootEditToQCPhoto] = useState("")
    const [productShootEditToQCUser, setProductShootEditToQCUser] = useState("")
    // const [productShootEditToQCReEdit, setProductShootEditToQCReEdit] = useState("")
    const [productShootEditToQCStatus, setProductShootEditToQCStatus] = useState("")

    // const [modelShootEditToQCPhoto, setModelShootEditToQCPhoto] = useState("")
    const [modelShootEditToQCUser, setModelShootEditToQCUser] = useState("")
    // const [modelShootEditToQCReEdit, setModelShootEditToQCReEdit] = useState("")
    const [modelShootEditToQCStatus, setModelShootEditToQCStatus] = useState("")

    // const [moodShootEditToQCPhoto, setMoodShootEditToQCPhoto] = useState("")
    const [moodShootEditToQCUser, setMoodShootEditToQCUser] = useState("")
    // const [moodShootEditToQCReEdit, setMoodShootEditToQCReEdit] = useState("")
    const [moodShootEditToQCStatus, setMoodShootEditToQCStatus] = useState("")

    // const [videoEditToQCPhoto, setVideoEditToQCPhoto] = useState("")
    const [videoEditToQCUser, setVideoEditToQCUser] = useState("")
    // const [videoEditToQCReEdit, setVideoEditToQCReEdit] = useState("")
    const [videoEditToQCStatus, setVideoEditToQCStatus] = useState("")


    const qcSubmit = async () => {

        // console.log(finalVendorCode)
        setButtonLoad(true)
        const response = await FetchData({
            "endpoint": url + "edit/qc",
            data: {
                "firstCode": firstCode,
                // "productShootEditToQCPhoto": productShootEditToQCPhoto,
                "productShootEditToQCUser": productShootEditToQCUser,
                // "productShootEditToQCReEdit": productShootEditToQCReEdit,
                "productShootEditToQCStatus": productShootEditToQCStatus,

                // "modelShootEditToQCPhoto": modelShootEditToQCPhoto,
                "modelShootEditToQCUser": modelShootEditToQCUser,
                // "modelShootEditToQCReEdit": modelShootEditToQCReEdit,
                "modelShootEditToQCStatus": modelShootEditToQCStatus,

                // "moodShootEditToQCPhoto": moodShootEditToQCPhoto,
                "moodShootEditToQCUser": moodShootEditToQCUser,
                // "moodShootEditToQCReEdit": moodShootEditToQCReEdit,
                "moodShootEditToQCStatus": moodShootEditToQCStatus,

                // "videoEditToQCPhoto": videoEditToQCPhoto,
                "videoEditToQCUser": videoEditToQCUser,
                // "videoEditToQCReEdit": videoEditToQCReEdit,
                "videoEditToQCStatus": videoEditToQCStatus,
            }
        })

        if (response.error === "no error") {
            setButtonLoad(false)
            setMessage("Data Changed Successfully")
            setFirstCode("")
            setData("")
            setShow(true)
        } else {
            setMessage(response.message)
            setLoading(false)
        }
    }




    //////////////////////////////  Catalog Submit ///////////////////////////

    const [catalogID, setCatalogID] = useState("")
    const [fsnID, setFSNID] = useState("")
    const [comments, setComments] = useState("")
    const [catalogStatus, setCatalogStatus] = useState("")


    const catalogSubmit = async () => {

        // console.log(finalVendorCode)
        setButtonLoad(true)
        const response = await FetchData({
            "endpoint": url + "edit/catalog",
            data: {
                "firstCode": firstCode,
                "catalogID": catalogID,
                "fsnID": fsnID,
                "comments": comments,
                "catalogStatus": catalogStatus,
            }
        })

        if (response.error === "no error") {
            setButtonLoad(false)
            setMessage("Data Changed Successfully")
            setFirstCode("")
            setData("")
            setShow(true)
        } else {
            setMessage(response.message)
            setLoading(false)
        }
    }





    return (
        <Container
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
                ease: "easeInOut",
                duration: 0.5
            }}
        >
            <Header>
                Edit SKU
            </Header>
            <Body>
                {!show ?
                    <>
                        <Pane width={"20%"}>
                            <InnerPane direction={"column"}>
                                <Message>
                                    {message}
                                </Message>
                                <Input1 index={1} label={"First Code"} onChange={(e) => setFirstCode(e.target.value)}
                                    name={"setfirstCode"} />
                                <ButtonContainer jc={"center"}>
                                    <Button index={1} name={loading ? "Loading..." : "submit"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} onClick={handleFetch} />
                                </ButtonContainer>
                            </InnerPane>
                        </Pane>


                        <Pane width={"80%"} bg={"#d0f4de"} bs={"var(--box-shadow)"}>
                            {data.length > 0 ?
                                <Content>
                                    <Left>
                                        <LeftInner onClick={() => setProfile("Merchandiser")} bg={profile === "Merchandiser" ? "#2b2b2b" : ""} color={profile === "Merchandiser" ? "white" : ""}>
                                            Merchandiser
                                        </LeftInner>
                                        <LeftInner onClick={() => setProfile("Pricing")} bg={profile === "Pricing" ? "#2b2b2b" : ""} color={profile === "Pricing" ? "white" : ""}>
                                            Pricing
                                        </LeftInner>
                                        <LeftInner onClick={() => setProfile("Buyer")} bg={profile === "Buyer" ? "#2b2b2b" : ""} color={profile === "Buyer" ? "white" : ""}>
                                            Buyer
                                        </LeftInner>
                                        <LeftInner onClick={() => setProfile("Shoot")} bg={profile === "Shoot" ? "#2b2b2b" : ""} color={profile === "Shoot" ? "white" : ""}>
                                            Shoot
                                        </LeftInner>
                                        <LeftInner onClick={() => setProfile("Editor")} bg={profile === "Editor" ? "#2b2b2b" : ""} color={profile === "Editor" ? "white" : ""}>
                                            Editor
                                        </LeftInner>
                                        <LeftInner onClick={() => setProfile("QC")} bg={profile === "QC" ? "#2b2b2b" : ""} color={profile === "QC" ? "white" : ""}>
                                            QC
                                        </LeftInner>
                                        <LeftInner onClick={() => setProfile("Catalog")} bg={profile === "Catalog" ? "#2b2b2b" : ""} color={profile === "Catalog" ? "white" : ""}>
                                            Catalog
                                        </LeftInner>
                                    </Left>

                                    {profile === "Merchandiser" &&
                                        <Right>
                                            <Inner>
                                                <Input1 label={"Product Name"} value={data[0].productName} onChange={(e) => setProductName(e.target.value)} />
                                                <Dropdown index={1} value={data[0].categoryCode} ph={data[0].categoryCode} options={categoryCodes} onChange={"handleSignUpChange"}
                                                    name={"tags"} fn={setCategoryCode} />
                                                <Input1 label={"Product Category"} value={data[0].productCategory} onChange={(e) => setProductCategory(e.target.value)} />
                                                <Input1 type={"number"} value={data[0].costPrice} label={"Cost Price"} onChange={(e) => setCostPrice(e.target.value)} />
                                                <Input1 type={"number"} value={data[0].sampleQuantity} label={"Sample Quantity"} onChange={(e) => setSampleQty(e.target.value)} />
                                                <Input1 type={"number"} value={data[0].stock} label={"Stock"} onChange={(e) => setStock(e.target.value)} />
                                                <Input1 label={"Title"} value={data[0].title} onChange={(e) => setTitle(e.target.value)} />
                                                <Input1 label={"Description"} value={data[0].description} onChange={(e) => setDesc(e.target.value)} />
                                                <Dropdown index={1} value={data[0].tag} ph={data[0].tag} options={tags} onChange={"handleSignUpChange"}
                                                    name={"tags"} fn={setTag} />
                                            </Inner>

                                            <Inner>
                                                <Input1 label={"Base Metal"} value={data[0].baseMetal} onChange={(e) => setBaseMetal(e.target.value)} />
                                                <Input1 label={"Base Color"} value={data[0].baseColor} onChange={(e) => setBaseColor(e.target.value)} />
                                                <Input1 label={"Product Type"} value={data[0].productType} onChange={(e) => setProductType(e.target.value)} />
                                                <Input1 label={"Sub Type"} value={data[0].subType} onChange={(e) => setSubType(e.target.value)} />
                                                <Input1 label={"Additional Color"} value={data[0].additionalColor} onChange={(e) => setAddlColor(e.target.value)} />
                                                <Input1 label={"Product Weight"} value={data[0].productWeight} onChange={(e) => setProductWeight(e.target.value)} />
                                                <Input1 label={"Product Length"} value={data[0].productLength} onChange={(e) => setProductLength(e.target.value)} />
                                                <Input1 label={"Product Width"} value={data[0].productWidth} onChange={(e) => setProductWidth(e.target.value)} />
                                                <Input1 label={"Product Height"} value={data[0].productHeight} onChange={(e) => setProductHeight(e.target.value)} />
                                            </Inner>

                                            <Inner>

                                                <Input1 label={"Collections"} value={data[0].collections} onChange={(e) => setCollections(e.target.value)} />
                                                <Dropdown index={1} value={data[0].look} ph={data[0].look} options={looks} onChange={"handleSignUpChange"}
                                                    name={"looks"} fn={setLook} />

                                                <Drp>
                                                    {finalVendorCode === "" ? <>

                                                        <Input label={"Vendor Name"} value={data[0].vendorName} onChange={handleVendor}
                                                            name={"vendorName"} />

                                                        {suggestions.length > 0 &&
                                                            <DropDownBox>
                                                                {suggestions.map((value, index) => {
                                                                    return <DropdownOption key={index} onClick={() => handleFinalVendorCode(value.vendorName)}>
                                                                        {value.vendorName}
                                                                    </DropdownOption>
                                                                })
                                                                }
                                                            </DropDownBox>
                                                        }
                                                    </>
                                                        :
                                                        <Code>
                                                            <CodeName>
                                                                {finalVendorCode}
                                                            </CodeName>
                                                            <Edits onClick={() => setFinalVendorCode("")}>
                                                                Edit
                                                            </Edits>
                                                        </Code>
                                                    }
                                                </Drp>
                                                <ButtonContainer jc={"center"}>
                                                    <Button index={1} name={buttonLoad ? "Loading..." : "submit"} bgcolor={"#2b2b2b"} color={"var(--main-color)"} onClick={mercSubmit} />
                                                </ButtonContainer>

                                            </Inner>
                                        </Right>
                                    }



                                    {profile === "Pricing" &&
                                        <Right>
                                            <Inner>
                                                <Input1 label={"MRP"} value={data[0].mrp} onChange={(e) => setMRP(e.target.value)} />
                                                <Input1 label={"Selling Price"} value={data[0].sellingPrice} onChange={(e) => setSellingPrice(e.target.value)} />
                                                <Input1 label={"PO Quantity"} value={data[0].poQty} onChange={(e) => setPOQty(e.target.value)} />
                                                <Dropdown index={1} value={data[0].pricingStatus} ph={data[0].pricingStatus} options={statuss} onChange={"handleSignUpChange"}
                                                    name={"status"} fn={setPricingStatus} />
                                                <ButtonContainer jc={"center"}>
                                                    <Button index={1} name={buttonLoad ? "Loading..." : "submit"} bgcolor={"#2b2b2b"} color={"var(--main-color)"} onClick={pricingSubmit} />
                                                </ButtonContainer>

                                            </Inner>
                                        </Right>
                                    }


                                    {profile === "Buyer" &&
                                        <Right>
                                            <Inner>
                                                <Input1 type={"PO Number"} value={data[0].poNumber} label={"PO Number"} onChange={(e) => setPONumber(e.target.value)} />
                                                <Input1 label={"Buyer Quantity"} value={data[0].buyerPOQuantity} onChange={(e) => setBuyerQty(e.target.value)} />
                                                <Input1 type={"Buyer Remarks"} value={data[0].buyerRemarks} label={"Buyer Remarks"} onChange={(e) => setBuyerRemarks(e.target.value)} />
                                                <Dropdown index={1} value={data[0].buyerStatus} ph={data[0].buyerStatus} options={statuss} onChange={"handleSignUpChange"}
                                                    name={"status"} fn={setBuyerStatus} />
                                                <ButtonContainer jc={"center"}>
                                                    <Button index={1} name={buttonLoad ? "Loading..." : "submit"} bgcolor={"#2b2b2b"} color={"var(--main-color)"} onClick={buyerSubmit} />
                                                </ButtonContainer>

                                            </Inner>
                                        </Right>
                                    }


                                    {profile === "Shoot" &&
                                        <Right>
                                            <Inner>
                                                <Input1 label={"Product Photo"} value={data[0].productShootPhoto} onChange={(e) => setProductShootPhoto(e.target.value)} />
                                                <Input1 label={"Product User"} value={data[0].productShootUser} onChange={(e) => setProductShootUser(e.target.value)} />
                                                <Input1 label={"Re-Edit Comments"} value={data[0].productShootReEdit} onChange={(e) => setProductShootReEdit(e.target.value)} />
                                                <Dropdown index={1} value={data[0].productShootStatus} ph={data[0].productShootStatus} options={statuss} onChange={"handleSignUpChange"}
                                                    name={"status"} fn={setProductShootStatus} />
                                            </Inner>

                                            <Inner>
                                                <Input1 label={"Model Photo"} value={data[0].modelShootPhoto} onChange={(e) => setModelShootPhoto(e.target.value)} />
                                                <Input1 label={"Model User"} value={data[0].modelShootUser} onChange={(e) => setModelShootUser(e.target.value)} />
                                                <Input1 label={"Re-Edit Comments"} value={data[0].modelShootReEdit} onChange={(e) => setModelShootReEdit(e.target.value)} />
                                                <Dropdown index={1} value={data[0].modelShootStatus} ph={data[0].modelShootStatus} options={statuss} onChange={"handleSignUpChange"}
                                                    name={"status"} fn={setModelShootStatus} />
                                            </Inner>

                                            <Inner>
                                                <Input1 label={"Mood Photo"} value={data[0].moodShootPhoto} onChange={(e) => setMoodShootPhoto(e.target.value)} />
                                                <Input1 label={"Mood User"} value={data[0].moodShootUser} onChange={(e) => setMoodShootUser(e.target.value)} />
                                                <Input1 label={"Re-Edit Comments"} value={data[0].moodShootReEdit} onChange={(e) => setMoodShootReEdit(e.target.value)} />
                                                <Dropdown index={1} value={data[0].moodShootStatus} ph={data[0].moodShootStatus} options={statuss} onChange={"handleSignUpChange"}
                                                    name={"status"} fn={setMoodShootStatus} />
                                            </Inner>

                                            <Inner>
                                                <Input1 label={"Video Link"} value={data[0].videoPhoto} onChange={(e) => setVideoPhoto(e.target.value)} />
                                                <Input1 label={"Model User"} value={data[0].videoUser} onChange={(e) => setVideoUser(e.target.value)} />
                                                <Input1 label={"Re-Edit Comments"} value={data[0].videoReEdit} onChange={(e) => setVideoReEdit(e.target.value)} />
                                                <Dropdown index={1} value={data[0].videoStatus} ph={data[0].videoStatus} options={statuss} onChange={"handleSignUpChange"}
                                                    name={"status"} fn={setVideoStatus} />
                                                <ButtonContainer jc={"center"}>
                                                    <Button index={1} name={buttonLoad ? "Loading..." : "submit"} bgcolor={"#2b2b2b"} color={"var(--main-color)"} onClick={shootSubmit} />
                                                </ButtonContainer>
                                            </Inner>

                                        </Right>
                                    }




                                    {profile === "Editor" &&
                                        <Right>
                                            <Inner>
                                                <Input1 label={"Product Photo"} value={data[0].productShootToEditorPhoto} onChange={(e) => setProductShootToEditorPhoto(e.target.value)} />
                                                <Input1 label={"Product User"} value={data[0].productShootToEditorUser} onChange={(e) => setProductShootToEditorUser(e.target.value)} />
                                                <Input1 label={"Re-Edit Comments"} value={data[0].productShootToEditorReEdit} onChange={(e) => setProductShootToEditorReEdit(e.target.value)} />
                                                <Dropdown index={1} value={data[0].productShootToEditorStatus} ph={data[0].productShootToEditorStatus} options={statuss} onChange={"handleSignUpChange"}
                                                    name={"status"} fn={setProductShootToEditorStatus} />
                                            </Inner>

                                            <Inner>
                                                <Input1 label={"Model Photo"} value={data[0].modelShootToEditorPhoto} onChange={(e) => setModelShootToEditorPhoto(e.target.value)} />
                                                <Input1 label={"Model User"} value={data[0].modelShootToEditorUser} onChange={(e) => setModelShootToEditorUser(e.target.value)} />
                                                <Input1 label={"Re-Edit Comments"} value={data[0].modelShootToEditorReEdit} onChange={(e) => setModelShootToEditorReEdit(e.target.value)} />
                                                <Dropdown index={1} value={data[0].modelShootToEditorStatus} ph={data[0].modelShootToEditorStatus} options={statuss} onChange={"handleSignUpChange"}
                                                    name={"status"} fn={setModelShootToEditorStatus} />
                                            </Inner>

                                            <Inner>
                                                <Input1 label={"Mood Photo"} value={data[0].moodShootToEditorPhoto} onChange={(e) => setMoodShootToEditorPhoto(e.target.value)} />
                                                <Input1 label={"Mood User"} value={data[0].moodShootToEditorUser} onChange={(e) => setMoodShootToEditorUser(e.target.value)} />
                                                <Input1 label={"Re-Edit Comments"} value={data[0].moodShootToEditorReEdit} onChange={(e) => setMoodShootToEditorReEdit(e.target.value)} />
                                                <Dropdown index={1} value={data[0].moodShootToEditorStatus} ph={data[0].moodShootToEditorStatus} options={statuss} onChange={"handleSignUpChange"}
                                                    name={"status"} fn={setMoodShootToEditorStatus} />
                                            </Inner>

                                            <Inner>
                                                <Input1 label={"Video Link"} value={data[0].videoToEditorPhoto} onChange={(e) => setVideoToEditorPhoto(e.target.value)} />
                                                <Input1 label={"Model User"} value={data[0].videoToEditorUser} onChange={(e) => setVideoToEditorUser(e.target.value)} />
                                                <Input1 label={"Re-Edit Comments"} value={data[0].videoToEditorReEdit} onChange={(e) => setVideoToEditorReEdit(e.target.value)} />
                                                <Dropdown index={1} value={data[0].videoToEditorStatus} ph={data[0].videoToEditorStatus} options={statuss} onChange={"handleSignUpChange"}
                                                    name={"status"} fn={setVideoToEditorStatus} />

                                                <ButtonContainer jc={"center"}>
                                                    <Button index={1} name={buttonLoad ? "Loading..." : "submit"} bgcolor={"#2b2b2b"} color={"var(--main-color)"} onClick={editorSubmit} />
                                                </ButtonContainer>

                                            </Inner>
                                        </Right>
                                    }




                                    {profile === "QC" &&
                                        <Right>
                                            <Inner>
                                                {/* <Input1 label={"Product Photo"} value={data[0].productShootEditToQCPhoto} onChange={(e) => setProductShootEditToQCPhoto(e.target.value)} /> */}
                                                <Input1 label={"Product User"} value={data[0].productShootEditToQCUser} onChange={(e) => setProductShootEditToQCUser(e.target.value)} />
                                                {/* <Input1 label={"Re-Edit Comments"} value={data[0].productShootEditToQCReEdit} onChange={(e) => setProductShootEditToQCReEdit(e.target.value)} /> */}
                                                <Dropdown index={1} value={data[0].productShootEditToQCStatus} ph={data[0].productShootEditToQCStatus} options={statuss} onChange={"handleSignUpChange"}
                                                    name={"status"} fn={setProductShootEditToQCStatus} />
                                            </Inner>

                                            <Inner>
                                                {/* <Input1 label={"Model Photo"} value={data[0].modelShootEditToQCPhoto} onChange={(e) => setModelShootEditToQCPhoto(e.target.value)} /> */}
                                                <Input1 label={"Model User"} value={data[0].modelShootEditToQCUser} onChange={(e) => setModelShootEditToQCUser(e.target.value)} />
                                                {/* <Input1 label={"Re-Edit Comments"} value={data[0].modelShootEditToQCReEdit} onChange={(e) => setModelShootEditToQCReEdit(e.target.value)} /> */}
                                                <Dropdown index={1} value={data[0].modelShootEditToQCStatus} ph={data[0].modelShootEditToQCStatus} options={statuss} onChange={"handleSignUpChange"}
                                                    name={"status"} fn={setModelShootEditToQCStatus} />
                                            </Inner>

                                            <Inner>
                                                {/* <Input1 label={"Mood Photo"} value={data[0].moodShootEditToQCPhoto} onChange={(e) => setMoodShootEditToQCPhoto(e.target.value)} /> */}
                                                <Input1 label={"Mood User"} value={data[0].moodShootEditToQCUser} onChange={(e) => setMoodShootEditToQCUser(e.target.value)} />
                                                {/* <Input1 label={"Re-Edit Comments"} value={data[0].moodShootEditToQCReEdit} onChange={(e) => setMoodShootEditToQCReEdit(e.target.value)} /> */}
                                                <Dropdown index={1} value={data[0].moodShootEditToQCStatus} ph={data[0].moodShootEditToQCStatus} options={statuss} onChange={"handleSignUpChange"}
                                                    name={"status"} fn={setMoodShootEditToQCStatus} />
                                            </Inner>

                                            <Inner>
                                                {/* <Input1 label={"Video Link"} value={data[0].videoEditToQCPhoto} onChange={(e) => setVideoEditToQCPhoto(e.target.value)} /> */}
                                                <Input1 label={"Model User"} value={data[0].videoEditToQCUser} onChange={(e) => setVideoEditToQCUser(e.target.value)} />
                                                {/* <Input1 label={"Re-Edit Comments"} value={data[0].videoEditToQCReEdit} onChange={(e) => setVideoEditToQCReEdit(e.target.value)} /> */}
                                                <Dropdown index={1} value={data[0].videoEditToQCStatus} ph={data[0].videoEditToQCStatus} options={statuss} onChange={"handleSignUpChange"}
                                                    name={"status"} fn={setVideoEditToQCStatus} />

                                                <ButtonContainer jc={"center"}>
                                                    <Button index={1} name={buttonLoad ? "Loading..." : "submit"} bgcolor={"#2b2b2b"} color={"var(--main-color)"} onClick={qcSubmit} />
                                                </ButtonContainer>

                                            </Inner>
                                        </Right>
                                    }





                                    {profile === "Catalog" &&
                                        <Right>
                                            <Inner>
                                                <Input1 label={"Catalog ID"} value={data[0].catalogID} onChange={(e) => setCatalogID(e.target.value)} />
                                                <Input1 label={"FSNID"} value={data[0].FSNID} onChange={(e) => setFSNID(e.target.value)} />
                                                <Input1 label={"comments"} value={data[0].comments} onChange={(e) => setComments(e.target.value)} />
                                                <Dropdown index={1} value={data[0].catalogStatus} ph={data[0].catalogStatus} options={statuss} onChange={"handleSignUpChange"}
                                                    name={"status"} fn={setCatalogStatus} />

                                                <ButtonContainer jc={"center"}>
                                                    <Button index={1} name={buttonLoad ? "Loading..." : "submit"} bgcolor={"#2b2b2b"} color={"var(--main-color)"} onClick={catalogSubmit} />
                                                </ButtonContainer>

                                            </Inner>
                                        </Right>
                                    }

                                </Content>
                                :
                                <Content>
                                    <Msg>
                                        {msg}
                                    </Msg>
                                </Content>
                            }
                        </Pane>
                    </>
                    :
                    <Pane1>
                        <Msg>
                            Data Changed successfully
                        </Msg>
                        <ButtonContainer jc={"center"}>
                            <Button index={1} name={"close"} bgcolor={"#2b2b2b"} color={"var(--main-color)"} onClick={handleReload} />
                        </ButtonContainer>

                    </Pane1>
                }
            </Body>
        </Container >
    )
}

export default Edit