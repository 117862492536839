import styled from "styled-components"
import { motion } from "framer-motion"
import { useContext, useEffect, useRef } from "react"
import { DataContext } from "../Contexts/DataContext"
import { useState } from "react"
import { CheckFirstCodeForm, FetchData } from "../Components/Subcomponents/FetchData"
import Loader from "../Components/Subcomponents/Loader"
import Input from "../Components/Subcomponents/Input"
import Dropdown from "../Components/Subcomponents/Dropdown"
import Button from "../Components/Subcomponents/Button"


const Container = styled(motion.div)`
    width: 100%;
    min-height: 100%;
    max-height: 85vh;
    display: flex;
`
const Body = styled(motion.div)`
    width: 100%;
    min-height: 100%;
    display: flex;
    gap: 10px;
`
const Left = styled(motion.div)`
    width: 33%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f8edeb;
    box-shadow: var(--box-shadow);
    border-radius: 10px;
`
const Center = styled(motion.div)`
    width: 33%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgba(202,240,248, 0.5);
    box-shadow: var(--box-shadow);
    border-radius: 10px;
`
const Right = styled(motion.div)`
    width: 33%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

const Message = styled.div`
    height: 100%;
    width: 100%;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: var(--ls);
    font-size: var(--font-s);
    color: grey;
`

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    margin: auto;
    margin-top: 20px;
    width: max-content;
    background-color: #ffc8dd;
    padding: 6px 20px;
    font-size: var(--font-s);
    letter-spacing: 1px;
    text-transform: caplitalize;
    border-radius: 3px;
    margin-bottom: 10px;
`

const InnerBody = styled.div`
    height: calc(100% - 35px);
    width: 100%;
    display: flex;
    padding: 10px;
    font-size: var(--font-s);
    letter-spacing: 1px;
    text-transform: caplitalize;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    overflow-y: scroll;
    font-size: var(--fsm);

    &::-webkit-scrollbar {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-track {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-thumb {
        width: 5px;
        background-color: #ffbe0b;
    }

`
const InnerBody1 = styled.div`
    height: calc(100% - 35px);
    width: 100%;
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
    font-size: var(--font-s);
    letter-spacing: 1px;
    text-transform: caplitalize;
    flex-direction: column;
`
const VendorName = styled.div`
    width: 70%;
    padding-top: 10px;
`

const Balance = styled.div`
    width: 70%;
    padding: 20px 5px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`

const DropDownBox = styled.div`
    max-height: 120px;
    width: 100%;
    background-color: white;
    overflow-y: scroll;
    position: relative;
    &::-webkit-scrollbar {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-track {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-thumb {
        width: 5px;
        background-color: #D6EAF8;
    }
`
const DropdownOption = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #ddfdfe;
    border-bottom: 1px solid #edede9;
    padding: 12px;
    font-size: var(--font-xs);
    letter-spacing: var(--ls);
    text-transform: uppercase;
    cursor: pointer;
    color: var(--font-color);
`

const Code = styled.div`
    width: 100%;
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    letter-spacing: var(--ls);
`
const CodeName = styled.div`
    font-size: var(--font-xs);
`
const Edit = styled.div`
    background-color: #fcbf49;
    padding: 3px 5px;
    border-radius: 2px;
    box-shadow: var(--box-shadow);
    cursor: pointer;
`
const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${props => props.jc};
    width: 100%;
    margin: auto;
    padding-top: 10px;
`

const Error = styled.div`
    color: red;
    font-size: var(--font-s);
    padding: 10px 20px;
    letter-spacing: var(--ls);
`

const InnerContainer = styled.div`
    min-height: 100px;
    width: 80%;
    margin-top: 10px;
    background-color: #f8f9fa;
    border-radius: 5px;
    box-shadow: var(--box-shadow);
    gap: 5px;
    align-items: space-between;
    cursor: pointer;
`
const One = styled.div`
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 2px 5px;
    font-size: var(--font-m);
    text-transform: capitalize;
    color: #3a0ca3;
`
const Two = styled.div`
    height: 25px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 2px 5px;
    font-size: 11px;
`
const Three = styled.div`
    height: 25px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 2px 5px;
    font-size: 11px;
`

const InnerBox = styled.div`
    margin-top: 20px;
    width: 80%;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(244,162,97);
`
const Content = styled.div`
    display: flex;
    height: 30px;
    width: 100%;
    font-size: var(--font-s);
`
const ContentLeft = styled.div`
    display: flex;
    height: 30px;
    width: 40%;
    align-items: center;
    padding-left: 10px;
    background-color: rgba(244,162,97,0.5);
    border: 1px solid rgba(244,162,97);
`
const ContentRight = styled.div`
    display: flex;
    height: 30px;
    width: 60%;
    align-items: center;
    justify-content: center;
    padding: 0px 2px;
    border: 1px solid rgba(244,162,97);
    background-color: ${props => props.color}
`

const SKUList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`
const SKUBox = styled.div`
    display: flex;
    gap: 5px;
    text-transform: uppercase;
    margin-top: 10px;
`
const SKU = styled.div`
    font-size: var(--font-s);
`

const Samples = () => {

    const { url, vendor, sampleOptions } = useContext(DataContext)

    const [loading, setLoading] = useState(false)
    const [load, setLoad] = useState(false)
    const [sampleType, setSampleType] = useState("")

    ///////////////////////////// Vendor Code //////////////////////////////////

    const [suggestions, setSuggestions] = useState([])
    const [finalVendorCode, setFinalVendorCode] = useState("")
    const [vendorTemp, setVendorTemp] = useState(finalVendorCode)


    const handleVendor = (e) => {
        setVendorTemp(e.target.value)
    }

    const handleFinalVendorCode = (e) => {
        setFinalVendorCode(e)
        setVendorTemp(e)
    }

    useEffect(() => {
        if (vendor) {
            if (vendor.length > 0) {

                setSuggestions(vendor.filter(vendors => {
                    return (vendors.vendorName).toLowerCase().match(vendorTemp.toLowerCase());
                }))
            }
            //Clears Data If Search Input Field Is Empty
            if (vendorTemp.length === 0) {
                setSuggestions([])
            }
        }
    }, [vendorTemp])


    //////////////////////////// Code Generation /////////////////////////

    const details = {
        qtyReceived: "", qtySelected: "", qtyRejected: "", rtvTracking: ""
    };

    const [form, setForm] = useState(details);
    const [msg, setMsg] = useState("")

    const handleForm = (e) => {
        const { name, value } = e.target;

        setForm({
            ...form,
            [name]: value,
        });
    }


    const postForm = async () => {

        const dataCheck = await CheckFirstCodeForm(form, finalVendorCode)

        if (sampleType !== "") {
            if (dataCheck['error'] === "no error") {
                setLoading(true)
                const response = await FetchData({
                    "endpoint": url + "firstcode/add",

                    data: {
                        "form": form,
                        "sampleType": sampleType,
                        "vendor": finalVendorCode
                    }
                })

                if (response.error === "no error") {
                    setLoading(false)
                    setMsg("")
                    clearForm()
                    window.location.reload()
                } else {
                    setMsg(response.message)
                    setLoading(false)
                }
            } else {
                setMsg(dataCheck["message"])
                setLoading(false)
            }
        } else {
            setMsg("Enter Valid Sample Type")
            setLoading(false)
        }
    }

    const clearForm = () => {
        setForm(details)
    }

    //////////////////////// Fetch Codes ///////////////////////

    const [fetched, setFetched] = useState("")

    useEffect(() => {

        async function fetchAll() {
            setLoad(true)
            const response = await FetchData({
                "endpoint": url + "firstcode/fetch",
            })

            if (response.error === "no error") {
                setLoad(false)
                setFetched(response.data)
                setMsg("")
            } else {
                setMsg(response.message)
                setLoad(false)
            }
        }

        fetchAll()
    }, [])


    // useEffect(() => {
    //     // console.log(fetched[0].sku)
    // }, [fetched])

    //////////////////////////// Show Code Summary /////////////////

    const [show, setShow] = useState("")


    return (

        <Container
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
                duration: 0.5
            }}
        >
            {!load ?
                <Body>
                    <Left>
                        <Header>
                            Create Sample Code
                        </Header>
                        <Error>
                            {msg}
                        </Error>
                        <InnerBody>
                            <VendorName>
                                {finalVendorCode === "" ? <>

                                    <Input label={"Vendor Name"} onChange={handleVendor}
                                        name={"vendorName"} />

                                    {suggestions.length > 0 &&
                                        <DropDownBox>
                                            {suggestions.map((value, index) => {
                                                return <DropdownOption key={index} onClick={() => handleFinalVendorCode(value.vendorName)}>
                                                    {value.vendorName}
                                                </DropdownOption>
                                            })
                                            }
                                        </DropDownBox>
                                    }
                                </>
                                    :
                                    <Code>
                                        <CodeName>
                                            {finalVendorCode}
                                        </CodeName>
                                        <Edit onClick={() => setFinalVendorCode("")}>
                                            Edit
                                        </Edit>
                                    </Code>
                                }
                            </VendorName>
                            <Balance>
                                <Input label={"Quantities Received"} onChange={handleForm}
                                    name={"qtyReceived"} />
                                <Input label={"Quantities Selected"} onChange={handleForm}
                                    name={"qtySelected"} />
                                <Input label={"Quantities Rejected"} onChange={handleForm}
                                    name={"qtyRejected"} />
                                <Input label={"RTV Tracking No."} onChange={handleForm}
                                    name={"rtvTracking"} />
                                <Dropdown index={1} ph={"Sample Type"} options={sampleOptions} onChange={"handleSignUpChange"}
                                    name={"Sample Type"} fn={setSampleType} />

                                <ButtonContainer jc={"center"}>
                                    <Button name={loading ? "loading..." : "Generate Code"} onClick={postForm} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} />
                                </ButtonContainer>

                            </Balance>
                        </InnerBody>

                    </Left>
                    <Center>
                        <Header>
                            Created Codes
                        </Header>
                        <InnerBody>
                            {fetched !== "" ?
                                <>
                                    {fetched.map((value, index) => {
                                        return <InnerContainer key={index} onClick={() => setShow(value)}>
                                            <One>
                                                {value.tag}
                                            </One>
                                            <Two>
                                                Created on : {value["createdAt"].slice(0, 10)}
                                            </Two>
                                            <Three>
                                                Selected: {value.qtySelected} | Pending: {(Array.isArray(value['sku']) && value['sku'].length) ? value.qtySelected - value["sku"].length : value.qtySelected}
                                            </Three>
                                        </InnerContainer>

                                    })}
                                </>
                                :
                                <Message>
                                    No Data to Show
                                </Message>
                            }
                        </InnerBody>
                    </Center>
                    <Right>
                        <Header>
                            Summary
                        </Header>
                        <InnerBody>
                            {show !== "" ?
                                <>
                                    <InnerBox>
                                        <Content>
                                            <ContentLeft> Vendor </ContentLeft>
                                            <ContentRight> {show.vendorName} </ContentRight>
                                        </Content>
                                        <Content>
                                            <ContentLeft>Received</ContentLeft>
                                            <ContentRight>{show.qtyReceived}</ContentRight>
                                        </Content>
                                        <Content>
                                            <ContentLeft>Selected</ContentLeft>
                                            <ContentRight color={"#c7f9cc"}>{show.qtySelected}</ContentRight>
                                        </Content>
                                        <Content>
                                            <ContentLeft>Rejected</ContentLeft>
                                            <ContentRight>{show.qtyRejected}</ContentRight>
                                        </Content>
                                        <Content>
                                            <ContentLeft>RTV Detail</ContentLeft>
                                            <ContentRight>{show.rtvTracking}</ContentRight>
                                        </Content>
                                        <Content>
                                            <ContentLeft> Sample Type </ContentLeft>
                                            <ContentRight> {show.sampleType} </ContentRight>
                                        </Content>
                                        <Content>
                                            <ContentLeft> SKU Used </ContentLeft>
                                            <ContentRight color={"#c7f9cc"}> {(Array.isArray(show['sku']) && show['sku'].length) ? show["sku"].length : 0} </ContentRight>
                                        </Content>
                                        <Content>
                                            <ContentLeft> Pending </ContentLeft>
                                            <ContentRight> {(Array.isArray(show['sku']) && show['sku'].length) ? show.qtySelected - show["sku"].length : show.qtySelected} </ContentRight>
                                        </Content>
                                    </InnerBox>
                                    {(Array.isArray(show['sku']) && show['sku'].length) ?
                                        <SKUList>
                                            <Header>
                                                SKU List
                                            </Header>
                                            <SKUBox>
                                                [
                                                {show["sku"].map((value, index) => {
                                                    return <SKU key={index}>
                                                        {value},
                                                    </SKU>

                                                })}
                                                ]
                                            </SKUBox>
                                        </SKUList>
                                        :
                                        <></>
                                    }
                                </>
                                :
                                <Message>
                                    Click Code to Preview
                                </Message>
                            }

                        </InnerBody>
                    </Right>
                </Body>
                :
                <Loader />
            }


        </Container >
    )
}

export default Samples