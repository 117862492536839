import styled from "styled-components"
import { HiViewGridAdd } from "react-icons/hi";


const Container = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    letter-spacing: 1.2px;
    color: ${props => props.color};
`


const StandardMsg = ({ color, value, noImage }) => {
    return (
        <Container color={color}>
            {!noImage &&
                <HiViewGridAdd size={72} color={color} />
            }
            {value}
        </Container>

    )
}

export default StandardMsg