// import { useEffect } from "react";
import { createContext, useEffect, useState } from "react";
let firstCodeJson = require('./firstCode.json');
let vendorJson = require('./vendor.json');


export const DataContext = createContext()

const AuthContextProvider = (props) => {

    const roles = ["Admin", "Merchandiser", "Pricing", "Buyer", "Catalog", "Photographer", "Editor", "QC"]
    const useableRoles = ["Created", "Pending", "Merchandiser", "Pricing", "ProductShoot", "ModelShoot", "MoodShoot", "VideoShoot", "ProductEditor", "ModelEditor", "MoodEditor", "VideoEditor", "ProductQC", "ModelQC", "MoodQC", "VideoQC", "Buyer", "Catalog", "Inactive"]
    const isAdmin = ["Admin", "Not Admin"]
    const looks = ["Timeless Wedding", "Bridesmaid Score", "Celebrations", "Party Icon", "Everyday Minimal"]
    const tags = ["Basic", "Premium"]
    const sampleOptions = ["Paid", "FOC"]
    const statuss = ['pending', "completed"]
    const switchRole = ["Product", "Model", 'Mood', 'Video']
    const rejection = ["Angle Issue", "Out Of Focus", "Styling Issue", "Over Exposed", "Under Exposed", "Sku Mismatch", "Broken Link"]
    const categoryCodes = ["4", "Accessory Gift Set", "Anklet", "Anklets", "Anouk", "Bags", "Bangles", "Belt", "Bracelet", "Brooch", "Chain", "Chain&Necklace", "Clutches", "DEFAULT", "Earrings", "Earrings&MaangTikka", "Facemask", "Hair Accessory", "HairAccessories", "Hat", "Head Jewellery", "Jewellery Set", "Kaleera", "Kaleeras", "Kamarband", "Lingerie accessories", "MaangTikka", "MaathaPatti", "Mangalsutra", "MC", "Necklace and Chains", "NecklaceSet", "NoseAccessories", "Nosepin", "Pasha", "Pendant With Chain", "Pendantset", "Rakhi", "Rings", "Roadster", "rubans925silver", "Saree Accessories", "TTXRUBANS", "Wallet", "Watch Charms"]
    // const filters1 = ["All", "Priority (Stock)", "Priority (Manual)"]


    const firstCode = firstCodeJson
    // const vendor = vendorJson


    // const url = "http://localhost:8080/api/v1/"
    const url = "https://rubans-cloud.et.r.appspot.com/api/v1/"
    const imageUrl = "https://storage.googleapis.com/rubansimage/"
    const stockPriority = 10



    //////////////////////// const imageUrl = "http://localhost:8080/"
    //////////////////////// const url = "https://rubans-dev.et.r.appspot.com/api/v1/"
    //////////////////////// const url = "https://rubans-dev.el.r.appspot.com/api/v1/"


    const [user, setUser] = useState(() => JSON.parse(localStorage.getItem("user") || "[]"));
    // const [selected, setSelected] = useState("");


    // // const fetchAll = async () => {

    // //     const response = await fetch(url + 'product/fetchAll', {
    // //         method: 'post',

    // //         headers: {
    // //             'Accept': 'application/json',
    // //             'Content-Type': 'application/json'
    // //         },
    // //     })
    // //     const json = await response.json()
    // //     setFetchProduct(json.data)
    // // }

    // // const [fetchProduct, setFetchProduct] = useState(() => fetchAll());

    const fetchVendor = async () => {


        const data = await JSON.parse(sessionStorage.getItem("vendors") || "[]")
        setVendorList(data)

        if (data.length < 5) {
            const response = await fetch(url + 'create/fetchvendor', {
                method: 'post',

                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })

            const res = await response.json()
            if (res.error === "no error") {

                const data = [];

                for (let i = 0; i < res["data"].length; i++) {
                    var myObject = res["data"][i]

                    if (myObject.vendorName != null) {
                        data.push(myObject)
                    }
                }
                setVendorList(data)
                sessionStorage.setItem("vendors", JSON.stringify(data))
            }
        }
    }

    const [vendor, setVendorList] = useState(() => fetchVendor());
    // var setVendorList

    useEffect(() => {
        if (user.length > 0) {
            if (user[0]["designation"] === "Admin") {
                setField("firstCodeStatus")
            } else if (user[0]["designation"] === "Merchandiser") {
                setField("merchStatus")
            } else if (user[0]["designation"] === "ProductOps") {
                setField("productOpsStatus")
            } else if (user[0]["designation"] === "Pricing") {
                setField("pricingStatus")
            } else if (user[0]["designation"] === "Buyer") {
                setField("buyerStatus")
            } else if (user[0]["designation"] === "Photographer") {
                setField("productShootStatus")
            } else if (user[0]["designation"] === "ModelShoot") {
                setField("modelShootStatus")
            } else if (user[0]["designation"] === "VideoShoot") {
                setField("videoStatus")
            } else if (user[0]["designation"] === "PhotoEditor") {
                setField("productShootToEditorStatus")
            } else if (user[0]["designation"] === "VideoEditor") {
                setField("Editor2FinalStatus")
            } else if (user[0]["designation"] === "ProductQC") {
                setField("productShootEditToQCStatus")
            } else if (user[0]["designation"] === "ModelQC") {
                setField("QC2FinalStatus")
            } else if (user[0]["designation"] === "Catalog") {
                setField("catalogStatus")
            }
        }

    }, [user])

    const [field, setField] = useState("")


    return (
        <DataContext.Provider value={{ statuss, categoryCodes, switchRole, roles, sampleOptions, useableRoles, user, url, rejection, imageUrl, isAdmin, firstCode, stockPriority, field, looks, vendor, tags, setVendorList }}
        //  value={{ firstCode, roles, isAdmin, url, imageUrl, user, setUser, selected, setSelected, field, filters1 }}
        >
            {props.children}
        </DataContext.Provider>
    )
}

export default AuthContextProvider;
