import { useContext } from "react"
import styled from "styled-components"
import { DataContext } from "../../Contexts/DataContext"
import { motion } from "framer-motion"

const Container = styled(motion.div)`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: var(--font-s);
    overflow-y: scroll;

    position: relative;
    &::-webkit-scrollbar {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-track {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-thumb {
        width: 5px;
        background-color: #D6EAF8;
    }

`

const Box = styled(motion.div)`

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: var(--font-s);
    overflow-y: scroll;

    position: relative;
    &::-webkit-scrollbar {
        width: 0px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-track {
        width: 0px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-thumb {
        width: 0px;
        background-color: #D6EAF8;
    }
`

const Header = styled(motion.div)`
    height: 35px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    letter-spacing: var(--ls);
    width: max-content;
    background-color: #f1faee;
    padding: 5px 10px;
    color: var(--font-color);
`

const Body = styled(motion.div)`
    height: calc(100% - 50px);
    width: 100%;
    padding: 25px 5px;
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-direction: column;
`
const Object = styled(motion.div)`
    min-height: 30px;
    width: 100%;
    display: flex;
    // align-items: center;
    justify-content: center;
`

const Left = styled(motion.div)`
    height: 100%;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
`
const Right = styled(motion.div)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 5px;
    height: 100%;
    width: calc(100% - 50px);
    letter-spacing: var(--ls);
`

const Name = styled.div`
    height: 60%;
    font-size: 12px;
    color: ${props => props.color};
`
const Time = styled.div`
    height: 40%;
    font-style: italic;
    color: ${props => props.color};
    font-size: var(--font-xs);
`
const Round = styled.div`
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: ${props => props.color};
`
const Line = styled.div`
    height: 100%;
    width: 2px;
    background-color: ${props => props.color};
`

const HeadBox = styled.div`
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: var(--ls);
    gap: 50px;
`
const Head = styled.div`
    width: max-content;
    background-color: #f1faee;
    padding: 5px 10px;
    color: var(--font-color);
    border-radius: 3px;
`
const Edit = styled.div`
    width: max-content;
    background-color: #fcbf49;
    padding: 5px 10px;
    color: var(--font-color);
    border-radius: 3px;
    box-shadow: var(--box-shadow);
    cursor: pointer;
`

const Stepper = ({ selectedSKUValue, home, setShowStepper }) => {

    // const { user } = useContext(DataContext)

    const handleEdit = () => {
        setShowStepper(false)
    }


    return (
        <Container
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
                type: "spring",
                stiffness: 100,
                duration: 0.3
            }}
        >
            <Box
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                    type: "spring",
                    stiffness: 100,
                    duration: 0.5
                }}
            >
                {/* {home !== true && */}
                <Header
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                        type: "spring",
                        stiffness: 100,
                        duration: 0.5
                    }}
                >
                    SKU Journey : {selectedSKUValue[0].firstCode}
                </Header>
                {/* } */}
                {/* {home === true &&
                    <HeadBox>
                        <Head>
                            SKU Journey : {selectedSKUValue[0].firstCode}
                        </Head>
                        <Edit onClick={() => handleEdit()}>
                            Edit
                        </Edit>
                    </HeadBox>
                } */}
                <Body>

                    {(selectedSKUValue[0].merchStatus === "completed") &&
                        <>
                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.1
                                }}
                            >
                                <Left>
                                    <Round color={home === true ? "#ff006e" : "#5603ad"} />
                                </Left>
                                <Right>
                                    <Name color={home === true ? "white" : "#03045e"}>
                                        Merchandiser
                                    </Name>
                                    <Time color={home === true ? "lightgrey" : "grey"}>
                                        {(selectedSKUValue[0].merchCreatedTime).replace("GMT+0000 (Coordinated Universal Time)", '')}
                                    </Time>
                                </Right>
                            </Object>
                        </>
                    }

                    {(selectedSKUValue[0].productOpsStatus === "completed") &&
                        <>
                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.2
                                }}
                            >
                                <Left> <Line color={home === true ? "#ff006e" : "#5603ad"} /> </Left>
                                <Right />
                            </Object>

                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.2
                                }}
                            >
                                <Left>
                                    <Round color={home === true ? "#ff006e" : "#5603ad"} />
                                </Left>
                                <Right>
                                    <Name color={home === true ? "white" : "#03045e"}>
                                        Product Ops
                                    </Name>
                                    <Time color={home === true ? "lightgrey" : "grey"}>
                                        {(selectedSKUValue[0].productOpsEndTime).replace("GMT+0000 (Coordinated Universal Time)", '')}
                                    </Time>
                                </Right>
                            </Object>
                        </>
                    }


                    {(selectedSKUValue[0].pricingStatus === "completed") &&
                        <>
                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.25
                                }}
                            >
                                <Left> <Line color={home === true ? "#ff006e" : "#5603ad"} /> </Left>
                                <Right />
                            </Object>


                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.25
                                }}
                            >
                                <Left>
                                    <Round color={home === true ? "#ff006e" : "#5603ad"} />
                                </Left>
                                <Right>
                                    <Name color={home === true ? "white" : "#03045e"}>
                                        Pricing
                                    </Name>
                                    <Time color={home === true ? "lightgrey" : "grey"}>
                                        {(selectedSKUValue[0].pricingEndTime).replace("GMT+0000 (Coordinated Universal Time)", '')}
                                    </Time>
                                </Right>
                            </Object>
                        </>
                    }


                    {(selectedSKUValue[0].buyerStatus === "completed") &&
                        <>
                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.3
                                }}
                            >
                                <Left> <Line color={home === true ? "#ff006e" : "#5603ad"} /> </Left>
                                <Right />
                            </Object>


                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.3
                                }}
                            >
                                <Left>
                                    <Round color={home === true ? "#ff006e" : "#5603ad"} />
                                </Left>
                                <Right>
                                    <Name color={home === true ? "white" : "#03045e"}>
                                        Buyer
                                    </Name>
                                    <Time color={home === true ? "lightgrey" : "grey"}>
                                        {(selectedSKUValue[0].buyerEndTime).replace("GMT+0000 (Coordinated Universal Time)", '')}
                                    </Time>
                                </Right>
                            </Object>
                        </>
                    }


                    {(selectedSKUValue[0].productShootStatus === "completed") &&
                        <>
                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.35
                                }}
                            >
                                <Left> <Line color={home === true ? "#ff006e" : "#5603ad"} /> </Left>
                                <Right />
                            </Object>


                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.35
                                }}
                            >
                                <Left>
                                    <Round color={home === true ? "#ff006e" : "#5603ad"} />
                                </Left>
                                <Right>
                                    <Name color={home === true ? "white" : "#03045e"}>
                                        Product Shoot
                                    </Name>
                                    <Time color={home === true ? "lightgrey" : "grey"}>
                                        {(selectedSKUValue[0].productShootEndTime).replace("GMT+0000 (Coordinated Universal Time)", '')}
                                    </Time>
                                </Right>
                            </Object>
                        </>
                    }


                    {(selectedSKUValue[0].modelShootStatus === "completed") &&
                        <>
                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.4
                                }}
                            >
                                <Left> <Line color={home === true ? "#ff006e" : "#5603ad"} /> </Left>
                                <Right />
                            </Object>


                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.4
                                }}
                            >
                                <Left>
                                    <Round color={home === true ? "#ff006e" : "#5603ad"} />
                                </Left>
                                <Right>
                                    <Name color={home === true ? "white" : "#03045e"}>
                                        Model Shoot
                                    </Name>
                                    <Time color={home === true ? "lightgrey" : "grey"}>
                                        {(selectedSKUValue[0].modelShootEndTime).replace("GMT+0000 (Coordinated Universal Time)", '')}
                                    </Time>
                                </Right>
                            </Object>
                        </>
                    }


                    {(selectedSKUValue[0].moodShootStatus === "completed") &&
                        <>
                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.4
                                }}
                            >
                                <Left> <Line color={home === true ? "#ff006e" : "#5603ad"} /> </Left>
                                <Right />
                            </Object>


                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.4
                                }}
                            >
                                <Left>
                                    <Round color={home === true ? "#ff006e" : "#5603ad"} />
                                </Left>
                                <Right>
                                    <Name color={home === true ? "white" : "#03045e"}>
                                        Mood Shoot
                                    </Name>
                                    <Time color={home === true ? "lightgrey" : "grey"}>
                                        {(selectedSKUValue[0].moodShootEndTime).replace("GMT+0000 (Coordinated Universal Time)", '')}
                                    </Time>
                                </Right>
                            </Object>
                        </>
                    }




                    {(selectedSKUValue[0].videoStatus === "completed") &&
                        <>
                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.45
                                }}
                            >
                                <Left> <Line color={home === true ? "#ff006e" : "#5603ad"} /> </Left>
                                <Right />
                            </Object>


                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.45
                                }}
                            >
                                <Left>
                                    <Round color={home === true ? "#ff006e" : "#5603ad"} />
                                </Left>
                                <Right>
                                    <Name color={home === true ? "white" : "#03045e"}>
                                        Video Shoot
                                    </Name>
                                    <Time color={home === true ? "lightgrey" : "grey"}>
                                        {(selectedSKUValue[0].videoEndTime).replace("GMT+0000 (Coordinated Universal Time)", '')}
                                    </Time>
                                </Right>
                            </Object>
                        </>
                    }


                    {(selectedSKUValue[0].productShootToEditorStatus === "completed") &&
                        <>
                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.5
                                }}
                            >
                                <Left> <Line color={home === true ? "#ff006e" : "#5603ad"} /> </Left>
                                <Right />
                            </Object>


                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.5
                                }}
                            >
                                <Left>
                                    <Round color={home === true ? "#ff006e" : "#5603ad"} />
                                </Left>
                                <Right>
                                    <Name color={home === true ? "white" : "#03045e"}>
                                        Product Editor
                                    </Name>
                                    <Time color={home === true ? "lightgrey" : "grey"}>
                                        {(selectedSKUValue[0].productShootToEditorEndTime).replace("GMT+0000 (Coordinated Universal Time)", '')}
                                    </Time>
                                </Right>
                            </Object>
                        </>
                    }

                    {(selectedSKUValue[0].modelShootToEditorStatus === "completed") &&
                        <>
                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.5
                                }}
                            >
                                <Left> <Line color={home === true ? "#ff006e" : "#5603ad"} /> </Left>
                                <Right />
                            </Object>


                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.5
                                }}
                            >
                                <Left>
                                    <Round color={home === true ? "#ff006e" : "#5603ad"} />
                                </Left>
                                <Right>
                                    <Name color={home === true ? "white" : "#03045e"}>
                                        Model Editor
                                    </Name>
                                    <Time color={home === true ? "lightgrey" : "grey"}>
                                        {(selectedSKUValue[0].modelShootToEditorEndTime).replace("GMT+0000 (Coordinated Universal Time)", '')}
                                    </Time>
                                </Right>
                            </Object>
                        </>
                    }


                    {(selectedSKUValue[0].moodShootToEditorStatus === "completed") &&
                        <>
                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.5
                                }}
                            >
                                <Left> <Line color={home === true ? "#ff006e" : "#5603ad"} /> </Left>
                                <Right />
                            </Object>


                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.5
                                }}
                            >
                                <Left>
                                    <Round color={home === true ? "#ff006e" : "#5603ad"} />
                                </Left>
                                <Right>
                                    <Name color={home === true ? "white" : "#03045e"}>
                                        Mood Editor
                                    </Name>
                                    <Time color={home === true ? "lightgrey" : "grey"}>
                                        {(selectedSKUValue[0].moodShootToEditorEndTime).replace("GMT+0000 (Coordinated Universal Time)", '')}
                                    </Time>
                                </Right>
                            </Object>
                        </>
                    }



                    {(selectedSKUValue[0].videoToEditorStatus === "completed") &&
                        <>
                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.55
                                }}
                            >
                                <Left> <Line color={home === true ? "#ff006e" : "#5603ad"} /> </Left>
                                <Right />
                            </Object>


                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.55
                                }}
                            >
                                <Left>
                                    <Round color={home === true ? "#ff006e" : "#5603ad"} />
                                </Left>
                                <Right>
                                    <Name color={home === true ? "white" : "#03045e"}>
                                        Video Editor
                                    </Name>
                                    <Time color={home === true ? "lightgrey" : "grey"}>
                                        {(selectedSKUValue[0].videoToEditorEndTime).replace("GMT+0000 (Coordinated Universal Time)", '')}
                                    </Time>
                                </Right>
                            </Object>
                        </>
                    }


                    {(selectedSKUValue[0].productShootEditToQCStatus === "completed") &&
                        <>
                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.6
                                }}
                            >
                                <Left> <Line color={home === true ? "#ff006e" : "#5603ad"} /> </Left>
                                <Right />
                            </Object>


                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.6
                                }}
                            >
                                <Left>
                                    <Round color={home === true ? "#ff006e" : "#5603ad"} />
                                </Left>
                                <Right>
                                    <Name color={home === true ? "white" : "#03045e"}>
                                        Product QC
                                    </Name>
                                    <Time color={home === true ? "lightgrey" : "grey"}>
                                        {(selectedSKUValue[0].productShootEditToQCEndTime).replace("GMT+0000 (Coordinated Universal Time)", '')}
                                    </Time>
                                </Right>
                            </Object>
                        </>
                    }


                    {(selectedSKUValue[0].modelShootEditToQCStatus === "completed") &&
                        <>
                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.6
                                }}
                            >
                                <Left> <Line color={home === true ? "#ff006e" : "#5603ad"} /> </Left>
                                <Right />
                            </Object>


                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.6
                                }}
                            >
                                <Left>
                                    <Round color={home === true ? "#ff006e" : "#5603ad"} />
                                </Left>
                                <Right>
                                    <Name color={home === true ? "white" : "#03045e"}>
                                        Model QC
                                    </Name>
                                    <Time color={home === true ? "lightgrey" : "grey"}>
                                        {(selectedSKUValue[0].modelShootEditToQCEndTime).replace("GMT+0000 (Coordinated Universal Time)", '')}
                                    </Time>
                                </Right>
                            </Object>
                        </>
                    }


                    {(selectedSKUValue[0].moodShootEditToQCStatus === "completed") &&
                        <>
                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.6
                                }}
                            >
                                <Left> <Line color={home === true ? "#ff006e" : "#5603ad"} /> </Left>
                                <Right />
                            </Object>


                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.6
                                }}
                            >
                                <Left>
                                    <Round color={home === true ? "#ff006e" : "#5603ad"} />
                                </Left>
                                <Right>
                                    <Name color={home === true ? "white" : "#03045e"}>
                                        Mood QC
                                    </Name>
                                    <Time color={home === true ? "lightgrey" : "grey"}>
                                        {(selectedSKUValue[0].moodShootEditToQCEndTime).replace("GMT+0000 (Coordinated Universal Time)", '')}
                                    </Time>
                                </Right>
                            </Object>
                        </>
                    }


                    {(selectedSKUValue[0].videoEditToQCStatus === "completed") &&
                        <>
                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.6
                                }}
                            >
                                <Left> <Line color={home === true ? "#ff006e" : "#5603ad"} /> </Left>
                                <Right />
                            </Object>


                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.6
                                }}
                            >
                                <Left>
                                    <Round color={home === true ? "#ff006e" : "#5603ad"} />
                                </Left>
                                <Right>
                                    <Name color={home === true ? "white" : "#03045e"}>
                                        Video QC
                                    </Name>
                                    <Time color={home === true ? "lightgrey" : "grey"}>
                                        {(selectedSKUValue[0].videoEditToQCEndTime).replace("GMT+0000 (Coordinated Universal Time)", '')}
                                    </Time>
                                </Right>
                            </Object>
                        </>
                    }



                    {(selectedSKUValue[0].catalogStatus === "completed") &&
                        <>
                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.7
                                }}
                            >
                                <Left> <Line color={home === true ? "#ff006e" : "#5603ad"} /> </Left>
                                <Right />
                            </Object>

                            <Object
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    duration: 0.5, delay: 0.7
                                }}
                            >
                                <Left>
                                    <Round color={home === true ? "#ff006e" : "#5603ad"} />
                                </Left>
                                <Right>
                                    <Name color={home === true ? "white" : "#03045e"}>
                                        Catalog
                                    </Name>
                                    <Time color={home === true ? "lightgrey" : "grey"}>
                                        {(selectedSKUValue[0].catalogEndTime).replace("GMT+0000 (Coordinated Universal Time)", '')}
                                    </Time>
                                </Right>
                            </Object>
                        </>
                    }

                </Body>
            </Box>
        </Container>
    )
}

export default Stepper