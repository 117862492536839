import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import Button from "../Components/Subcomponents/Button"
import Dropdown from "../Components/Subcomponents/Dropdown"
import { useNavigate } from 'react-router-dom';
import { FetchData } from "../Components/Subcomponents/FetchData";
import { DataContext } from "../Contexts/DataContext";


const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    min-width: 100%;
`

const Body = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 300px;
    min-width: 300px;
    background-color: #ecf8f8;
    border-radius: 5px;
    box-shadow: var(--box-shadow);
    gap: 20px;
    padding: 20px 0px;
    font-size: var(--font-s);
    letter-spacing: var(--ls);
    text-transform: uppercase;
`
const Name = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    width: 200px;
`

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
`

const Value = styled.div`
    display: flex;
    align-items: center;
    width: 200px;
    justify-content: space-between;
    font-size: var(--font-s);
    text-transform: uppercase;
    letter-spacing: var(--ls);
    gap: 15px;
`

const Modal = () => {

    const { user, switchRole, url } = useContext(DataContext)

    const navigate = useNavigate()


    useEffect(() => {
        console.log(user)
    }, [])

    const [designation, setDesignation] = useState("Product");

    const handleClick = async () => {

        let desc

        const response = await FetchData({
            "endpoint": url + "user/fetchbyname",
            data: {
                "user": user,
            }
        })

        const json = await response.data

        if (json === "Photographer") {
            if (designation === "Product") {
                desc = "Photographer"
            } else if (designation === "Model") {
                desc = "ModelShoot"
            } else if (designation === "Mood") {
                desc = "MoodShoot"
            } else if (designation === "Video") {
                desc = "VideoShoot"
            }
        }

        if (json === "Editor") {
            if (designation === "Product") {
                desc = "Editor"
            } else if (designation === "Model") {
                desc = "ModelEditor"
            } else if (designation === "Mood") {
                desc = "MoodEditor"
            } else if (designation === "Video") {
                desc = "VideoEditor"
            }
        }


        if (json === "QC") {
            if (designation === "Product") {
                desc = "QC"
            } else if (designation === "Model") {
                desc = "ModelQC"
            } else if (designation === "Mood") {
                desc = "MoodQC"
            } else if (designation === "Video") {
                desc = "VideoQC"
            }
        }

        if (desc !== "") {
            user[0].designation = desc
            localStorage.setItem('user', JSON.stringify(user))
        }

        navigate("/home")
        window.location.reload();

    }

    const cancelClick = async () => {
        navigate("/home")
    }


    return (
        <Container>
            {user &&
                <>
                    <Body>
                        <Name>
                            Switch Role
                        </Name>
                        <Content>
                            <Dropdown ph={"Product"} options={switchRole}
                                name={"designation"} fn={setDesignation} />
                        </Content>
                        <Value>
                            <Button name={"cancel"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} onClick={cancelClick} />
                            <Button name={"Update"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} onClick={handleClick} />
                        </Value>
                    </Body>
                </>
            }
        </Container >
    )
}

export default Modal