import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import Button from "./Button"


const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 10px 20px;
`
const Header = styled.div`
    display: flex;
    height: 40px;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid grey;
`
const Heading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-s);
    text-transform: caplitalize;
    letter-spacing: 1.2px;
    flex: 1;
    font-weight: 600;
    color: #023e8a;
`
const Values = styled.div`
    display: flex;
    height: 50px;
    width: 100%;
    align-items: center;
    justify-content: center;
`
const Value = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-s);
    text-transform: capitalize;
    letter-spacing: var(--ls);
    flex: 1;
    gap: 15px;
    text-overflow: ellipsis;
`
const Msg = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    letter-spacing: var(--ls);
    text-transform: capitalize;
    border-top: 1px solid lightgray;
    color: gray;
`

const Approved = ({ value }) => {

    const navigate = useNavigate()

    const handleChange = (userName, designation, accessLevel) => {
        navigate("/modal", {
            state: {
                "userName": userName,
                "designation": designation,
                "accessLevel": accessLevel
            }
        })
    }

    return (
        <Container>
            <Header>
                <Heading>User Name</Heading>
                <Heading>Role</Heading>
                <Heading>Access Level</Heading>
                <Heading>Edit</Heading>
            </Header>

            {value.filter(function (user) {
                return user.status === "approved"
            }).map((val, index) => {
                return <Values key={index}>
                    <Value>{(val.userName).length > 12 ? (val.userName).slice(0, 11) + "..." : val.userName}</Value>
                    <Value>{val.designation}</Value>
                    <Value>{val.accessLevel}</Value>
                    <Value>
                        <Button name={"edit"} bgcolor={"#ffd166"} color={"var(--font-color)"} onClick={() => handleChange(val.userName, val.designation, val.accessLevel)} />
                    </Value>
                </Values>
            })}

            <Msg>
                End of list
            </Msg>
        </Container>
    )
}



const Pending = ({ value1, userApproved, userRejected }) => {

    const handleApproved = (userName) => {
        userApproved(userName)
    }

    const handleRejected = (userName) => {
        userRejected(userName)
    }

    return (
        <Container>
            <Header>
                <Heading>User Name</Heading>
                <Heading>Role</Heading>
                <Heading>Access Level</Heading>
                <Heading>Approval</Heading>
            </Header>

            {value1.filter(function (user) {
                return user.status === "pending"
            }).map((val, index) => {
                return <Values key={index}>
                    <Value>{(val.userName).length > 12 ? (val.userName).slice(0, 11) + "..." : val.userName}</Value>
                    <Value>{val.designation}</Value>
                    <Value>{val.accessLevel}</Value>
                    <Value>
                        <Button name={"no"} bgcolor={"#ff4d6d"} color={"var(--main-color)"} onClick={() => handleRejected(val.userName)} />
                        <Button name={"yes"} bgcolor={"#b9fbc0"} color={"var(--font-color)"} onClick={() => handleApproved(val.userName)} />
                    </Value>
                </Values>
            })}

            <Msg>
                End of list
            </Msg>
        </Container>
    )
}


export { Approved, Pending }

