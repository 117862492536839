import styled from "styled-components"
import { BiSearchAlt } from 'react-icons/bi'
import { IoMdOptions } from "react-icons/io"
import { GrPrevious, GrNext } from "react-icons/gr"
import Button from "./Subcomponents/Button"
import Input from "./Subcomponents/Input"
import Loader from "./Subcomponents/Loader"
import { useContext, useEffect, useState } from "react"
import StandardMsg from "./Subcomponents/StandardMsg"
import { DataContext } from "../Contexts/DataContext"
import { FetchData } from "./Subcomponents/FetchData"
import { GrClose } from "react-icons/gr"
import HoverableDiv from "./Subcomponents/HoverableDiv"
import HoverableDiv1 from "./Subcomponents/HoverableDiv1"


const Container = styled.div`
    width: 100%;
    height: 100%;
`

const Header = styled.div`
    height: 40px;
    letter-spacing: var(--ls);
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`
const Body = styled.div`
    height: calc(100% - 80px);
    width: 100%;
    overflow-y: scroll;
    padding: 10px 0px;
    &::-webkit-scrollbar {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-track {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-thumb {
        width: 5px;
        background-color: #ffb4a2;
    }
`

const Pagination = styled.div`
    height: 40px;
    letter-spacing: var(--ls);
    display: flex;
    width: 90%;
    margin: auto;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    background-color: #fcbf49;
    border-radius: 5px;
    box-shadow: var(--box-shadow);
`

const EmptyDiv = styled.div`
    min-height: 40px;
`

const Left = styled.div`
    height: 100%;
    color: #f72585;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: var(--font-s);
`

const Count = styled.div`
    background-color: #ffcdb2;
    color: var(--font-color);
    font-size: var(--font-xs);
    padding: 3px 5px;
    border-radius: 2px;
`

const Right = styled.div`
    display: flex;
    height: 100%;
    gap: 10px;
    cursor: pointer;
    background-color: #fcbf49;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 5px;
`

const Card = styled.div`
    height: 90px;
    width: 95%;
    background-color: ${props => props.color};
    margin: auto;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 6px 6px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 10px auto;
    border-radius: 5px;
    font-size: var(--font-xs);
    letter-spacing: var(--ls);
    position: relative;
    overflow: hidden;

`

const Paginate = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
    cursor: pointer;
    font-size: var(--font-s);
    justify-content: ${props => props.ji};
`

const Image = styled.div`
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
`
const ImageBox = styled.div`
    height: 70px;
    width: 70px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dde5b6;
    box-shadow: var(--box-shadow);
`

const Content = styled.div`
    width: 60%;
    height: 100%;
    padding: 5px 3px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 3px;
    color: #03045e;
`
const Name = styled.div`
    text-transform: uppercase;
`
const Priority = styled.div`
    text-transform: capitalize;
    color: red;
`
const Comments = styled.div`
`

const Filter = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    font-size: 12px;
    letter-spacing: 0.8px;
    background-color: #e7ecef;
    padding: 10px;
    box-shadow: var(--box-shadow);
    border-radius: 5px;
    width: 90%;
    margin: auto;
`

const Label = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 20px;
`

const InputField = styled.input`
`

const TopLabel = styled.div`
    margin: 20px 0px;
    background-color: #ffcdb2;
    padding: 5px 10px;
    width: max-content;
    border-radius: 3px;
`

const FilterBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`

const FilterBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px 10px;
`

const SearchBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
`

const Ribbon = styled.div`
    position: absolute;
    inset: 0 auto auto 0;
    background: ${props => props.bg};
    transform-origin: 100% 0;
    transform: translate(-29.3%) rotate(-45deg);
    box-shadow: 0 0 0 999px ${props => props.bg};
    clip-path: inset(0 -100%);
    color: white;
`


const LeftBox = ({ setSelected, setShowStepper }) => {

    const { url, user, imageUrl, stockPriority } = useContext(DataContext)
    const [reFetch, setReFetch] = useState(false)

    const [commentField, setCommentField] = useState("")

    const [toShow, setToShow] = useState("regular")
    const [list, setList] = useState()
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [end, setEnd] = useState(10)
    const [message, setMessage] = useState("No Pending Tasks")


    ///////////////////// set Comments Field ////////////////////

    useEffect(() => {
        if (user) {
            if (user[0].designation === "Photographer") {
                setCommentField("productShootReEdit")
            } else if (user[0].designation === "ModelShoot") {
                setCommentField("modelShootReEdit")
            } else if (user[0].designation === "MoodShoot") {
                setCommentField("moodShootReEdit")
            } else if (user[0].designation === "VideoShoot") {
                setCommentField("videoReEdit")
            } else if (user[0].designation === "Editor") {
                setCommentField("productShootToEditorReEdit")
            } else if (user[0].designation === "ModelEditor") {
                setCommentField("modelShootToEditorReEdit")
            } else if (user[0].designation === "MoodEditor") {
                setCommentField("moodShootToEditorReEdit")
            } else if (user[0].designation === "VideoEditor") {
                setCommentField("videoToEditorReEdit")
            }
        }
    }, [user])


    ///////////////////////////// Image Hover //////////////////////////////////

    const [isHovering, setIsHovering] = useState(false)
    const [imageLink, setImageLink] = useState("")


    const handleHover = (value, image) => {
        if (value === true) {
            setIsHovering(true)
            setImageLink(image)
        } else {
            setIsHovering(false)
            setImageLink(image)
        }
    }



    ///////////////////// Fetch Count ////////////////////////////

    async function fetchCount() {
        const response = await FetchData({
            "endpoint": url + "boxview/fetchleftcount",
            data: {
                "designation": user[0].designation,
                "allChecked": allChecked,
                "manualChecked": manualChecked,
                "stockChecked": stockChecked,
                "stockPriority": stockPriority
            }
        })
        if (response.error === "no error") {
            setCount(response.data)
        } else {
            setMessage(response.message)
        }
    }

    useEffect(() => {
        fetchCount()
    }, [])


    //////////////////////// Fetching Data ///////////////////////

    async function fetchFn() {
        setToShow("loader")
        const response = await FetchData({
            "endpoint": url + "boxview/fetchleft",
            data: {
                "designation": user[0].designation,
                "start": start,
                "end": end,
                "allChecked": allChecked,
                "manualChecked": manualChecked,
                "stockChecked": stockChecked,
                "stockPriority": stockPriority
            }
        })

        if (response.error === "no error") {
            if (response["data"].length > 0) {
                setList(response.data)
                setToShow("regular")
            } else {
                setToShow("empty")
            }
        } else {
            setMessage(response.message)
        }
    }

    useEffect(() => {
        fetchFn()
    }, [user, start, end, reFetch])


    /////////////////////////////// Pagination //////////////////////

    const handlePrev = () => {
        setStart(start - 10)
        setEnd(end - 10)
    }

    const handleNext = () => {
        setStart(start + 10)
        setEnd(end + 10)
    }

    //////////////////////////// Search /////////////////////////////

    const [search, setSearch] = useState("")

    const handleSearchClose = () => {
        window.location.reload()
    }

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    const postSearch = async () => {
        if (search !== "") {
            setToShow("loader")
            const response = await FetchData({
                "endpoint": url + "boxview/fetchone",
                data: {
                    "designation": user[0].designation,
                    "search": search,
                    "status": "pending"
                }
            })

            if (response.error === "no error") {
                if (response["data"].length > 0) {
                    setList(response.data)
                    setToShow("regular")
                } else {
                    setToShow("empty")
                }
            } else {
                setMessage(response.message)
                setToShow("empty")
            }
        }
    }

    /////////////////////////////////// Filter ////////////////////////

    const [allChecked, setAllChecked] = useState(true)
    const [manualChecked, setManualChecked] = useState(false)
    const [stockChecked, setStockChecked] = useState(false)

    const handleAllChecked = () => {
        setAllChecked(true)
        setManualChecked(false)
        setStockChecked(false)
    }

    const handleManualChecked = () => {
        setAllChecked(false)
        setManualChecked(true)
        setStockChecked(false)
    }

    const handleStockChecked = () => {
        setAllChecked(false)
        setManualChecked(false)
        setStockChecked(true)
    }

    const handleFilter = () => {
        setStart(0)
        setEnd(10)
        fetchCount()
        fetchFn()
    }

    //////////////////////////// Selected SKU //////////////////////////

    const handleSelected = (e) => {
        setShowStepper(false)
        setSelected(e)
    }

    return (
        <Container>
            {isHovering === true && <HoverableDiv1 url={imageLink} />}

            <Header>
                {(toShow !== "search") &&
                    <>
                        <Left>
                            Pending Tasks
                            <Count>{end < count ? end + "/" + count : count + "/" + count}</Count>
                        </Left>
                        <Right>
                            <IoMdOptions size={20} onClick={() => setToShow("filter")} />
                            <BiSearchAlt size={20} onClick={() => setToShow("search")} />
                        </Right>
                    </>
                }
                {toShow === "search" &&

                    <SearchBox>
                        <span style={{ cursor: "pointer" }}>
                            <GrClose size={20} color={'red'} onClick={() => handleSearchClose()} />
                        </span>
                        <Input index={1} label={"Search"} value={search}
                            name={"styleID"} onChange={handleSearch} />
                        <div >
                            <Button name={"submit"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} onClick={postSearch} />
                        </div>
                    </SearchBox>
                }
            </Header>
            <Body>
                {toShow === "regular" &&
                    <>
                        {list && <>
                            {list.map((value, index) => {
                                return <Card key={index} color={value.stock > stockPriority ? "#f8edeb" : "#e4fee4"} onClick={() => handleSelected(value.firstCode)}>
                                    <Image>
                                        <ImageBox>
                                            <img onMouseOver={() => handleHover(true, value.photo)} onMouseOut={() => handleHover(false, value.photo)} style={{
                                                height: "50px", width: "50px", borderRadius: "5px", objectFit: "cover", objectPosition: "center"

                                            }} src={imageUrl + value.photo} alt="" />
                                        </ImageBox>
                                    </Image>
                                    <Content>
                                        <Name>
                                            {value.firstCode} /INV: {value.stock}
                                            {value.priority === "true" &&
                                                <Priority>
                                                    Priority
                                                </Priority>
                                            }
                                        </Name>
                                        {commentField !== "" && <>
                                            {(value[commentField] !== "") &&
                                                <Comments style={{ color: "red" }}>
                                                    {console.log(value[commentField], commentField)}
                                                    {(user[0].designation === "ProductShoot" || user[0].designation === "ModelShoot" || user[0].designation === "MoodShoot" || user[0].designation === "VideoShoot") ? "Re-Shoot" : "Re-Edit"}
                                                </Comments>
                                            }
                                        </>
                                        }
                                    </Content>
                                    <Ribbon bg={value.tag === "Premium" ? "#f72585" : "#2b2b2b"}>
                                        {value.tag}
                                    </Ribbon>

                                </Card>
                            })}
                        </>}
                    </>
                }

                {toShow === "empty" &&
                    <StandardMsg noImage={true} color={"#9381ff"} value={message} />
                }

                {toShow === "loader" &&
                    <Loader />
                }

                {toShow === "filter" &&
                    <Filter>
                        <FilterBox>
                            <TopLabel>
                                Filter By Priority
                            </TopLabel>
                            <Label onClick={() => handleAllChecked()}>
                                <InputField type="radio" checked={allChecked}
                                />
                                All
                            </Label>
                            <Label onClick={() => handleManualChecked()}>
                                <InputField type="radio" checked={manualChecked}
                                />
                                Priority (Manual)
                            </Label>
                            <Label onClick={() => handleStockChecked()}>
                                <InputField type="radio" checked={stockChecked}
                                />
                                Priority (Stock)
                            </Label>
                        </FilterBox>

                        {/* <FilterBox>
                            <TopLabel>
                                Filter By Tag
                            </TopLabel>
                            <Label>
                                <InputField type="radio" checked={tag === "Basic" ? true : false}
                                />
                                Basic
                            </Label>
                            <Label>
                                <InputField type="radio" checked={tag === "Premium" ? true : false}
                                />
                                Premium
                            </Label>
                        </FilterBox> */}

                        <FilterBtn>
                            <div onClick={() => setToShow("regular")}>
                                <Button name={"clear"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} />
                            </div>
                            <div>
                                <Button name={"submit"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} onClick={handleFilter} />
                            </div>
                        </FilterBtn>
                    </Filter>
                }

            </Body>
            {toShow === "regular" &&
                <Pagination>
                    {start > 0 ?
                        <Paginate onClick={() => handlePrev()} ji={"space-between"}>
                            <GrPrevious size={20} />
                            PREV
                        </Paginate >
                        :
                        <Paginate ji={"space-between"} />
                    }
                    {end < count ?
                        <Paginate onClick={() => handleNext()} ji={"space-between"}>
                            NEXT
                            <GrNext size={20} />
                        </Paginate>
                        :
                        <Paginate ji={"space-between"} />
                    }
                    {count <= 10 &&
                        <Paginate ji={"center"}>
                            Showing All Data
                        </Paginate>
                    }
                </Pagination>
            }
            {toShow !== "regular" &&
                <EmptyDiv />
            }
        </Container>

    )
}

export default LeftBox