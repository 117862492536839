import styled from "styled-components"
import { RxCodesandboxLogo } from "react-icons/rx"
import { useNavigate } from "react-router-dom"
import { useContext } from "react"
import { DataContext } from "../Contexts/DataContext"
import { motion } from "framer-motion"

const Nav = styled(motion.div)`
    height: 55px;
    min-width: 100%;
    background-color: var(--bg-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--nav-color);
    padding: 0px 15px;
`
const Logo = styled.div`
    font-size: var(--font-l);
    padding-left: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-weight: 600;
`
const Menu = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-s);
    gap: 5%;
    padding-right: 100px;
`
const Login = styled.div`
    background-color: var(--nav-color);
    color: var(--main-color);
    width: max-content;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    letter-spacing: 1.2px;
`
const Option = styled.div`
    color: var(--font-color);
    width: max-content;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    font-size: var(--font-s);
    letter-spacing: 1.2px;
    text-transform: capitalize;
`

const Option1 = styled.div`
    color: green;
    width: max-content;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    font-size: var(--font-s);
    letter-spacing: 1.2px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`

const Navbar = () => {

    const navigate = useNavigate()
    const { user } = useContext(DataContext)

    const handleLogin = () => {
        localStorage.removeItem("user")
        navigate("/")
        window.location.reload()
    }

    return (
        <Nav
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
                ease: "easeInOut",
                duration: 0.5, delay: 0
            }}
        >
            <Logo>
                <RxCodesandboxLogo size={28} />
                RUBANS
            </Logo>

            <Menu
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                    ease: "easeInOut",
                    duration: 0.5, delay: 0.3
                }}
            >
                {user.length > 0 && (user[0]['designation'] === "Admin") ? <Option onClick={() => navigate("/edit")}> Edit </Option> : ""}
                {user.length > 0 && (user[0]['designation'] === "Admin") ? <Option onClick={() => navigate("/users")}> Users</Option> : ""}
                {user.length > 0 && (user[0]['accessLevel'] === "Admin") ? <Option onClick={() => navigate("/dashboard")}> Dashboard</Option> : ""}

                {user.length > 0 && (
                    (user[0]['designation'] === "ModelQC" ||
                        user[0]['designation'] === "MoodQC" ||
                        user[0]['designation'] === "VideoQC" ||
                        user[0]['designation'] === "QC")
                ) ? <Option1 onClick={() => navigate("/mergeqc")}> <span>Merge QC</span></Option1> : ""}

                {user.length > 0 && <Option onClick={() => navigate("/barcode")}> Barcode</Option>}
                {user.length > 0 && (user[0]['designation'] === "Merchandiser") ? <Option onClick={() => navigate("/samples")}> Samples</Option> : ""}
                {user.length > 0 && (user[0]['designation'] === "Merchandiser") ? <Option onClick={() => navigate("/create")}> Create</Option> : ""}
                {user.length > 0 && <Option onClick={() => navigate("/home")}> <span style={{ color: "#ff006e" }}>{user[0].userName}</span></Option>}
                {user.length > 0 && (
                    (user[0]['designation'] === "Photographer" ||
                        user[0]['designation'] === "ModelShoot" ||
                        user[0]['designation'] === "MoodShoot" ||
                        user[0]['designation'] === "VideoShoot" ||
                        user[0]['designation'] === "Editor" ||
                        user[0]['designation'] === "ModelEditor" ||
                        user[0]['designation'] === "MoodEditor" ||
                        user[0]['designation'] === "VideoEditor" ||
                        user[0]['designation'] === "ModelQC" ||
                        user[0]['designation'] === "MoodQC" ||
                        user[0]['designation'] === "VideoQC" ||
                        user[0]['designation'] === "QC")
                ) ? <Option1 onClick={() => navigate("/switch")}> <span>Switch ({user[0].designation})</span></Option1> : ""}
                {user.length > 0 && <Login onClick={() => handleLogin()}> {user.length > 0 ? "LOGOUT" : "LOGIN"} </Login>}
            </Menu>
        </Nav>
    )
}

export default Navbar