import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { FetchData } from "../Components/Subcomponents/FetchData"
import { Approved, Pending } from "../Components/Subcomponents/Usercard"
import Loader from "../Components/Subcomponents/Loader";
import { DataContext } from "../Contexts/DataContext";
import { motion } from "framer-motion"

const Container = styled(motion.div)`
    width: 100%;
    display: flex;
    font-size: var(--font-s);
`
const CurrentUsers = styled(motion.div)`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`
const PendingApproval = styled(motion.div)`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f8f7ff;
    border-radius: 10px;
    box-shadow: var(--box-shadow);
`
const Header = styled.div`
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffdab9;
    padding: 6px 20px;
    letter-spacing: 1.2px;
    text-transform: caplitalize;
    border-radius: 3px;
`
const Body = styled.div`
    height: calc(100% - 50px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const Users = () => {

    const { url } = useContext(DataContext)
    const [userList, setUserList] = useState("")

    useEffect(() => {

        async function fetchFn() {
            const response = await FetchData({
                "endpoint": url + "user/fetch",
            })
            const json = await response.data
            setUserList(json)
        }

        fetchFn()
        // eslint-disable-next-line
    }, [])

    const userApproved = async (userName) => {
        const response = await FetchData({
            "endpoint": url + "user/update",
            data: {
                "userName": userName,
                "status": "approved"
            }
        })
        const json = await response.data
        setUserList(json)
        window.location.reload();
    }

    const userRejected = async (userName) => {
        const response = await FetchData({
            "endpoint": url + "user/update",
            data: {
                "userName": userName,
                "status": "rejected"
            }
        })
        const json = await response.data
        setUserList(json)
        window.location.reload();
    }


    return (
        <Container
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
                ease: "easeInOut",
                duration: 0.5
            }}
        >
            {userList.length > 0 ?
                <>
                    <CurrentUsers
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                            type: "spring",
                            stiffness: 100,
                            duration: 0.5, delay: 0.5
                        }}
                    >
                        <Header>
                            Current Users
                        </Header>
                        <Body>
                            <Approved value={userList} />
                        </Body>
                    </CurrentUsers>
                    <PendingApproval
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                            type: "spring",
                            stiffness: 100,
                            duration: 0.5, delay: 0.7
                        }}
                    >
                        <Header>
                            Pending Approval
                        </Header>
                        <Body>
                            <Pending value1={userList} userApproved={userApproved} userRejected={userRejected} />
                        </Body>
                    </PendingApproval>
                </>
                :
                <Loader />
            }
        </Container >
    )
}

export default Users